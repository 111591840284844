import { useEffect, useRef, useState } from "react"
import moment from "moment";
import { Enrollment, StudyType } from '../api/study';
import { baseUrls } from "../routes";
import { BreadcrumbItem } from "../components/MBreadcrumbs";

export const emptyValueFormatter = (value: string) => {
  if (!value) return undefined;

  return value;
}

export const emptyStringFormatter = (value: string) => {
  if (!value) return "-";

  return value;
}

export const formatName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`;

export const formatEnrollment = (enrollment: Enrollment) => {
  if (enrollment.toLowerCase() === 'home') {
    return 'At home';
  }

  if (enrollment.toLowerCase() === 'clinic') {
    return 'In-clinic';
  }

  return '';
}

export const formatStudyType = (studyType: StudyType) => {
  if (studyType === 'holter') {
    return 'Holter';
  }

  if (studyType === 'mct') {
    return 'MCT'
  }

  if (studyType === 'two_week_holter') {
    return "Multi-Holter";
  }

  if (studyType === 'rpm') {
    return 'RPM'
  }

  return '';
}

export const formatDateAPI = (date : Date | null) => date ? moment(date).format("D-M-YYYY") : null;

export const formatDateToShow = (date: Date | string | null) => moment(date).format('DD.MM.YYYY');
export const formatDateTimeToShow = (date: Date | string) => moment(date).format('DD.MM.YYYY HH:mm');

export const formatDateTimeLong = (date: Date | string) => moment(date).format('DD.MM.YYYY HH:mm:ss');

export const formatTimeToShow = (date: Date | string) => moment(date).format('HH:mm');

export const dateFromString = (dateStr:string) =>{return moment(dateStr, 'HH:mm').toDate()}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isObject = (value: any) => typeof value === 'object' && value !== null;

export const isDraft = (data: any) => isObject(data.draft_data) && data.status === 'draft';

export const isHolter = (value: string) => value.toLowerCase() === 'holter';
export const isMCT = (value: string) => value.toLowerCase() === 'mct';

export const isTwoWeekHolter = (value: string) => value.toLowerCase() === 'two_week_holter';

export const isRPM = (value: string) => value.toLowerCase() === 'rpm';

export const datesDifference = (oldDate: Date) => {
  if(oldDate === null){
    return "Never"
  }

  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  let currentDate = new Date();

  let diff = currentDate.getTime() - oldDate.getTime()

  if(diff > 30*24*60*60*1000){
    return formatter.format(Math.round(-diff / (30*24*60*60*1000)), 'months');
  } else if(diff > 24*60*60*1000){
    return formatter.format(Math.round(-diff / (24*60*60*1000)), 'days');
  } else if(diff > 60*60*1000){
    return formatter.format(Math.round(-diff / (60*60*1000)), 'hours');
  } else if(diff > 60*1000){
    return formatter.format(Math.round(-diff / (60*1000)), 'minutes');
  } else{
    return formatter.format(Math.round(-diff / 1000), 'seconds');
  }
}

export const formatShortDescription = (major:number,minor:number,build:number,hardware:string) => {
  return major + "." + minor +"." + build + "-"+hardware
}

export const shortenString = (value: string) => {
  if(value.length > 50){
    return value.substring(0,50) + "...";
  }
  return value;
}

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export const pickersMigrationHack = (error?: boolean) => ({
  paddingBottom: '0 !important',

  '& input': {
    padding: '8.5px 14px'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
  }
});

export const prepareAdminBreadcrumbs = (region?: BreadcrumbItem, cluster?: BreadcrumbItem, clinic?: BreadcrumbItem) => {
  let breadcrumbs: any[] = [];

  if (region) {
    breadcrumbs.push({
      pageName: region.pageName,
      to: `${baseUrls.regions}/${region.to}`
    })
  }

  if (cluster) {
    breadcrumbs.push({
      pageName: cluster.pageName,
      to: `${baseUrls.clusters}/${cluster.to}`
    })
  }

  if (clinic) {
    breadcrumbs.push({
      pageName: clinic.pageName,
      to: `${baseUrls.clinics}/${clinic.to}`
    })
  }

  return {
    homepage: {
      pageName: 'Regions',
      to: baseUrls.regions
    },
    breadcrumbs
  }
}

export const sleep = (ms:number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const usePrevious = (value: any) => {
    const currentRef = useRef(value)
    const previousRef = useRef()
    if (currentRef.current !== value) {
        previousRef.current = currentRef.current
        currentRef.current = value
    }
    return previousRef.current
}

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const isRegion = (regionId: string, clusterId: string) => !regionId && !clusterId;
export const isCluster = (regionId: string, clusterId: string) => regionId && !clusterId;
export const isClinic = (regionId: string, clusterId: string) => regionId && clusterId;