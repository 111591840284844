export const bloodPressureChart = (bpList: any) => ({
  chart: {type: "spline"},
  title:{text:''},
  yAxis: {
    title: {
      text: null
    },
    //min : 60, max : 200, tickInterval : 20
    tickInterval : 20
  },
  credits: {enabled: false},
  xAxis: {
    min : 0,
    max : 24,
    tickInterval : 1,
    labels: {
      format : "{value}"}},
  tooltip:{
    format:"{value}",
    shared: true,
    useHTML: true,
    headerFormat: '<table>',
    pointFormat: '<b>{point.low}</b> SYS<br/><b>{point.high}</b> DIA<br/>{point.x}:00',
    footerFormat: '</table>',
    valueDecimals: 2
  },
  series: [{
    type : "columnrange",
    showInLegend: false,
    lineWidth: 1,
    pointWidth : 4,
    data: bpList.sort((a: any, b: any) => +new Date(b.date) - +new Date(a.date)).map((bp: any) =>
    [bp.hour,  parseInt(bp.systolic), parseInt(bp.diastolic)]
    )
  }]
});

export const heartRateChart = (heartRateList: any) => ({
  chart: {type: "line"},
  title:{text:''},
  yAxis: {
    title: {
      text: null
    },
    //min : 60, max : 200,
    tickInterval : 20,
  },
  xAxis : {
    min : 0, max : 24, tickInterval : 1,
  },
  tooltip:{
    format:"{value}",
    shared: true,
    useHTML: true,
    headerFormat: '<table>',
    pointFormat: '<b>{point.y}</b> bpm avg<br/>{point.x}:00',
    footerFormat: '</table>',
    valueDecimals: 2
  },
  credits: {enabled: false},
  series: [{
    showInLegend: false,
    data: heartRateList.sort((a: any, b: any) => +b.date - +a.date).map((hr: any)=>({
      x: hr.date,
      y: hr.heart_rate
    }))
  }]
});

export const saturationChart = (saturationList: any) => ({
  type: 'spline',
  credits: {enabled: false},
  title:{text:''},
  yAxis: {
    title: {
      text: null
    },
    // min : 75, max : 100,
    tickInterval : 1
  },
  tooltip:{
    format:"{value}",
    shared: true,
    useHTML: true,
    headerFormat: '<table>',
    pointFormat: '<b>{point.y}% avg<br/></b>{point.x}:00',
    footerFormat: '</table>',
    valueDecimals: 2
  },
  xAxis: {
    min : 0, max : 24, tickInterval : 1,
    type: 'number',
    labels: {
      format : "{value}",
      rotation: 0.1,
      align: 'center',
      step: 0.5,
      enabled: true
    }
  },
  series: [{
    //step : 'left',
    showInLegend: false,
    data: saturationList.map((sp02: any) => [
      sp02.hour,
      parseInt(sp02.saturation)
    ])
  }]
});


export const ppgChart = (ppgData: any) => {
  const ppgGreen = ppgData?.ppg_green || [];
  const ppgPeaks = ppgData?.ppg_peaks || [];


  const data = ppgGreen.map((item : any, index : number) => {
    item  = ((item * 1.8 / 16777214) / 21) * 1000;
    return [index / 50, item];


    // if(ppgPeaks.indexOf(index) >= 0) {
    //   return {x: index / 50, y:item,color:"red", marker: { enabled: true }}
    // } else{
    //   return [index / 50, item];
    // }
  });

  return {
    chart: {
      zoomType: 'xy',
      type : 'spline'
    },
    credits: {
      enabled: false
    },
    title:{
      text:''
    },
    xAxis: {
      labels: {
        format: '{value}s'
      },
      step : 250,

    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      title: {
        text: null
      }
    },
    series: [{
      states: {
        hover: {
          enabled: false
        }
      },
      showInLegend: false,
      type : 'spline',
      data
    }]
  }
}


export const rrTachogramChart = (ppgData: any) => {
  const systolicIntervals = ppgData.systolic_intervals || [];

  return {
  chart: {
    type: 'scatter',
    zoomType: 'xy'
  },
  credits: {
    enabled: false
  },
  title:{
    text:''
  },
  yAxis: {
    title: {
      text: 'R-R interval (ms)'
    }
  },
  xAxis : {
    title: {
      text: '# RR interval'
    }
  },
  tooltip: {
    enabled: false
  },
  series: [{
    showInLegend: false,
    // color: 'rgb(4,173,144)',
    data: systolicIntervals.map((i: number) => [i])
  }]
}}


export const poincareChart = (ppgData: any) => {
  const x = (ppgData?.systolic_intervals || []).slice(0, -1);
  const y = (ppgData?.systolic_intervals || []).slice(1);

  const data = x?.map((i: any, index: number) => [x[index], y[index]])

  return {
    chart: {
      zoomType: 'xy',
      height: '100%'
    },
    credits: {
      enabled: false
    },
    title:{
      text:''
    },
    yAxis: {
      title: {
        text: 'Previous interval (ms)'
      }
    },
    xAxis : {
      title: {
        text: 'R-R interval (ms)'
      }
    },
    tooltip: {
      enabled: false
    },
    series: [{
      showInLegend: false,
      type: 'scatter',
      // color: 'rgb(4,173,144)',
      data
    }]
  }
}


