import Button, {ButtonProps} from '@mui/material/Button';
import { Box } from '@mui/material';
import { CircularProgress } from "@mui/material";

export interface MButtonProps extends ButtonProps {
    id?: string;
    busy?: boolean;
}

export const MButton = ({busy, variant, onClick, disabled, children, ...rest}: MButtonProps) => {

    return (
        <>
            <Button
                {...rest}
                variant={variant || "contained"}
                onClick={onClick}
                disabled={disabled || busy}
            >
                {children}
                {busy ?
                    <Box
                        sx={{ 
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress 
                            size={25} 
                        /> 
                    </Box> : <></>
                }
            </Button>
        </>
    );
}