const DMSConfiguration = {
    dataBits : 8,
    baudRate: 115200,
    stopBits: 1
}

const sleep = (milliseconds: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
};

export const getPorts = async() => {
    try {
        // Get the list of available serial ports
        const ports = await navigator.usb.getDevices();

        // Assuming the serial ports provide manufacturer info, you can filter based on that
        const filteredPorts = ports.filter(port => {
            return port.vendorId === 0x0483 && port.productId === 0x5740;
        });
        console.log(ports);
        console.log(filteredPorts)
        return filteredPorts;
    } catch (error) {
        console.error("Error accessing serial ports:", error);
    }
}

export const requestPort = async() => {
    try {
        return await navigator.usb.requestDevice({
            filters: [
                { vendorId: 0x0483, productId: 0x5740 }
            ]
        })
    } catch (error) {
        console.error("Error requesting serial port:", error);
    }
};


export async function openDevice(device: USBDevice) {
    try {
        await device.open();
        if (device.configuration === null) {
            await device.selectConfiguration(1); // Select the first configuration
        }
        await device.claimInterface(1); // Claim interface 0
        console.log("Device opened and interface claimed.");
    } catch (error) {
        console.error("Failed to open device:", error);
    }
}

export async function logDeviceConfiguration(device: USBDevice) {
    await device.open();
    console.log("Device opened");
    if (device.configuration === null) {
        await device.selectConfiguration(1);
    }
    console.log("Configuration: ", device.configuration);

    if (device.configuration) {
        for (const iface of device.configuration.interfaces) {
            console.log("Interface: ", iface.interfaceNumber);
            for (const alt of iface.alternates) {
                console.log("Alternate Setting: ", alt.alternateSetting);
                for (const endpoint of alt.endpoints) {
                    console.log(`Endpoint: ${endpoint.endpointNumber}, Direction: ${endpoint.direction}, Type: ${endpoint.type}`);
                }
            }
        }
    }
    await device.close();
    console.log("Device closed");
}


export const _sendCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {

    let _ = await portAccess.transferOut(2, command);
    //console.log(command,_);
    if (with_escape_char) {
        const encoder = new TextEncoder();
        const commandEncoded = encoder.encode("\"\\r\\n\"");
        let _ = await portAccess.transferOut(2, commandEncoded);
        //console.log("\"\\r\\n\"",_);
    }
}

export const sendTextCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {
    const encoder = new TextEncoder();
    let commandEncoded = encoder.encode(command);
    await _sendCommand(portAccess,commandEncoded,with_escape_char);
}

export const sendBinaryCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {
    await _sendCommand(portAccess,command,with_escape_char);
}

export const sendRequestSNCommand = async(portAccess:USBDevice) => {
    let command = new Uint8Array([192, 1, 192]);
    let with_escape_char = false;
    await _sendCommand(portAccess,command,with_escape_char);
}

export const sendRequestTimeCommand = async(portAccess:USBDevice) => {
    let command = new Uint8Array([192, 3, 192]);
    let with_escape_char = false;
    await _sendCommand(portAccess,command,with_escape_char);
}

export const readData = async(device: USBDevice, length: number) => {
    try {
        let result = await device.transferIn(2, length); // Use the IN endpoint number 2
        console.log(`Data received`);
        console.log(result.data)
        return result.data;
    } catch (error) {
        console.error("Failed to read data:", error);
        return null;
    }

}