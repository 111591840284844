import Box from "@mui/material/Box";
import { MButton } from "../../components/MButton";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { PDFDownloadLink } from '@react-pdf/renderer'
import CredentialPDFView from "../../components/СredentialsPDF";
import MModal from "../../components/MModal";

interface PDFModalInterface {
    open : boolean;
    name: string;
    accountName: string;
    password: string;
    email: string;
}

export const PDFModal = (props: PDFModalInterface) => {
    const { open, name, accountName, password, email } = props;
    const navigate = useNavigate();

    return (
        <MModal
            open={open}
            handleClose={() => navigate(-1)}
            width={480}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 10}} paragraph={true}>
                    Account creation
                </Typography>
                <Typography variant="body1" sx={{mb: 5}}>
                    Account was created successfully
                </Typography>
                <Box sx={{textAlign: "center",mb: 5}}>
                    <div>
                        <PDFDownloadLink document={
                            <CredentialPDFView clinic_name={name}
                                            account_name={accountName}
                                            email={email}
                                            password={password}
                            />}
                                        fileName={name+"_"+email+"_credentials.pdf"}>
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Download PDF with credentials '
                            }
                        </PDFDownloadLink>
                    </div>
                </Box>
                <Box sx={{textAlign: "center"}}>
                    <MButton onClick={() => {navigate(-1)}}>Continue</MButton>
                </Box>
            </Box>
        </MModal>
    );
};

export default PDFModal;