import {networkLayer} from "./networkLayer";
import {config} from "../config";

export const getClinicStaffList = async (clinic_id:string) => {
    return await networkLayer('get', `${config.baseUrl}/clinics_staff/list/`,
        null,
        {"clinic_id":clinic_id});
}

export const createStuffAccount = async(data:any) => {
    return await networkLayer('post',`${config.baseUrl}/clinics_staff/create/`,data)
}

export const getStaffProfile = async (id: string | undefined) => {
    return await networkLayer('get', `${config.baseUrl}/clinics_staff/${id}/custom_detail/`);
}

export const updateStaffProfile = async (id:string,data: any) => {
    return await networkLayer('patch', `${config.baseUrl}/clinics_staff/${id}/update/`,data);
}

export const deleteStaffProfile = async (id:string) => {
    return await networkLayer('delete', `${config.baseUrl}/clinics_staff/${id}/`,null,null);
}