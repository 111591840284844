import { useNavigate } from "react-router-dom";
import AdminDevicesTable from "../components/AdminDevicesTable";

const tableHeadings = [
    {
        fieldName: 'S/N',
        field: 'serial_number'
    },
    {
        fieldName: 'Is Connected',
        field: 'connection'
    }
];

interface AdminMeasurementsGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;
}

export const AdminDevicesGrid = (props: AdminMeasurementsGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row,
        serial_number:row.serial_number,
        connection : row.connection
    }));

    return (
        <AdminDevicesTable
            headings={tableHeadings}
            rows={rows}
        />
    );
};

export default AdminDevicesGrid;