interface LogEntry {
    timestamp: Date;
    level: string;
    message: string;
}


function parseDMSDateString(dateString: string): Date {
    // Remove the "UTC" prefix and split the string into date and time parts
    const [datePart, timePart] = dateString.replace('UTC', '').split('@');

    // Construct a standard date-time string
    const formattedString = `${datePart}T${timePart}Z`;

    // Create and return the Date object
    return new Date(formattedString);
}

export async function parseLogFile(fileHandle: FileSystemFileHandle): Promise<LogEntry[]> {
    const file = await fileHandle.getFile();
    const text = await file.text();
    const lines = text.split('\n').filter(line => line.trim() !== '');

    const parsedLogs: LogEntry[] = lines
        .map(line => {
            const [timestamp, level, message] = line.split(' | ');
            return {
                timestamp: parseDMSDateString(timestamp.trim()),
                level: level.trim(),
                message: message.trim()
            };
        })
        .filter(log => log.message === "Double Tap");

    return parsedLogs;
}
