import {networkLayer} from "./networkLayer";
import {config} from "../config";

export enum UserRolePermissionsEnum {
    'Super Admin' = 'super_admin_access',
    'IT Admin' = 'it_admin_access',
    'Account Manager' = 'account_manager_access',
    'Lead Tech' = 'lead_technician_access',
    'Senior Tech' = 'senior_technician_access',
    'Technician' = 'technician_access',
    'Unit Owner' = 'unit_owner_access',
    'Clinician' = 'clinician_access',
}

type EnumUserRolePermissions = {[value in UserRolePermissionsEnum]?: boolean}

export interface UserPermissions extends EnumUserRolePermissions {
    id?: string;
    description: string;
    key: string;
}    

export const addRolePermissions = async (data: Permissions) => {
    return await networkLayer('post', `${config.baseUrl}/role_permissions/`, data, null);
}

export const listRolePermissions = async () => {
    return await networkLayer('get', `${config.baseUrl}/role_permissions/`, null,null);
}
//
export const deleteRolePermissions = async (id: string) => {
    return await networkLayer('delete', `${config.baseUrl}/role_permissions/${id}/`, null,null);
}

export const updateRolePermissions = async (id: string, data: Permissions) => {
    return await networkLayer('put', `${config.baseUrl}/role_permissions/${id}/`, data,null);
}