import { useCallback, useEffect, useState } from "react";
import { getRegionsList } from "../../../api/region";
import { getClustersList } from "../../../api/cluster";
import { getClinicsList } from "../../../api/clinic";
import MTreeViewAsync from "../../../components/MTreeViewAsync/MTreeViewAsync";
import { Unit, updateAccount } from "../../../api/accounts";
import { usePrevious } from "../../../utils/utils";
import { Box, Divider } from "@mui/material";
import { MButton } from "../../../components/MButton";
import { useLocation } from "react-router-dom";
import { connectedUnitsRoles } from "../formUtils";

export type EntityType = 'region' | 'cluster' | 'clinic';

interface AccessDropdownTreeNode {
    name: string;
    id: string;
    children?: AccessDropdownTreeNode[];
    metadata?: {
        entityType?: EntityType;
    }
}

interface AccessTreeProps {
    isEdit?: boolean;
    connectedUnitIds?: string[];
    connectedUnits?: Unit[];
    handleSelectedItems?: (ids: string[]) => void;
}

const formatToTree = (arr: any[], entityType?: EntityType, isBranch?: boolean) => arr.map((i: any) => ({
    name: i.name,
    id: i.id,
    isBranch,
    metadata: {
        entityType
    }
})).sort((a:any, b:any) => b.departments_number - a.departments_number);

export const recursiveFind = (id: string, array: any[]) => {
    let result: any;
    array.some(o => o.id === id && (result = o) || (result = recursiveFind(id, o.children || [])));
    return result;
}

const update = (t: any, func: any) => {
    switch (t?.constructor) {
        case Object:
        return Object.entries(t).reduce((r: any, [k, v]) => {
            const newValue = update(func(v), func)
            if (newValue !== undefined) r[k] = newValue
            return r
        }, {})
        case Array:
        return t.flatMap((v: any) => {
            const newValue = update(func(v), func)
            return newValue === undefined ? [] : [newValue]
        })
        default:
        return t
    }
}

const updateWithObj = (t: any, obj: any) => {
    return update(t, (v: any) => v.id == obj.id ? {...v, ...obj} : v)
}

const recursiveAdd = (id: string, array: any[], data: any[], entityType?: EntityType, isBranch?: boolean) => {
    let result: any;

    result = array.map(i => {
        if (data) {
            if (i.id === id) {
                i.children = formatToTree(data, entityType, isBranch)
            } else {
                recursiveAdd(id, i.children || [], data, entityType, isBranch);
            }
        }

        return i;
    });

    return result;
}

const prepareUnitIds = (connectedUnits: Unit[], nodes: AccessDropdownTreeNode[], isLoaded: boolean) => connectedUnits.filter(cunit => {
    const unitsLoadedIds = connectedUnits.map(funit => recursiveFind(funit.id, nodes)).filter(Boolean).map(i => i.id);
    return isLoaded ? unitsLoadedIds.includes(cunit.id) : !unitsLoadedIds.includes(cunit.id);
}).map(i => i.id);

const calculateHalfSelectedUnits = (connectedUnits: Unit[], selectedIds: string[]) => {
    const halfSelectedUnits = connectedUnits.filter(cunit => !selectedIds.includes(cunit.id));
    return [...new Set([
        ...halfSelectedUnits.map(u => u.id),
        ...halfSelectedUnits.map(u => u.cluster),
        ...halfSelectedUnits.map(u => u.region),
    ].filter(Boolean))] as string[];
}

export const AccessTree = (props: AccessTreeProps) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const accountId = searchParams.get('accountId') || undefined;

    const { handleSelectedItems, isEdit, connectedUnitIds = [], connectedUnits = [] } = props;
    const [nodes, setNodes] = useState<AccessDropdownTreeNode[]>([]);
    const prevNodes = usePrevious(nodes);
    
    const [busy, setBusy] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [halfSelectedIds, setHalfSelectedIds] = useState<string[]>([]);

    const [selectedClustersIds, setSelectedClustersIds] = useState<string[]>([]);
    const [selectedClinicsIds, setSelectedClinicsIds] = useState<string[]>([]);

    const [isUnitsChanged, setUnitsChanged] = useState<boolean>(false);

    const fetchRegionsList = useCallback(async () => {
        const regions = await getRegionsList();
        const formattedTreeData = formatToTree(regions, 'region', true);
        setNodes(formattedTreeData);
    }, [])

    useEffect(() => {
        fetchRegionsList()
    }, []);

    useEffect(() => {
        // on nodes load
        if (isEdit && prevNodes && !(prevNodes as string[]).length && nodes.length) {
            const selectedIds = prepareUnitIds(connectedUnits, nodes, true);
            const halfSelectedIds = calculateHalfSelectedUnits(connectedUnits, selectedIds);
            setSelectedIds(selectedIds);
            setHalfSelectedIds(halfSelectedIds);
        }
    }, [isEdit, prevNodes, nodes]);

    useEffect(() => {
        if (isEdit) {
            if (!halfSelectedIds.length) return;
            const currentSelectedIds = prepareUnitIds(connectedUnits, nodes, true);
            const currentHalfSelectedIds = calculateHalfSelectedUnits(connectedUnits, currentSelectedIds);

            if (halfSelectedIds.length !== currentHalfSelectedIds.length) {
                setHalfSelectedIds(currentHalfSelectedIds);
            }
        }
    }, [isEdit, connectedUnits, nodes, selectedIds])

    useEffect(() => {
        if (isEdit) {
            if (!selectedClustersIds.length) return;
            if (selectedClustersIds.length) {
                setSelectedIds([...connectedUnitIds, ...selectedClustersIds]);
                setSelectedClustersIds([]);
            }
        }
    }, [isEdit, nodes, selectedClustersIds])

    useEffect(() => {
        if (isEdit) {
            if (!selectedClinicsIds.length) return;
            if (selectedClinicsIds.length) {
                setSelectedIds([...connectedUnitIds, ...selectedClinicsIds]);
                setSelectedClinicsIds([]);
            }
        }
    }, [isEdit, nodes, selectedClinicsIds])

    const handleItemsLoading = async (id: string) => {
        const currentItem = recursiveFind(id, nodes);
        if ('children' in currentItem) return;
        let newNodes = [...nodes];

        if (currentItem.metadata.entityType === 'region') {
            const clusters = await getClustersList(id);
            
            if (clusters.length) {
                newNodes = recursiveAdd(id, nodes, clusters, 'cluster', true);

                const selectedClustersIds = prepareUnitIds(connectedUnits, clusters, true);
                setSelectedClustersIds(selectedClustersIds);
            } else {
                newNodes = updateWithObj(newNodes, { id, isBranch: false })
            }
        }

        if (currentItem.metadata.entityType === 'cluster') {
            const clinics = await getClinicsList(id);

            if (clinics.length) {
                newNodes = recursiveAdd(id, nodes, clinics, 'clinic', false);

                const selectedClinicsIds = prepareUnitIds(connectedUnits, clinics, true);
                setSelectedClinicsIds(selectedClinicsIds);
            } else {
                newNodes = updateWithObj(newNodes, { id, isBranch: false })
            }
        }

        setNodes(newNodes);
    }

    const updateUnitsInfo = async () => {
        setBusy(true);
        const connectedNotLoadedNodesUnitIds = prepareUnitIds(connectedUnits, nodes, false);

        if (accountId) {
            await updateAccount(accountId, {
                id: accountId,
                'action': 'change_units',
                'new_units': [...new Set([...connectedNotLoadedNodesUnitIds, ...connectedUnitIds])]
            }).finally(() => setTimeout(() => {
                setBusy(false)
                setUnitsChanged(false);
            }, 500));
        }
    }

    if (!nodes) return <></>;
    // 
    return (
        <>
            <MTreeViewAsync 
                childrenData={nodes}
                isEdit={isEdit}
                sids={selectedIds}
                hsids={halfSelectedIds}
                handleItemsLoading={handleItemsLoading}
                handleSelectedItems={handleSelectedItems}
                onCheckboxClicked={() => setUnitsChanged(true)}
            />
            {isEdit && isUnitsChanged ? (
                <Box mt={2}>
                    <Divider />
                    <Box mt={2}>
                        <MButton
                            busy={busy}
                            variant="outlined"
                            color="info"
                            onClick={updateUnitsInfo}
                            sx={{color:"error", width:"75%"}}
                        >
                            Update units info
                        </MButton>
                    </Box>
                </Box>
            ) : <></>}
        </>

    );
};

export default AccessTree;