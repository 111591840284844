import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { StudyGrid } from "../containers/StudyGrid";
import { centered } from '../theme';
import { baseUrls } from "../routes";
import { getStudiesList } from '../api/study';
import { PermissionsE, usePermissions } from "../context/usePermissions";

const ClinicianHome = () => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [studies, setStudies] = useState([]);

  const fetchStudiesList = useCallback(async () => {
    const { data } = await getStudiesList();

    setStudies(data);
  }, [])

  useEffect(() => {
    fetchStudiesList()
  }, [fetchStudiesList]);

  const pageHeaderActions = [
    hasPermission!(PermissionsE["study.w"]) ? {
      onClick: () => navigate(baseUrls.studiesAdd),
      name: "New Study"
    } : undefined
  ].filter(Boolean) as PageHeaderActionsProps[];

  return (
    <>
      <PageHeader
        title="Studies"
        actions={pageHeaderActions}
      />
      <Grid container>
        <Grid item xs={12}>
          {
            studies.length ? (
              <StudyGrid
                data={studies}
              />
            ) : (
              <Paper
                sx={{ minHeight: '50vh', ...centered }}
              >
                <Typography variant="h6">No studies yet. Create a new one</Typography>
              </Paper>
            )
          }
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicianHome;