import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';

interface RadioOption {
  label: string;
  subLabel?: string;
  value: string;
}

interface MRadioProps {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
  options: RadioOption[];
  disabled?: boolean;
  isSmall?: boolean;
}

export const MRadioPlates = (props: MRadioProps) => {
  const { label, value = null, onChange, options, disabled, isSmall } = props;

  if (!options) return <></>;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Box display="flex" pt={2} pb={4}>
        {options.map((option: RadioOption, key: number) => (
          <Box
            key={key}
            mr={options.length - 1 !== key ? 2 : 0}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: isSmall ? 180 : 240,
              height: 110,
              border: option.value === value ? disabled ? '3px solid rgba(0, 0, 0, 0.5)' : '3px solid #1D80E8' : '1px solid rgba(0, 0, 0, 0.3)',
              borderRadius: 0.75,
              cursor: 'pointer',
              pointerEvents: disabled ? 'none' : '',
              color: disabled ? '#808080' : '#000000',
              textAlign: 'center'
            }}
            onClick={() => onChange(option.value)}
          >
            <Typography><b>{option.label}</b></Typography>  
            {option.subLabel && <Typography sx={{ fontSize: 14 }}>{option.subLabel}</Typography>}
          </Box>
        ))}
      </Box>

    </>
  );
}

export default MRadioPlates;
