import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import Typography from "@mui/material/Typography";
import { addCluster, getClustersList } from "../api/cluster";
import { MModal } from "../components/MModal";
import { MTextField } from "../components/MTextField";
import { MButton } from "../components/MButton";
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Paper } from "@mui/material";
import UnitsListGrid from "../containers/UnitsListGrid";
import { getRegion } from "../api/region";
import { MBreadcrumbs } from "../components/MBreadcrumbs";
import { prepareAdminBreadcrumbs } from "../utils/utils";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const Region = () => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["units.w"]);

    const navigate = useNavigate();
    const { regionId } = useParams();
    const [regionDetails, setRegionDetails] = useState<any>(null);

    const [busy, setBusy] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [clustersList, setClustersList] = useState<any[]>([]);

    const [newClusterName, setNewClusterName] = useState("");

    const fetchRegionDetails = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        if (regionId != null) {
            const regionDetails = await getRegion(regionId);
            setRegionDetails(regionDetails);

            const clustersList = await getClustersList(regionId);
            clustersList.sort((a: any, b: any) => b.departments_number - a.departments_number);
            setClustersList(clustersList);
        }
    }, [regionId])

    useEffect(() => {
        fetchRegionDetails()
    }, [fetchRegionDetails]);


    const onEntityCreation = async () => {
        if (!canWrite) return;
        setBusy(true);

        let data = {
            "name": newClusterName,
            "region": regionId
        }
        await addCluster(data);
        window.location.reload();

    }

    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3">Add new cluster</Typography>
            </Box>
            <Box mt={6} width={320}>

            </Box>
            <Box mt={1.5} width={320}>
                <MTextField
                    label="New Cluster name"
                    onChange={(e) => setNewClusterName(e.target.value)}
                />
            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": { marginRight: 2 },
                    textAlign: 'center'
                }}
            >
                <MButton disabled={busy} onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton busy={busy} onClick={onEntityCreation} color={"primary"}>Confirm</MButton>
            </Box>
        </Box>
    )

    const region = regionDetails ? {
        pageName: regionDetails.name,
        to: regionDetails.id
    } : undefined;

    const pageHeaderActions = [
        canWrite ? {
            onClick: handleOpen,
            name: "Add Cluster"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            <Box mt={2}>
                <MBreadcrumbs {...prepareAdminBreadcrumbs(region)} />
            </Box>
            <PageHeader
                title={`${(!regionDetails ? "??" : regionDetails["name"])}`}
                subTitle="region"
                actions={pageHeaderActions}
            />
            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography variant="h4" paragraph={true}>Units</Typography>
                        </Box>
                        <UnitsListGrid type="cluster" data={clustersList} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
export default Region;