import React from 'react';
import { Box, Typography } from '@mui/material';
import ukr_certification_image from "../images/ukr_certification.png"
import md_image from "../images/md.jpg"
import factory_image from "../images/factory.png"

const ukr_certification = {
    src: ukr_certification_image,
    alt: "test"
}

const md = {
    src: md_image,
    alt: "test"
}

const factory = {
    src: factory_image,
    alt: "test"
}



// Constants for content
const PLATFORM_NAME = "Mawi Cardiac Monitoring Platform";
const APP_VERSION = "4.70.84";
const COMPANY_NAME = "Mawi Inc. LLC";
const ADDRESS = [
    "79008, Lviv City, Lviv Region",
    "7A Staroevreyska street",
    "Office 9, Ukraine",
];
const EMAIL = "info@mawiheart.com";
const FDA_AUTH_NUMBER = "MDMA-2-2024-2719";
const IMAGES = [
    { src: ukr_certification.src, alt: ukr_certification.alt },
    { src: md.src, alt: md.alt },
    { src: factory.src, alt: factory.alt },

];

export const SpecificationsPage = () => {
    return (
        <Box
            sx={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 4,
                maxWidth: '100%',
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 2,
                backgroundColor: 'white',
            }}
        >
            {/* Platform Name - Centered */}
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                {PLATFORM_NAME}
            </Typography>

            {/* Version - Centered */}
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
                Version: {APP_VERSION}
            </Typography>

            {/* Content: Left Images, Right Information */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: 2,
                    padding: 5,
                    paddingLeft: "20%",
                    paddingRight: "20%"
                }}
            >
                {/* Left - Images and FDA Authorization */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-start',
                    }}
                >
                    {/* Images */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {IMAGES.map((image, index) => (
                            <img
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                style={{ width: '150px', height: 'auto' }}
                            />
                        ))}
                    </Box>

                    {/* FDA Authorization Number - positioned directly behind images */}
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2" fontWeight="bold">
                            sFDA Authorization Number:
                        </Typography>
                        <Typography variant="body2">{FDA_AUTH_NUMBER}</Typography>
                    </Box>
                </Box>

                {/* Right - Information */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: 1
                    }}
                >
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2" fontWeight="bold">
                            {COMPANY_NAME}
                        </Typography>
                        {ADDRESS.map((line, index) => (
                            <Typography key={index} variant="body2">
                                {line}
                            </Typography>
                        ))}
                    </Box>

                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="body2">
                            Email: <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SpecificationsPage;