import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MTextField } from "../components/MTextField";
import { MButton } from "../components/MButton";
import { validateEmail, validateRequiredField } from "../utils/validator";
import {useAuth, isClinician, isTechnician, isAdmin, isSuperAdmin} from "../context/useAuth";
import { baseUrls } from "../routes";
import { authenticateUser } from "../api/auth";

const Login = () => {
  const [busy, setBusy] = useState(false);
  const { user, login } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (isClinician(user)) {
        navigate(baseUrls.studies);
      }


      // TODO: all "multiple connected units" accounts cases should be handled here
      if (isTechnician(user)) {
        navigate(baseUrls.reports);
      }

      if (isAdmin(user) || isSuperAdmin(user)) {
        navigate(baseUrls.regions);
      }
    }
  }, []);

  const formValueChangeHandler = (field: 'email' | 'password', value: string) => {
    if (field === 'email') {
      if (emailError) {
        setEmailError('')
      }
      setEmail(value);
      return;
    } 

    if (field === 'password') {
      if (passwordError) {
        setPasswordError('')
      }
      setPassword(value);
      return;
    }
  }

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const emailMessage = validateEmail(email);
      const passwordMessage = validateRequiredField('Password', password);
      if (emailMessage || passwordMessage) {
        setEmailError(emailMessage);
        setPasswordError(passwordMessage);
        return;
      }
      setBusy(true);
      const user = await authenticateUser(email, password);
      login(user);
    } catch (err: any) {
      console.error(err)
    } finally {
      setBusy(false);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 66px)"
      }}
    >
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            background: 'white',
            borderRadius: 2.5,
            width: 360,
            padding: '37px 30px 53px'
          }}
        >
          <Typography variant="h4" sx={{ textAlign: 'center'}}>
            Welcome
          </Typography>
          <Box mt={6}>
            <MTextField 
              label="Email"
              error={!!emailError}
              helperText={emailError}
              onChange={(e) => formValueChangeHandler('email', e.target.value)}
            />    
          </Box>
          <Box mt={2.5}>
            <MTextField 
              label="Password"
              type="password"
              error={!!passwordError}
              helperText={passwordError}
              onChange={(e) => formValueChangeHandler('password', e.target.value)}
            />
          </Box>
          <Box mt={5}>
            <MButton type="submit" busy={busy} fullWidth onClick={onSubmit}>Sign in</MButton> 
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Login;