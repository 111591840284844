import {networkLayer} from "./networkLayer";
import {config} from "../config";

export const addInfrastructure = async (data: any) => {
    return await networkLayer('post', `${config.usersUrls}/external_api/geo/infrastructure/`, data,null);
}

export const listInfrastructures = async () => {
    return await networkLayer('get', `${config.usersUrls}/external_api/geo/infrastructure/`, null,null);
}
//
export const deleteInfrastructure = async (id:any) => {
    return await networkLayer('delete', `${config.usersUrls}/external_api/geo/infrastructure/${id}/`, null,null);
}

export const updateInfrastructure = async (id:any,data: any) => {
    return await networkLayer('put', `${config.usersUrls}/external_api/geo/infrastructure/${id}/`, data,null);
}