import { TextareaAutosize, Box, InputLabel, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

export interface MTextareaProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (v: string) => void;
  maxLength?: number;
}

export const MTextarea = (props: MTextareaProps) => {
  const { label, placeholder, value = '', onChange, maxLength } = props;
  const [tValue, setTValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setTValue(value);
    onChange?.(value);
  }

  useEffect(() => {
    if (value) setTValue(value);
  }, [value])

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Box
        sx={{
          '& textarea': {
              width: '100%',
              resize: 'none',
              minHeight: 60
          }
        }}
      >
        <TextareaAutosize
          value={tValue}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxLength}
        />
      </Box>
      {
        maxLength ? (
          <Box mb={2} sx={{ fontSize: 12 }} textAlign="right">
            {tValue.length} / {maxLength}
          </Box>
        ) : <></>
      }
    </>
  );
}

export default MTextarea;