import { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MButton } from "../components/MButton";
import { MTextField } from "../components/MTextField";
import MDatePicker from "../components/MDatePicker";
import { getPatient, updatePatient } from "../api/patient";
import MSelect from "../components/MSelect";
import { Link, useNavigate } from "react-router-dom";
import { baseUrls } from "../routes";
import { getClinicDetail } from "../api/clinic";
import { PermissionsE, usePermissions } from "../context/usePermissions";

const genderOptions = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
];

interface PatientInfoProps {
    newIdPatient: string;
}

export const PatientInfo = (newIdPatient: PatientInfoProps) => {
  const { hasPermission } = usePermissions();
    const [busy, setBusy] = useState(false);
    const [idPatient, setIdPatient] = useState("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [patient, setPatient] = useState<any>(null);
    const [clientName, setClientName] = useState("");

    useEffect(() => {
        setIdPatient(newIdPatient.newIdPatient);
    }, [newIdPatient]);

    const getClientDetails = useCallback(async (id_department: string) => {
        if (!hasPermission!(PermissionsE["patient.r"])) return;
        const data = await getClinicDetail(id_department);
        setClientName(data['name']);
    }, [])

    const getPatientData = useCallback(async () => {
        if (!hasPermission!(PermissionsE["patient.r"])) return;
        if (!idPatient) return;
        let profile = await getPatient(idPatient);
        setPatient(profile.data);
        getClientDetails(profile.data.id_department);
    }, [idPatient])

    useEffect(() => {
        getPatientData();
    }, [getPatientData])

    const postUpdateUserProfile = async () => {
        if (!hasPermission!(PermissionsE["patient.w"])) return;
        setBusy(true);
        await updatePatient(patient).finally(() => setTimeout(() => setBusy(false), 500));
        setIsEdit(false);
    }

    const handlePatientChange = (keyToUpdate: string, newValue: string) => {
        let newDict = { ...patient };
        newDict[keyToUpdate] = newValue;
        setPatient(newDict);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Typography
                        variant="h4"
                        fontStyle={{ "color": "#4F4949", "fontWeight": "bold" }}
                    >
                        Patient details(ID: {patient?.unique_id})
                    </Typography>
                    <Box ml={1}>
                        {isEdit ?
                            <>
                                <>
                                    <MButton variant="text"
                                        hidden={!isEdit}
                                        color="error"
                                        onClick={() => {
                                            setIsEdit(false);
                                        }}>
                                        Cancel
                                    </MButton>
                                    <MButton
                                        busy={busy}
                                        variant="text"
                                        hidden={!isEdit}
                                        onClick={() => {
                                            setIsEdit(false);
                                            postUpdateUserProfile();
                                        }}
                                    >
                                        Save
                                    </MButton>
                                </>
                            </> : (
                                <MButton variant="text"
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                >
                                    Edit
                                </MButton>
                            )}
                    </Box>
                </Grid>
            </Grid>
            {
                patient?.id_department && (
                    <Grid item xs={12} display="flex" alignItems="center">
                        <Typography variant="h5">Clinic</Typography>
                        <Link to={baseUrls.clinics + "/" + patient?.id_department} style={{ display: 'flex', marginLeft: 8, fontSize: 20 }}>
                            {clientName}
                        </Link>
                    </Grid>
                )
            }
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MTextField
                            label="First name"
                            value={patient?.first_name}
                            disabled={!isEdit}
                            onChange={(e) => {
                                handlePatientChange("first_name", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Last name"
                            value={patient?.last_name}
                            disabled={!isEdit}
                            onChange={(event) => {
                                handlePatientChange("last_name", event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MSelect
                            label="Gender"
                            value={patient?.gender || ''}
                            defaultValue={genderOptions[0].value}
                            disabled={!isEdit}
                            options={genderOptions}
                            onChange={(e) => {
                                handlePatientChange("gender", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDatePicker
                            label="Date of Birth"
                            disabled={!isEdit}
                            value={patient?.birthday}
                            onChange={(e) => {
                                if (e !== null) {
                                    handlePatientChange("birthday", e.toString());
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
