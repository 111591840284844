import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import { MTextField } from "./MTextField";

export interface MAutocompleteProps {
  label?: string;
  placeholder?: string;
  options?: any[];
  value?: any;
  onInputChange?: (val?: {label: string, value: string}) => void;
  onType?: (val: string) => void;
  disabled?: boolean;
}

export const MAutocomplete = (props: MAutocompleteProps) => {
  const { label, placeholder, options = [], value = null, onInputChange, onType, disabled } = props;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Autocomplete
        options={options}
        getOptionLabel={(option: any) => option.label}
        value={value}
        readOnly={disabled}
        onInputChange={(event, newInputValue) => {
          const value = options.find((item: any) => item.label === newInputValue);
          onInputChange && onInputChange(value);
        }}
        renderInput={(params) => 
          <MTextField 
            {...params}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => onType && onType(e.target.value)}
          />}
      />
    </>
  );
}

export default MAutocomplete;
