import {networkLayer} from "./networkLayer";
import {config} from "../config";

const base_path = 'dashboard/study/technician/checkpoints'

export const listCheckpoints = async (id_study : string) => {
    return await networkLayer('get', `${config.dataUrls}/${base_path}/`,
        null,
        {id_study:id_study}
    );
}
//
export const deleteCheckpoint = async (id:string, id_study: string) => {
    return await networkLayer('delete', `${config.dataUrls}/${base_path}/${id}/`,
        null,
        {id_study:id_study}
    );
}

export const updateCheckpoint = async (id:any,data: any, id_study: string) => {
    return await networkLayer('put',
        `${config.dataUrls}/${base_path}/${id}/custom_update/`,
        data,
        {id_study:id_study});
}


export const requestCEREditLink = async (id_study:string, id:string) => {
    if (id == null){
        id = "null"
    }

    return await networkLayer('get',
        `${config.dataUrls}/${base_path}/${id}/get_edit_link/`,
        null,
        {id_study:id_study});
}

export const requestCERReadLink = async (id_study:string, id:string | null) => {
    if (id == null){
        id = "null"
    }

    return await networkLayer('get',
        `${config.dataUrls}/${base_path}/${id}/get_view_link/`,
        null,
        {id_study:id_study});
}