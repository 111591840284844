import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface TabProps {
  label: string;
  content: React.ReactElement;
}

interface MTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: TabProps[];
  tabsStyles?: SxProps<Theme>;
  tabsContent?: JSX.Element | string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

export const MTabs = (props: MTabsProps) => {
  const { value, onChange, tabs, tabsStyles, tabsContent } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={tabsStyles}>
          <Tabs value={value} onChange={onChange}>
            {tabs.map((tab: TabProps) => (
              <Tab 
                key={tab.label} 
                label={tab.label}
              />
            ))}
          </Tabs>
        </Box>
        {tabsContent ? <Box width="100%">{tabsContent}</Box> : <></>}
      </Box>
      {tabs.map((tab: TabProps, index: number) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
}

export default MTabs;