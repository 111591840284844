import { InputLabel, FormHelperText } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { pickersMigrationHack } from '../utils/utils';

interface MTimePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const MTimePicker = (props: MTimePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText } = props;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <TimePicker
          value={value ? new Date(value) : null}
          onChange={onChange}
          disabled={disabled}
          sx={pickersMigrationHack(error)}
        />
      {error ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit' }}>{helperText}</FormHelperText> : <></>}
    </>
  );
}

export default MTimePicker;
