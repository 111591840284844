import * as React from "react";
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {Box, Button, ButtonBase, IconButton, Paper} from "@mui/material";
import {formatDateToShow, formatTimeToShow} from "../utils/utils";


export const renderAccordionHeaderECG = (item: any, buttonText: string, onClick: any) => {

    // { backgroundColor: {"#FFEEEE" : 'white'}}
    return <AccordionSummary sx={(item.is_afib) ? {backgroundColor: "#FFEEEE"} : {backgroundColor: "white"}}>
    <Box width="100%" display="flex" justifyContent="space-between">
    <Box width="75%" justifyContent='left' display='flex' alignItems='center'>
        {item.is_afib && <Box width="3%"><ReportProblemIcon/></Box>}
    {(item.is_afib) ?
        <Box>
            <Typography
            variant="body1">{`Signs of AFib, ${item.heart_rate} bpm average (${formatTimeToShow(item.date)})`}
            </Typography>
        </Box>
    :
        <Box>
            <Typography
            variant="body1">{`Sinus rhythm, ${item.heart_rate} bpm average (${formatTimeToShow(item.date)})`}
            </Typography>
        </Box>
    }
    </Box>
    <Box width="10%" justifyContent="right">
    <Button variant="text" disabled={true} onClick={onClick}><u>{buttonText}</u></Button>
    </Box>

    </Box>
    </AccordionSummary>

}

export const renderAccordionHeaderPPG = (item: any, buttonText: string) => {

    return <AccordionSummary>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems='center'>
    <Box width="75%" justifyContent='left' display='flex'>
    <Box width="3%"><ReportProblemIcon/></Box>
    <Box><Typography variant="body1">{`Signs of AFib (${formatTimeToShow(item.date)})`}</Typography></Box>
    </Box>
    <Box width="10%" justifyContent="right">
    <Button variant="text"><u>{buttonText}</u></Button>
    </Box>

    </Box>
    </AccordionSummary>
}

export const renderAccordionHeaderCompaniesList = (item: any) => {
    let name = (item.name.length > 30) ? item.name.substring(0,40)+"..." : item.name;
    let backgroundColor = (item.departments_number == 0) ? "#F8F8F8" : "#FFFFFF";
    return <AccordionSummary sx={{"background-color":backgroundColor    }}>

    <Box display="flex" alignItems="center">
    <Box mr={2}>
    <Typography variant="body1" fontStyle={{"color":"#4F4949"}}>{name}</Typography>
    </Box>
    <Typography variant="body2">{item.departments_number} clinics</Typography>
    </Box>
    </AccordionSummary>
}
