import MIdOrganizationExternalsTable from "../components/MIdOrganizationExternalsTable";
import { TableHeading } from "../components/MTable";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import {formatDateToShow} from "../utils/utils";

interface IDOrganizationExternalsGridProps {
    data: any[];
    // clientId?: string;
}

export const IDOrganizationExternalsGrid = (props: IDOrganizationExternalsGridProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["id_orgs_ext.w"]);
    
    const { data } = props;

    const tableHeadings = [
        {
            fieldName: 'ID organization external',
            field: 'id_organization_external'
        },
        {
            fieldName: 'Date created',
            field: 'created_at'
        },
        {
            fieldName: 'Description',
            field: 'description'
        },
        canWrite ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    const rows = data.map((row) => ({
        ...row,
        description:row.description,
        id_organization_external:row.id_organization_external,
        created_at: formatDateToShow(row.created_at)
    }));

    return (
        <MIdOrganizationExternalsTable
            headings={tableHeadings}
            rows={rows}
        />
    );
};

export default IDOrganizationExternalsGrid;