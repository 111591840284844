
export const deleteAllEntries = async (dirHandle: FileSystemDirectoryHandle) => {
    for await (const entry of dirHandle.values()) {
        dirHandle.removeEntry(entry.name);
    }

    console.log('All files deleted successfully.');
};



export const writeToFile = async (filename: string, content: string, directoryHandle: any) => {
    if (!directoryHandle) return;
    try {
        const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
        const writable = await fileHandle.createWritable();
        await writable.write(content);
        await writable.close();
        // console.log(`String '${content}' written to file ${filename} successfully.`);
    } catch (error) {
        console.error('Error writing to file:', error);
    }
};

export const writeToBinaryFile = async (filename: string, base64Content: string, directoryHandle: any) => {
    if (!directoryHandle) return;

    try {
        const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
        const writable = await fileHandle.createWritable();

        // Step 1: Decode base64 content
        // const contentBytes = decodeBase64(base64Content);

        const decodedString = atob(base64Content);
        const byteArray = new Uint8Array(decodedString.length);
        for (let i = 0; i < decodedString.length; i++) {
            byteArray[i] = decodedString.charCodeAt(i);
        }

        // Step 2: Write content to file
        await writable.write(byteArray);
        await writable.close();
        console.log(`Data written to file '${filename}' successfully.`);
    } catch (error) {
        console.error('Error writing to file:', error);
    }
};



export const readFromFile = async (filename: string, directoryHandle: any) => {
    if (!directoryHandle) return;
    try {
        const fileHandle = await directoryHandle.getFileHandle(filename);
        const file = await fileHandle.getFile();
        const content = await file.text();
        console.log(`File ${filename} content:`, content);
        return content;
    } catch (error) {
        console.error('Error reading file:', error);
    }
};