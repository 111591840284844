import {networkLayer} from './networkLayer';
import {config} from "../config"

export interface ClinicalReasonsInterface {
  primary_indication?: string;
  reason_for_test?: string;
  medications?: string;
  has_pacemaker: boolean;
  had_stroke: boolean;
  id_unique: string;
}

export interface ICD10Option {
  label: string;
  value: string;
}

export const fetchICD10Codes = async (query: string) => {
  if(query.length < 3) return
  const resp = await fetch(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${query}`)
  const icd10 = await resp.json();

  const data: ICD10Option[] = []
  icd10 && icd10[3].forEach((item: any) => {
    data.push({ label: `${item[0]} - ${item[1]}`, value: item[0]})
  })

  return data;
}

export const createClinicalReasons = async (values: ClinicalReasonsInterface) => {
  return await networkLayer('post', `${config.usersUrls}/dashboard/clinical_reasons/`, {
    primary_indication: values.primary_indication,
    reason_for_test: values.reason_for_test,
    medications: values.medications,
    has_pacemaker: values.has_pacemaker,
    had_stroke: values.had_stroke,
    id_unique: values.id_unique
  });
}

export const getClinicalReasons = async (id_unique: string) => {
  return await networkLayer('get', `${config.usersUrls}/dashboard/clinical_reasons`, null, {id_unique});
}