import StudyForm from "./StudyForm";
import { createStudy, updateDraftStudy, deleleStudy } from '../api/study';

interface StudyFormProps {
  values?: any;
}

export const StudyFormEditDraft = ({ values }: StudyFormProps) => {
  const createStudyHandler = (values: any) => {
    createStudy(values)
  }

  const draftStudyHandler = (values: any) => {
    updateDraftStudy(values)
  }

  
  const deleleStudyHandler = (values: any) => {
    deleleStudy(values)
  }

  if (!values) return <></>;

  return (
    <StudyForm 
      values={values}
      createStudyHandler={createStudyHandler}
      draftStudyHandler={draftStudyHandler}
      deleteStudyHandler={deleleStudyHandler}
    />
  );
};

export default StudyFormEditDraft;