import {Credentials, Endpoint, S3} from "aws-sdk";
import axios from "axios";
import {getUploadingCredentials, updateStudyMeasurement} from "../api/study";

const generateS3Credentials = (credentials: any) => {
    const s3Endpoint = new Endpoint("s3-eu-west-1.amazonaws.com");

    const s3Credentials = new Credentials({
        accessKeyId: credentials.data.access_key,
        secretAccessKey: credentials.data.secret_key,
        sessionToken: credentials.data.session_token
    })

    const s3 = new S3({
        endpoint: s3Endpoint,
        credentials: s3Credentials,
        apiVersion: '2006-03-01',
        signatureVersion: 'v4',
        region: "eu-west-1"
    })

    return { s3Endpoint, s3Credentials, s3 };
}

export const uploadToS3Service = async (key: string) => {
    const credentials = await getUploadingCredentials();
    const { s3 } = await generateS3Credentials(credentials);
    const uploadId = await initMultiPartUpload(credentials, key);

    return {
        credentials,
        s3,
        uploadId
    }
}

export const initMultiPartUpload = async (credentials: any, key: string) => {
    const { s3 } = await generateS3Credentials(credentials);

    const params = {
        Bucket: credentials.data.bucket_name,
        Key: key
    }

    const { UploadId } = await s3.createMultipartUpload(params).promise();
    return UploadId;
}

export const uploadChunk = async (s3: any, credentials: any, file: any, partNumber: number, key: string, uploadId: any, chunkSize: any) => {
    const partParams = {
        Bucket: credentials.data.bucket_name,
        Key: key,
        PartNumber: partNumber,
        UploadId: uploadId ? uploadId : ''
    };

    const url = await s3.getSignedUrlPromise('uploadPart', partParams);

    const sentSize = (partNumber - 1) * chunkSize * 1024 * 1024

    if (file) {
        let chunk = file.slice(sentSize, sentSize + chunkSize * 1024 * 1024)

        let data = await axios.put(url, chunk, {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });


        return {
            PartNumber: partNumber,
            ETag: data.headers.etag.replaceAll('"', ""),
        };
    }
}

export const completeMultiPartUpload = async (s3: any, credentials: any, id: string, key: string, fileType: string | null, uploadId: any, partsArray: any, id_unique:string) => {
    const completeParams = {
        Bucket: credentials.data.bucket_name,
        Key: key,//devices[0].id + ".edf",
        MultipartUpload: {
            Parts: partsArray,
        },
        UploadId: uploadId ? uploadId : "",
    };

    s3.completeMultipartUpload(completeParams, async (err: any, data: any) => {
        if (err) {
            console.error("Error completing multipart upload:", err);
        } else {
            console.log('Completed!');
        }
    });
}