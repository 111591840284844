import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MModal from "./MModal";
import { Typography } from "@mui/material";
import { MButton } from "./MButton";
import MTextField from "./MTextField";
import {
    addIdOrganizationExternal,
    deleteIdOrganizationExternal,
    updateIdOrganizationExternal
} from "../api/id_organization_external";
import MTable, { MTableActionsButtons, MTableProps, TableHeading } from "./MTable";
import { PermissionsE, usePermissions } from "../context/usePermissions";

interface MIdOrganizationExternalsTableProps extends MTableProps {
    onRowClick?: (id: string) => void;
}

const defaultRow = {
    description: '',
    id_organization_external : '3'
}

export const MIdOrganizationExternalsTable = (props: MIdOrganizationExternalsTableProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["id_orgs_ext.w"]);

    const [busy, setBusy] = useState(false);
    const { headings, rows, sx, onRowClick } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState<any>(defaultRow);
    const [isEditModal,setIsEditModal] = useState<boolean>(false);


    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "id_organization_external":
                return '"'+row.id_organization_external+'"';
            case "actions":
                return canWrite ? (
                    <MTableActionsButtons 
                        rowData={row}
                        onEdit={() => {
                            setSelectedRow(row);
                            setIsEditModalOpen(true);
                            setIsEditModalOpen(true)
                        }}
                        onDelete={()=>{
                            setSelectedRow(row);
                            setIsDeleteModalOpen(true);
                        }}
                    />
                ) : undefined;
            default:
                return row[heading.field];
        }
    };

    const handleItemChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const submitUpdateItem = async() => {
        if (!canWrite) return;
        let data = {
            description: selectedRow.description,
            id_organization_external: selectedRow.id_organization_external
        }
        await updateIdOrganizationExternal(selectedRow.id,data);
        window.location.reload();
    }

    const submitItem = async () => {
        if (!canWrite) return;
        let data = {
            description: selectedRow.description,
            id_organization_external: selectedRow.id_organization_external
        }
        await addIdOrganizationExternal(data);
        window.location.reload();
    }

    const submitDeleteItem = async() => {
        if (!canWrite) return;
        setBusy(true);
        await deleteIdOrganizationExternal(selectedRow?.id);
        window.location.reload();
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        if(isEditModal){
            await submitUpdateItem().finally(() => setTimeout(() => setBusy(false), 500));
        } else {
            await submitItem().finally(() => setTimeout(() => setBusy(false), 500));
        }
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Id Organization External deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow.id_organization_external}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsDeleteModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={submitDeleteItem}>Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        ID organization external
                    </Typography>

                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Value"
                        value={selectedRow?.id_organization_external}
                        onChange={(e) => {handleItemChange("id_organization_external",e.target.value)}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Description(Put '\n' to add line break)"
                        multiline={true}
                        value={selectedRow?.description}
                        onChange={(e) => {handleItemChange("description", e.target.value)}}
                    />
                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => {setIsEditModalOpen(false)}}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >

                <Typography variant="h4">
                    ID organization externals
                </Typography>

                {canWrite ? (
                    <MButton variant="contained" color="primary" onClick={() => {
                        setIsEditModal(false);
                        setIsEditModalOpen(true);
                        setSelectedRow(defaultRow)
                    }}>
                        Add ID
                    </MButton>
                ) : <></>}

            </Box>
            <MTable 
                headings={headings}
                rows={rows}
                sx={sx}
                renderCell={renderCell}
            />  
        </div>
    );
}

export default MIdOrganizationExternalsTable;


