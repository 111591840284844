import { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getClinicDetail, updateClinic } from "../api/clinic";
import { getClinicStaffList } from "../api/clinic_staff"
import { Box, Button, Grid } from "@mui/material";
import AccountsGrid from "../containers/AccountsGrid";
import { baseUrls } from "../routes";
import {analysisPlatforms, featureFlagsList, languages, studiesList} from "../utils/constants";
import MCheckbox from "../components/MCheckbox";
import MTextField from "../components/MTextField";
import AdminMeasurementsGrid from "../containers/AdminMeasurementsGrid";
import { listStudiesClinicAdmin } from "../api/study";
import MSelect from "../components/MSelect";
import { listIdOrganizationExternals } from "../api/id_organization_external";
import { MBreadcrumbs } from "../components/MBreadcrumbs";
import { prepareAdminBreadcrumbs } from "../utils/utils";
import { deleteStaffProfile, updateStaffProfile } from "../api/clinic_staff";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const Clinic = () => {
    const { hasPermission } = usePermissions();
    const { clinicId } = useParams();
    const navigate = useNavigate();

    const [clinicData, setClinicData] = useState<any>(null);

    const [studies, setStudies] = useState<any[]>([]);
    const [clinicName, setClinicName] = useState("");
    const [allowedStudies, setAllowedStudies] = useState<string[]>([]);
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);
    const [studyLanguage, setStudyLanguage] = useState("");
    const [analysisPlatform, setAnalysisPlatform] = useState("amps");

    const [idOrgExternal, setIdOrgExternal] = useState("");
    const [idOrganizationExternals, setIdOrganizationExternals] = useState([]);

    const [isEditClinic, setIsEditClinic] = useState(false);

    const [staffProfiles, setStaffProfiles] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const loadContent = useCallback(async () => {
        if (clinicId) {
            await fetchClinicDetail()
            await fetchStaffProfilesList()
            await fetchStudies()
        }
    }, [clinicId])

    useEffect(() => {
        loadContent()
    }, [loadContent]);

    const fetchStudies = async () => {
        // setIsMeasurementAvailableMore(false);
        const studies = await listStudiesClinicAdmin(clinicId)

        let sortedDataDescending = studies.data.sort((a: any, b: any) =>
            new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
        );
        setStudies(sortedDataDescending)
    }

    const fetchStaffProfilesList = async () => {
        if (clinicId != null) {
            const data = await getClinicStaffList(clinicId);
            setStaffProfiles(data);
            setIsLoaded(true);
            await fetchClinicDetail();
        }
    }

    const fetchClinicDetail = async () => {
        if (clinicId != null) {
            const data = await getClinicDetail(clinicId);
            setClinicData(data);

            setClinicName(data['name']);
            setAllowedStudies(data['allowed_studies'])
            setStudyLanguage(data['study_language'])
            setAnalysisPlatform(data['analysis_platform'])
            setIdOrgExternal(data['id_organization_external'])
            setFeatureFlags(data['feature_flags'])

            const idOptions = await listIdOrganizationExternals();

            let options = idOptions.map((originalDict: any) => {
                return {
                    value: originalDict.id_organization_external,
                    label: originalDict.id_organization_external + " - " + originalDict.description,
                };
            });

            setIdOrganizationExternals(options);
        }
    }

    const addAccount = () => {
        navigate(`${baseUrls.addAccount}?clinicId=${clinicId}`)
    }

    const saveAccountChange = async () => {
        let data = {
            "name": clinicName,
            "allowed_studies": allowedStudies,
            "study_language": studyLanguage,
            "id_organization_external": idOrgExternal,
            "feature_flags" : featureFlags
        }
        await updateClinic(data, clinicId)
        setIsEditClinic(false);
    }

    const handleAllowedStudiesCheck = (event: any, key: string) => {
        var updatedList = [...allowedStudies];

        if (event.target.checked) {
            updatedList = [...allowedStudies, key];
        } else {
            updatedList.splice(allowedStudies.indexOf(key), 1);
        }
        setAllowedStudies(updatedList);
    };

    const handleFeatureFlagsCheck = (event: any, key: string) => {
        var updatedList = [...featureFlags];

        if (event.target.checked) {
            updatedList = [...featureFlags, key];
        } else {
            updatedList.splice(featureFlags.indexOf(key), 1);
        }
        setFeatureFlags(updatedList);
    };

    function renderEditClientButtons() {
        if (isEditClinic) {
            return <>
                <Button color="error" onClick={() => {
                    setIsEditClinic(false);
                    getClinicDetail(clinicId);
                }
                }>
                    Cancel
                </Button>
                <Button onClick={() => {
                    setIsEditClinic(true);
                    saveAccountChange();
                }
                }>Save</Button>
            </>
        } else {
            return <>
                <Button onClick={() => setIsEditClinic(true)}>Edit</Button>
            </>
        }
    }

    const region = clinicData ? {
        pageName: clinicData.region_name,
        to: clinicData.region
    } : undefined;

    const cluster = clinicData ? {
        pageName: clinicData.cluster_name,
        to: clinicData.cluster
    } : undefined;

    const clinic = clinicData ? {
        pageName: clinicData.name,
        to: clinicData.id
    } : undefined;

    const pageHeaderActions = [
        hasPermission!(PermissionsE["units__staff.w"]) ? {
            onClick: addAccount,
            name: "Add Staff Account"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <Box mt={2}>
                <MBreadcrumbs {...prepareAdminBreadcrumbs(region, cluster, clinic)} />
            </Box>
            <PageHeader
                title={`${!clinicName ? "??" : clinicName}`}
                subTitle="clinic"
                actions={pageHeaderActions}
            />

            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4" paragraph={true}>
                            Client details  {renderEditClientButtons()}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MTextField
                                            label="Clinic name"
                                            value={clinicName}
                                            onChange={(e) => setClinicName(e.target.value)}
                                            disabled={!isEditClinic}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MSelect
                                            label="Studies language"
                                            value={studyLanguage}
                                            disabled={!isEditClinic}
                                            options={languages}
                                            onChange={(e) => setStudyLanguage(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                hasPermission!(PermissionsE['units.r_tech']) ? (
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ mt: 1 }}>
                                                    Allowed studies
                                                </Typography>
                                                {studiesList.map((item, index) => (
                                                    <>
                                                        <MCheckbox
                                                            key={index}
                                                            label={item.label}
                                                            disabled={!isEditClinic}
                                                            checked={allowedStudies.includes(item.value)}
                                                            onChange={(e) => { handleAllowedStudiesCheck(e, item.value) }}
                                                        />
                                                        <br />
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ mt: 1 }}>
                                                    Feature flags
                                                </Typography>
                                                {featureFlagsList.map((item, index) => (
                                                    <>
                                                        <MCheckbox
                                                            key={index}
                                                            label={item.label}
                                                            disabled={!isEditClinic}
                                                            checked={featureFlags.includes(item.value)}
                                                            onChange={(e) => { handleFeatureFlagsCheck(e, item.value) }}
                                                        />
                                                        <br />
                                                        <br />
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MSelect
                                                    value={idOrgExternal}
                                                    disabled={!isEditClinic}
                                                    label="ID organization external"
                                                    options={idOrganizationExternals}
                                                    onChange={(e) => { setIdOrgExternal(e.target.value) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MSelect
                                                    label="Analysis platform"
                                                    disabled={!isEditClinic}
                                                    value={analysisPlatform}
                                                    options={analysisPlatforms}
                                                    onChange={(e) => { setAnalysisPlatform(e.target.value); }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : <></>
                            }
                        </Grid>
                    </Grid>
                    {
                        hasPermission!(PermissionsE['units__staff.r']) ? (
                            <Grid item xs={12}>
                                <Typography variant="h4" paragraph={true}>
                                    Staff accounts
                                </Typography>
                                <AccountsGrid
                                    data={staffProfiles}
                                    onUpdate={updateStaffProfile}
                                    onDelete={deleteStaffProfile}
                                    updateLink={`${baseUrls.editAccount}?clinicId=${clinicId}`}
                                    entity={'clinic'}
                                />
                            </Grid>
                        ) : <></>
                    }

                    <Grid item xs={12}>
                        <Typography variant="h4" paragraph={true}>
                            Latest studies
                        </Typography>
                        <AdminMeasurementsGrid data={studies} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Clinic;