import { useState } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MButton } from "../components/MButton";
import { MTextField } from "../components/MTextField";
import MDateTimePicker from "../components/MDateTimePicker";
import ReactJson from "react-json-view";
import InputLabel from "@mui/material/InputLabel";
import { updateStudyMeasurement } from "../api/study";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const MeasurementInfo = (newItem: any) => {
    const { hasPermission } = usePermissions();

    const [busy, setBusy] = useState(false);
    const [defaultItem, setDefaultItem] = useState<any>(newItem.newItem);
    const [item, setItem] = useState<any>(newItem.newItem);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const postUpdateMeasurement = async () => {
        if (!hasPermission!(PermissionsE['study.w_meta'])) return;
        setBusy(true);
        let measurement_data = {
            "id": item.id,
            "analysis_configuration": (item.analysis_platform == "cardio.ai") ? item.analysis_configuration : null,
            "date_start": item.date_start,
            "analysis_platform": item.analysis_platform,
            "analysis_token": (item.analysis_platform == "cardio.ai") ? item.analysisToken : null,
            "report_email": item.report_email,
            "id_unique": item.id_userprofile,
            "ordering_physician_full_name": item.ordering_physician_full_name
        }
        await updateStudyMeasurement(measurement_data).finally(() => setTimeout(() => setBusy(false), 500));
        setIsEdit(false);
    }

    const handleMeasurementChange = (keyToUpdate: string, newValue: string | object) => {
        let newDict = { ...item };
        newDict[keyToUpdate] = newValue;
        setItem(newDict);
    };

    const isCardioAI = item['analysis_platform'] === "cardio.ai";

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Typography
                        variant="h4"
                        fontStyle={{ "color": "#4F4949", "fontWeight": "bold" }}
                    >
                        Device S/N: {item.device_serial_number}
                    </Typography>
                    {
                        hasPermission!(PermissionsE['study.w_meta']) ? (
                            <Box ml={1}>
                                {isEdit ?
                                    <>
                                        <MButton
                                            variant="text"
                                            hidden={!isEdit}
                                            color="error"
                                            onClick={() => {
                                                setIsEdit(false);
                                                setItem(defaultItem);
                                            }}
                                        >
                                            Cancel
                                        </MButton>
                                        <MButton
                                            busy={busy}
                                            variant="text"
                                            hidden={!isEdit}
                                            onClick={() => {
                                                postUpdateMeasurement();
                                                setIsEdit(false);
                                            }}
                                        >
                                            Save
                                        </MButton>
                                    </> : (
                                        <MButton variant="text"
                                            onClick={() => {
                                                setIsEdit(true);
                                            }}
                                        >
                                            Edit
                                        </MButton>
                                    )}
                            </Box>
                        ) : <></>
                    }

                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MTextField
                            label="ID (internal identifier)"
                            value={item['id']}
                            disabled={true}
                            onChange={(e) => {
                                handleMeasurementChange("id", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Report email"
                            value={item?.report_email}
                            disabled={!isEdit}
                            onChange={(e) => {
                                handleMeasurementChange("report_email", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MTextField
                            label="Ordering physician"
                            value={item?.ordering_physician_full_name}
                            onChange={(e) => {
                                handleMeasurementChange("ordering_physician_full_name", e.target.value);
                            }}
                            disabled={!isEdit}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MDateTimePicker
                            label="Date Study Started"
                            value={item?.date_start}
                            disabled={!isEdit}
                            onChange={(e) => {
                                if (e !== null) {
                                    handleMeasurementChange("date_start", e.toISOString());
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDateTimePicker
                            label="Date Measurement uploaded"
                            value={item?.date_upload}
                            disabled={true}
                            onChange={(e) => {
                                if (e !== null) {
                                    handleMeasurementChange("date_upload", e.toISOString());
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {isCardioAI && (
                <Grid item xs={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MTextField
                                label="Analysis token"
                                value={item?.analysis_token}
                                variant="standard"
                                disabled={!isEdit}
                                onChange={(e) => {
                                    handleMeasurementChange("analysis_token", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="URL Track(link to analysis software"
                                value={item?.url_track}
                                variant="standard"
                                disabled={true}
                                onChange={(e) => {
                                    handleMeasurementChange("url_track", e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                <InputLabel>Analysis configuration</InputLabel>
                            </Typography>
                            <ReactJson
                                src={(item['analysis_configuration'] === null) ? {} : item['analysis_configuration']}
                                onEdit={(e) => {
                                    handleMeasurementChange("analysis_configuration", e.updated_src);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}