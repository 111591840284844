import { useCallback, useEffect, useState } from "react";
import { Paper } from "@mui/material";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { Account, deleteAccount, getAccountsList, updateAccount } from "../api/accounts";
import AccountsGrid from "../containers/AccountsGrid";
import { baseUrls } from "../routes";
import { useNavigate } from "react-router-dom";
import { usePermissions, PermissionsE } from "../context/usePermissions";
import { useAuth, isSuperAdmin } from "../context/useAuth";

export const Accounts = () => {
    const { hasPermission } = usePermissions();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState<Account[]>([]);

    const canWrite = hasPermission!(PermissionsE["system__staff.w"]);

    const fetchAccountsList = useCallback(async () => {
        if(!hasPermission!(PermissionsE["system__staff.r"])) return;
        const data = await getAccountsList();

        setAccounts(data);
    }, [])

    useEffect(() => {
        fetchAccountsList()
    }, []);

    const pageHeaderActions = [
        canWrite ? {
            name: "Add account",
            onClick: () => navigate(baseUrls.addAccount)
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <PageHeader 
                title="Accounts"
                actions={pageHeaderActions}
            />
            {
                hasPermission!(PermissionsE["system__staff.r"]) ? (
                    <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                        <AccountsGrid 
                            data={accounts}
                            updateLink={canWrite ? baseUrls.editAccount : undefined}
                            onDelete={canWrite ? deleteAccount : undefined}
                            onUpdate={canWrite ? updateAccount : undefined}
                        />  
                    </Paper>
                ) : <></>
            }
        </>
    );
};

export default Accounts;