import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getStudyFileURL, reSubmitStudy, uploadPDFReport } from "../api/study";
import MFileUpload from "../components/MFileUpload";
import MButtonWithConfirm from "../components/MButtonWithConfirm";
import { MButton } from "../components/MButton";
import { baseUrls } from "../routes";
import MModal from "../components/MModal";
import { Link } from "react-router-dom";
import { formatDateTimeToShow } from "../utils/utils";
import { Grid } from "@mui/material";
import { PermissionsE, usePermissions } from "../context/usePermissions";

enum FileType {
    SOURCE = 'source',
    PROCESSED = 'processed'
}

export const StudyActionsAdmin = (study: any) => {
    const { hasPermission } = usePermissions();

    const [PDFBase64, setPDFBas64] = useState<any>(null);
    const [isReSubmited, setIsResubmited] = useState(false);
    const [busy, setBusy] = useState(false);
    const [rawFilesList, setRawFilesList] = useState([]);
    // const [processedFilesList, setProcessedFilesList] = useState([]);
    const [isFileLinksModalOpen, setIsFileLinksModalOpen] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState(FileType.SOURCE);

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {

                let encoded = fileReader.result?.toString().replace(/^data:(.*,)?/, '');
                if (encoded) {
                    if ((encoded?.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }

            }
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            }
        })
    }
    const handleFileRead = async (file: File | undefined) => {
        if (!file) {
            setPDFBas64(null);
            return;
        }
        const base64 = await convertBase64(file)
        setPDFBas64(base64);
    }

    const uploadNewPDF = async (isSaveOriginal?: boolean) => {
        let id_track = null;
        if (study.id_track) {
            id_track = study.id_track
        } else {
            id_track = study.measurements[0].id_track
        }

        if (id_track) {
            setBusy(true);
            let data = {
                "file_base64": PDFBase64,
                "exam_reporting_request_id": id_track,
                "is_save_original": !!isSaveOriginal
            }
            await uploadPDFReport(data).finally(() =>
                setTimeout(() => {
                    setBusy(false);
                }, 500)
            );
            window.location.reload();
        }
    }

    const sendStudyReSubmit = async (isResubmit?: boolean) => {
        let data = {
            id_study: study.id,
            is_update_database: !!isResubmit
        }
        await reSubmitStudy(data)
        setIsResubmited(true);
    }

    const getRawFiles = async (fileType: string) => {
        let data = await getStudyFileURL(study.id, fileType);
        let urls = data.data.urls;

        setRawFilesList(urls);


        setIsFileLinksModalOpen(true);
        setBusy(false);

        //setIsResubmited(true);
    }


    const resubmitOptions = [
        {
            buttonText: 'Re-submit report',
            description: 'Re-submit. Once processed, generated report will be added to Mawi System',
            onConfirm: () => sendStudyReSubmit(true),
            confirmContent: (
                <Box mb={2} textAlign="center">
                    <Typography variant="h4">File resubmission</Typography>
                    <Box my={2}>
                        Do you want re-submit report?
                    </Box>
                </Box>
            )
        },
        {
            buttonText: 'Duplicate report',
            description: 'Duplicate. Re-uploaded record will be ignored by Mawi system',
            onConfirm: () => sendStudyReSubmit(),
            confirmContent: (
                <Box mb={2} textAlign="center">
                    <Typography variant="h4">Duplicate report</Typography>
                    <Box my={2}>
                        Do you want duplicate report?
                    </Box>
                </Box>
            )
        }
    ]

    const modal = (
        <MModal
            open={isFileLinksModalOpen}
            handleClose={() => {
                setIsFileLinksModalOpen(false)
                setBusy(false);
            }}
            width={600}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{ textAlign: "center", mb: 4 }} paragraph={true}>
                    Study raw files links
                </Typography>
                {selectedFileType === FileType.SOURCE && (
                    <>
                        {rawFilesList.map((url_element: any, index) => (
                            <Typography variant="h5" sx={{ textAlign: "left", mt: 4 }}>
                                File name: {url_element?.file_name} <br />
                                Link: <a href={url_element?.url} download> Save file</a> <br />
                                Link expires: {formatDateTimeToShow(new Date(Date.now() + url_element?.expire_on * 1000))}
                            </Typography>
                        ))}
                    </>
                )}


                <Box sx={{ textAlign: "center", mt: 4 }}>
                    <MButton busy={busy} sx={{ ml: 2 }} onClick={() => { setIsFileLinksModalOpen(false) }}>
                        Close
                    </MButton>
                </Box>
            </Box>
        </MModal>
    );

    return (
        <>
            {modal}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" fontStyle={{ "color": "#4F4949" }}>Study actions</Typography>
                </Grid>
                {
                    hasPermission!(PermissionsE["study.rep_replace"]) ? (
                        <Grid item xs={5}>
                            <Typography variant="h5">Replace report: </Typography>
                            <Box my={3}>
                                <MFileUpload
                                    inputProps={{ accept: '.pdf' }}
                                    onFileChangeHandler={f => handleFileRead(f)}
                                />
                            </Box>
                            {
                                PDFBase64 && (
                                    <Box>
                                        <Box mb={2}>
                                            <MButtonWithConfirm
                                                busy={busy}
                                                confirmHandler={() => uploadNewPDF(true)}
                                                confirmContent={
                                                    <Box mb={2}>
                                                        <Typography variant="h4">Do you want save without changes?</Typography>
                                                    </Box>
                                                }
                                            >
                                                Save without changes
                                            </MButtonWithConfirm>
                                        </Box>
                                        <Box>
                                            <MButtonWithConfirm
                                                busy={busy}
                                                confirmHandler={() => uploadNewPDF()}
                                                confirmContent={
                                                    <Box mb={2}>
                                                        <Typography variant="h4">Do you want process report?</Typography>
                                                    </Box>
                                                }
                                            >
                                                Process (remove cover page, add meta data etc.)
                                            </MButtonWithConfirm>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Grid>
                    ) : <></>
                }
                {
                    hasPermission!(PermissionsE["study.submission"]) ? (
                        <Grid item xs={4}>
                            {!isReSubmited && (
                                <Box>
                                    <Typography variant="h5">Re-submit report for analysis</Typography>
                                    <Box mt={3}>
                                        {resubmitOptions.map((o, key) =>
                                            study.analysis_platform === "amps_cers" && o.buttonText === "Duplicate report" ? null : (
                                                <Box display="flex" alignItems="center" mb={2} key={key}>
                                                    <Box mr={2}>
                                                        <MButtonWithConfirm
                                                            confirmHandler={o.onConfirm}
                                                            confirmContent={o.confirmContent}
                                                            sx={{ width: 160 }}
                                                        >
                                                            {o.buttonText}
                                                        </MButtonWithConfirm>
                                                    </Box>
                                                    <Box sx={{ maxWidth: 300 }}>
                                                        <Typography sx={{ fontSize: 12, lineHeight: '16px' }}>
                                                            {o.description}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        )}
                                        {/*{resubmitOptions.map((o, key) => (*/}
                                        {/*    <Box display="flex" alignItems="center" mb={2} key={key}>*/}
                                        {/*        <Box mr={2}>*/}
                                        {/*            <MButtonWithConfirm*/}
                                        {/*                confirmHandler={o.onConfirm}*/}
                                        {/*                confirmContent={o.confirmContent}*/}
                                        {/*                sx={{ width: 160 }}*/}
                                        {/*            >*/}
                                        {/*                {o.buttonText}*/}
                                        {/*            </MButtonWithConfirm>*/}
                                        {/*        </Box>*/}
                                        {/*        <Box sx={{ maxWidth: 300 }}>*/}
                                        {/*            <Typography sx={{ fontSize: 12, lineHeight: '16px' }}>*/}
                                        {/*                {o.description}*/}
                                        {/*            </Typography>*/}
                                        {/*        </Box>*/}
                                        {/*    </Box>*/}
                                        {/*))}*/}
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    ) : <></>
                }
                {
                    hasPermission!(PermissionsE["study.raw"]) ? (
                        <Grid item xs={3}>
                            <Typography variant="h5">Study file links</Typography>
                            <Box mt={3} display="flex" flexDirection="column" sx={{ width: 160 }}>
                                <MButton
                                    disabled={busy}
                                    onClick={(e) => {
                                        setBusy(true);
                                        setSelectedFileType(FileType.SOURCE)
                                        // setIsFileLinksModalOpen(true);
                                        getRawFiles(FileType.SOURCE)
                                    }}
                                >
                                    Raw files
                                </MButton>
                            </Box>
                        </Grid>
                    ) : <></>
                }

            </Grid>
        </>

    )
}