import {getBaseURL, networkLayer} from "./networkLayer";
import {config} from "../config"

export const getValueFromLocalStorage = async(key: string) => {
  try {
    let user = localStorage.getItem('user');
    return user && JSON.parse(user)[key]
  } catch {
    return null
  }
}

export const getRandomValueFromLocalStorage = async(key: string) => {
  try {
    let object = localStorage.getItem(key);
    if(object){
      return JSON.parse(object);
    }
    return null;
  } catch {
    return null
  }
}

export const setValueToLocalStorage = async (key: string, value: any) => {
  try {
    if (typeof value === "object") {
      await localStorage.setItem(key, JSON.stringify(value))
    } else {
      await localStorage.setItem(key, value)
    }
  } catch {
    return null;
  }
}

export const getValueFromSessionStorage = async(key: string) => {
  try {
    let data = sessionStorage.getItem(key);
    return data && JSON.parse(data)
  } catch {
    return null
  }
}

export const setValueToSessionStorage = async (key: string, value: any) => {
  try {
    if (typeof value === "object") {
      await sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      await sessionStorage.setItem(key, value)
    }
  } catch {
    return null;
  }
}

export const removeValueFromSessionStorage = async (key: string) => {
  try {
    await sessionStorage.removeItem(key)
  } catch {
    return null;
  }
}

export const refreshToken = async () => {
  const refresh = await getValueFromLocalStorage('refresh');
  if (!refresh) return;

  const data = await networkLayer('post',
      `${config.baseUrl}/auth/refresh`,
      { refresh },
      undefined,
      undefined,
      true);


  const staff_profile = await getValueFromLocalStorage('staff_profile');
  await setValueToLocalStorage('user', {
    ...data,
    staff_profile
  });
}

export const authenticateUser = async (email: string, password: string) => {
  //let base_url = await getBaseURL(email);
  return await networkLayer('post', `${config.baseUrl}/auth/obtain`, {
    email,
    password
  });
}