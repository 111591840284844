import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent } from "react";

export interface MCheckboxProps {
    label?: string;
    value?: boolean | false;
    checked: boolean;
    onChange?: (event : ChangeEvent,checked: boolean | null) => void;
    disabled?: boolean | false;
}

export const MCheckbox = (props: MCheckboxProps) => {
    const {label = "", value = false, disabled=false, checked = false, onChange} = props;

    return (
        <FormControlLabel
            control={<Checkbox value={value} disabled={disabled} checked={checked}  onChange={onChange} />}
            label={label}
        />
    );
}

export default MCheckbox;