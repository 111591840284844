import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
const Footer = () => {
    const showCertificationLink = process.env.REACT_APP_API_BASE_URL?.includes("saudi");

    return (
        <Box
            sx={{
                backgroundColor: 'transparent', // Make it transparent
                color: 'black',
                textAlign: 'center',
                padding: '16px',
                position: 'relative',
                bottom: 0,
                width: '100%',
            }}
        >
            <Typography variant="body1">
                &copy; 2024 Mawi Inc.
            </Typography>

            {showCertificationLink && (
                <Typography variant="body2">
                    <RouterLink to="/specifications" style={{ color: 'inherit', textDecoration: 'underline' }}>
                        Specifications
                    </RouterLink>
                </Typography>
            )}
        </Box>
    );
};

export default Footer;