import { InputLabel, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pickersMigrationHack } from '../utils/utils';

interface MDatePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const MDatePicker = (props: MDatePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText } = props;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <DatePicker
        value={value ? new Date(value) : null}
        onChange={onChange}
        disabled={disabled}
        sx={{
          width: "100%",
          ...pickersMigrationHack(error)
        }}
      />
      {error ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit' }}>{helperText}</FormHelperText> : <></>}
    </>
  );
}

export default MDatePicker;
