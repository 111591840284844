import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MButton } from "../components/MButton";

export interface PageHeaderActionsProps {
  onClick: () => void;
  name: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
}

export interface PageHeaderProps {
  title: string | JSX.Element;
  subTitle?: string;
  actions?: PageHeaderActionsProps[];
}

const PageHeader = ({ title, subTitle, actions }: PageHeaderProps) => {
  return (
    <>
      <Box py={3.5} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box 
          display="flex" 
          alignItems="center"
          sx={{
            '& .MuiTypography-root': { paddingRight: 2 },
          }}
        >
          <Typography variant="h3">{title}</Typography>
          {subTitle && <Typography><b>{subTitle}</b></Typography>}
        </Box>
        {
          actions && (
            <Box
            sx={{
              '& .MuiButton-root:not(:last-child)': { marginRight: 2 },
            }}
            >
              {
                actions.map((action, key) => (
                  <MButton key={key} onClick={action.onClick} color={action.color || 'primary'} variant={action.variant}>{action.name}</MButton>
                ))
              }
            </Box>
          )
        }
      </Box>
    </>
  );
};

export default PageHeader;