import { networkLayer } from './networkLayer';
import { config } from "../config"

export interface Unit {
    id: string;
    name: string;
    cluster?: string;
    region?: string;
}

export interface Account {
    email: string;
    role: string;
    name: string;
    units?: Unit[];
}

export const getAccountsList = async () => {
    return await networkLayer('get', `${config.baseUrl}/system_staff/`);
}

export const addAccount = async (data: any) => {
    return await networkLayer('post', `${config.baseUrl}/system_staff/`, data);
}

export const getAccount = async (userId: string) => {
    return await networkLayer('get', `${config.baseUrl}/system_staff/${userId}/detail/`);
}

export const updateAccount = async (userId: string, data: any) => {
    return await networkLayer('patch', `${config.baseUrl}/system_staff/${userId}/update/`, data);
}

export const deleteAccount = async (userId: string) => {
    return await networkLayer('delete', `${config.baseUrl}/system_staff/${userId}/`, null, null);
}


export type RegionClusterEntity = 'region' | 'cluster';

export const getRegionClusterAccountsList = async (entity: RegionClusterEntity, entityId: string) => {
    return await networkLayer('get', `${config.baseUrl}/${entity}/${entityId}/staff/list/`);
}

export const addRegionClusterAccount = async (entity: RegionClusterEntity, entityId: string, data: any) => {
    return await networkLayer('post', `${config.baseUrl}/${entity}/${entityId}/staff/`, data);
}

export const getRegionClusterAccount = async (entity: RegionClusterEntity, entityId: string, userId: string) => {
    return await networkLayer('get', `${config.baseUrl}/${entity}/${entityId}/staff/${userId}/detail/`);
}

export const updateRegionClusterAccount = async (entity: RegionClusterEntity, entityId: string, userId: string, data: any) => {
    return await networkLayer('patch', `${config.baseUrl}/${entity}/${entityId}/staff/${userId}/`, data);
}

export const deleteRegionClusterAccount = async (entity: RegionClusterEntity, entityId: string, userId: string) => {
    return await networkLayer('delete', `${config.baseUrl}/${entity}/${entityId}/staff/${userId}/`, null, null);
}