import moment from "moment";

export const validateRequiredField = (fieldName: string, value: string | Date | null) => {
  if (!value) {
    return `${fieldName} is required field`;
  }
  
  return "";
}

export const validateEmail = (email: string) => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email) {
    return "Email is required field";
  }

  if (!email.match(regexEmail)) {
    return "Wrong email format";
  }
  
  return "";
}

export const validateDate = (fieldName: string, date: Date | null) => {
  if (!date) {
    return `${fieldName} is required field`;
  }

  if (!moment(date).isValid()) {
    return "Wrong date format";
  }

  return "";
}

export const validateDeviceSerialNumber = (isExists: boolean, isConnectable: boolean) => {
  if (!isExists) {
    return "Device doesn't exists";
  }

  if (!isConnectable) {
    return "Device is not connectable";
  }
  
  return "";
}