import Box from '@mui/material/Box';
import {formatDateTimeLong, formatDateTimeToShow} from '../utils/utils';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

export enum EventSeverity {
    error = 'error',
    info = 'info',
    success = 'success'
}

export interface AdminStudyLogItem {
    event_severity: EventSeverity,
    date: Date,
    event_code: string,
    event_text?: string,
    event_author?: string
}


interface AdminStudyLogsProps {
    logs: AdminStudyLogItem[];
}

export interface AdminStudyLogsHeading {
    field: string,
    minWidth?: number
}

const headings = [
    {
        field: 'event_severity'
    },
    {
        field: 'date',
        minWidth: 155
    },
    {
        field: 'event_code',
        minWidth: 155
    },
    {
        field: 'event_text'
    },
    {
        field: 'event_author',
        minWidth: 155
    }
]

export const AdminStudyLogs = ({ logs }: AdminStudyLogsProps) => {

    if (!logs.length) return (
        <Box>No logs to display</Box>
    )

    const eventSeverityColor = (eventSeverity: EventSeverity) => {
        switch(eventSeverity){
            case "info": return "#d9d9d9";
            case "error": return "#ee3074";
            case "success": return "#30ce32";
            default: return "#d9d9d9";
          }
    }

    const isJSON = (text: string) => {
        try {
            JSON.parse(text);
            return true;
        } catch {
            return false;
        }
    };

    const isHTML = (text: string) => /<\/?[a-z][\s\S]*>/i.test(text);

    
    const renderCell = (heading: AdminStudyLogsHeading, row: any) => {
        let content = row[heading.field];

        switch (heading.field) {
            case "event_severity":
                return  <Box 
                            sx={{ 
                                background: eventSeverityColor(row.event_severity), 
                                borderRadius: '50%', 
                                width: 16, 
                                height: 16
                            }} 
                        />;
            case "date":
                return formatDateTimeLong(row.date);
            case "event_code":
                return row.event_code.replace(/_/g, " ");  
            case "event_author":
                return row.event_author ? `by ${row.event_author}` : ''; 
            default:
                if (typeof content === 'string') {
                    content = content.replace(/'/g, '"');
                    content = content.replace(/([{,]\s*)([a-zA-Z0-9_]+)(\s*:\s*)/g, '$1"$2"$3');
                    // Beautify JSON if applicable
                    if (isJSON(content)) {
                        const formattedJSON = JSON.stringify(JSON.parse(content), null, 2);
                        return <pre style={{ fontSize: 12, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{formattedJSON}</pre>;
                    }
                    const a = 2;
                    // Render HTML if detected
                    if (isHTML(content)) {
                        return <span dangerouslySetInnerHTML={{ __html: content }} />;
                    }
                }
                return content;
        }
    };

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {logs.map((row: any, key: number) => (
                        <TableRow key={key}>
                            {headings.map((heading: AdminStudyLogsHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16, padding: 0.5, minWidth: heading.minWidth }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                    <>{renderCell(heading, row) || ''}</>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer> 
    );
}

export default AdminStudyLogs;
