import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMore from "highcharts/highcharts-more";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { renderAccordionECG, renderAccordionPPG} from '../components/MAccordion';
import {
  getSaturationList,
  getHeartRateList,
  getPPGList,
  getECGList,
  getBPList,
  getPPGData,
  getECGData
} from "../api/study";
import moment, {Moment} from "moment";
import { formatDateToShow, formatTimeToShow } from "../utils/utils";
import {bloodPressureChart, heartRateChart, saturationChart} from "../components/MChart";
import MList from "../components/MList";
import {renderAccordionHeaderECG, renderAccordionHeaderPPG} from "../components/MAccordionHeader";
import {renderAccordionDetailsPPG} from "../components/MAccordionDetails";

HCMore(Highcharts);

interface StudySummaryRPMProps {
  studyId: string;
}

export const StudySummaryRPM = ({ studyId }: StudySummaryRPMProps) => {
  //const studyId = newStudyId;

  const [counter, setCounter] = useState(0);
  const currentDate = moment().add(counter, 'days');

  const [saturationList, setSaturationList] = useState<any[]>([]);
  const [heartRateList, setHeartRateList] = useState<any[]>([]);

  const [ppgList, setPPGList] = useState<any[]>([]);
  const [ppgPageNumber,setPpgPageNumber] = useState<number>(0);
  const [isPPGAvailableMore,setIsPPGAvailableMore] = useState<boolean>(false);

  const [ecgList, setECGList] = useState<any[]>([]);
  const [ecgPageNumber,setEcgPageNumber] = useState<number>(0);
  const [isEcgAvailableMore,setIsEcgAvailable] = useState<boolean>(true);


  const [bpList, setBPList] = useState<any[]>([]);

  const [ecgDataList, setECGDataList] = useState<any[]>([]);

  const getData = useCallback(async (studyId) => {

    const saturationList = await getSaturationList(studyId, moment().add(counter, 'days').format('YYYY-MM-DD'));
    const heartRateList = await getHeartRateList(studyId, currentDate.format('YYYY-MM-DD'));
    const ppg = await getPPGList(studyId, moment().add(counter, 'days').format('YYYY-MM-DD'),ppgPageNumber);
    const ecgList = await getECGList(studyId, currentDate.format('YYYY-MM-DD'),ecgPageNumber);
    const bpList = await getBPList(studyId, currentDate.format('YYYY-MM-DD'));

    setSaturationList(saturationList.data);
    setHeartRateList(heartRateList.data);
    setBPList(bpList.data);

    setPPGList(ppg.data);
    setIsPPGAvailableMore(ppg.data.length!= 0);
    setPpgPageNumber(ppgPageNumber+1);

    setECGList(ecgList.data);
    setIsEcgAvailable(ecgList.data.length!= 0);
    setEcgPageNumber(ecgPageNumber+1);
  }, [counter]);


  const handleGetPPGList = async (studyId : string, ppgPageNumber : number, date: Moment) => {
    let ppgData = await getPPGList(studyId,
        date.format('YYYY-MM-DD'),
        ppgPageNumber);

    setIsPPGAvailableMore(ppgData.data.length!= 0);
    setPpgPageNumber(ppgPageNumber+1);

    let newPPGList = [...ppgList];
    newPPGList.push(...ppgData.data);
    setPPGList(newPPGList);
  }

  const handleGetECGList = async (studyId : string, ecgPageNumber : number, date: Moment) => {
    let ecgData = await getECGList(studyId,
        date.format('YYYY-MM-DD'),
        ppgPageNumber);

    setIsEcgAvailable(ecgData.data.length!= 0);
    setEcgPageNumber(ecgPageNumber+1);

    let newECGList = [...ecgList];
    newECGList.push(...ecgData.data);
    setECGList(newECGList);
  }


  const getPPGDataFromList = async (id: string) => {
    const ppgData = await getPPGData(id);
    return ppgData.data;
  }

  // const getECGDataFromList = async (id: string) => {
  //   const ecgData = await getECGData(id);
  //   let newECGDataList = [...ecgDataList];
  //
  //   if (!newECGDataList.find(item => item.id === id)) {
  //     newECGDataList.push({
  //       ...ecgData.data
  //     })
  //   }
  //
  //   setECGDataList(newECGDataList);
  // }

  useEffect(() => {
    // alert("clear");
    setSaturationList([]);
    setHeartRateList([]);

    setPPGList([]);
    setPpgPageNumber(0);
    setIsPPGAvailableMore(true);

    setECGList([]);
    setEcgPageNumber(0);
    setIsEcgAvailable(true);

    setBPList([]);
    getData(studyId);

  }, [getData, studyId,counter]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        mb={5}
      >
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setCounter(counter - 1);
            setPpgPageNumber(0);
            setEcgPageNumber(0);}
          }
        >
          <ArrowBackIosIcon  />
        </Box>
        <Box>
          {formatDateToShow(currentDate.toString())}
        </Box>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {setCounter(counter + 1);
            setPpgPageNumber(0);
            setEcgPageNumber(0);}}
        >
          <ArrowForwardIosIcon />
        </Box>
      </Box>
      <Typography variant="h5">Rhythm events</Typography>
      <br/>
      <Box my={2} width="100%">
          <MList
              newItems={ppgList}
              newIsAvailableMore={isPPGAvailableMore}
              handleListLoading={() => {handleGetPPGList(studyId, ppgPageNumber, moment().add(counter, 'days'));}}
              handleItemLoading={getPPGDataFromList}
              renderChild={renderAccordionPPG}
              childProps={{"extra":{renderAccordionHeaderPPG,renderAccordionDetailsPPG}}}
          />
      </Box>
      <Typography variant="h5">ECG</Typography><br/>
      <Box width="100%">
        <MList
            newItems={ecgList}
            newIsAvailableMore={isEcgAvailableMore}
            handleListLoading={() => {handleGetECGList(studyId, ecgPageNumber, moment().add(counter, 'days'));}}
            handleItemLoading={() => {}}
            renderChild={renderAccordionECG}
            childProps={{"extra":{renderAccordionHeaderECG}}}
        />
      </Box>
      {/*{*/}
      {/*  !!ecgList.length && (*/}
      {/*    ecgList.map((ecg, key) => (*/}
      {/*      <MAccordion*/}
      {/*        title={`ECG at ${formatTimeToShow(ecg.date)} (open pdf)`}*/}
      {/*        name={`ecg_${key}`}*/}
      {/*        key={key}*/}
      {/*        onExpandCallback={() => getECGDataFromList(ecg.id)}*/}
      {/*      >*/}
      {/*        <>*/}
      {/*          {*/}
      {/*            ecgDataList.find(item => item.id === ecg.id) &&*/}
      {/*              Object.entries(ecgDataList.find(item => item.id === ecg.id)).map(([key,value]) => {*/}
      {/*                return <div key={key}><b>{key}</b> : {(value)}</div>*/}
      {/*              })*/}
      {/*          }*/}
      {/*        </>*/}
      {/*      </MAccordion>*/}
      {/*    ))*/}
      {/*  )*/}
      {/*}*/}

      {
        !!bpList.length && (
          <Box my={2}>
            <Box mb={2}>
              <Typography variant="h5">Blood Pressure</Typography>
            </Box>
            <HighchartsReact
              highcharts={Highcharts}
              options={bloodPressureChart(bpList)}
            />
          </Box>
        )
      }
      {
        !!heartRateList.length && (
          <Box my={2}>
            <Box mb={2}>
              <Typography variant="h5">Heart Rate</Typography>
            </Box>
            <HighchartsReact
              highcharts={Highcharts}
              options={heartRateChart(heartRateList)}
            />
          </Box>
        )
      }
      {
        !!saturationList.length && (
          <Box my={2}>
            <Box mb={2}>
              <Typography variant="h5">Blood Oxygen(SpO2)</Typography>
            </Box>
            <HighchartsReact
              highcharts={Highcharts}
              options={saturationChart(saturationList)}
            />
          </Box>
        )
      }
    </>
  );
};

export default StudySummaryRPM;