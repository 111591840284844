import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import * as React from "react";
import MModal from "./MModal";
import {useState} from "react";
import {Typography} from "@mui/material";
import {formatShortDescription} from "../utils/utils";
import MTextField from "./MTextField";
import {MButton} from "./MButton";
import {addFirmarePackage, deleteFirmwarePackage, updateFirmwarePackage} from "../api/device";
import MTable, { MTableActionsButtons } from './MTable';
import { PermissionsE, usePermissions } from '../context/usePermissions';

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string

}

interface MFirmwarePackagesTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

const defaultRow = {
    description: '',
    firmware_major: 1,
    firmware_minor: 0,
    firmware_build: 0,
    hardware_revision: "1.0"
}

export const MFirmwarePackagesTable = (props: MFirmwarePackagesTableProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["fw_packages.w"]);

    const [busy, setBusy] = useState(false);
    const { headings, rows, sx, onRowClick } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState<any>(defaultRow);
    const [isEditModal,setIsEditModal] = useState<boolean>(false);
    const [selectedPackage,setSelectedPackage] = useState<any>(null);


    const sendDeleteFirmwarePackage = async() => {
        if (!canWrite) return;
        await deleteFirmwarePackage(selectedRow?.id);
        window.location.reload();
    }
    const formatMultipleValuesColumn = (i:number,value:string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "link":
                return <a href={row.url}>Download</a>;
            case "description":
                return <div>
                    {/* Render each line with <br/> after it */}
                    {row.description.split('\n').map((line:string, index:number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </div>
            case "actions":
                return canWrite ? (
                    <MTableActionsButtons 
                        rowData={row}
                        onEdit={() => {
                            setSelectedRow(row);
                            setIsEditModalOpen(true);
                            setIsEditModalOpen(true)
                        }}
                        onDelete={()=>{
                            setSelectedRow(row);
                            setIsDeleteModalOpen(true);
                        }}
                    />
                ) : <></>;
            default:
                return row[heading.field];
        }
    };

    const handleFirmwareChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {

                let encoded = fileReader.result?.toString().replace(/^data:(.*,)?/, '');
                if(encoded){
                    if ((encoded?.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }

            }
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            }
        })
    }

    const handleFileRead = async (event:any) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setSelectedPackage(base64);
    }

    const submitUpdatePackage = async() => {
        if (!canWrite) return;
        let data = {
            description: selectedRow.description,
            firmware_major: selectedRow.firmware_major,
            firmware_minor: selectedRow.firmware_minor,
            firmware_build: selectedRow.firmware_build,
            hardware_revision: selectedRow.hardware_revision,
            package_base64:selectedPackage
        }
        await updateFirmwarePackage(selectedRow.id,data);
        window.location.reload();
    }

    const submitPackage = async () => {
        if (!canWrite) return;
        let data = {
            description: selectedRow.description,
            firmware_major: selectedRow.firmware_major,
            firmware_minor: selectedRow.firmware_minor,
            firmware_build: selectedRow.firmware_build,
            hardware_revision: selectedRow.hardware_revision,
            package_base64:selectedPackage
        }
        await addFirmarePackage(data);
        window.location.reload();
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        if(isEditModal){
            await submitUpdatePackage().finally(() => setTimeout(() => setBusy(false), 500));
        } else {
            await submitPackage().finally(() => setTimeout(() => setBusy(false), 500));
        }
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Firmware package deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{formatShortDescription(selectedRow?.firmware_major,
                        selectedRow?.firmware_minor,
                        selectedRow?.firmware_build,
                        selectedRow?.hardware_revision)}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => {setIsDeleteModalOpen(false)}}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={() => sendDeleteFirmwarePackage()} >Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Firmware package
                    </Typography>

                    <Typography variant="h6">
                        Package short name
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, mt:1}}>
                        {formatShortDescription(selectedRow?.firmware_major,
                            selectedRow?.firmware_minor,
                            selectedRow?.firmware_build,
                            selectedRow?.hardware_revision)}
                    </Typography>
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Major version"
                        value={selectedRow?.firmware_major}
                        onChange={(e) => {handleFirmwareChange("firmware_major",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Minor version"
                        value={selectedRow?.firmware_minor}
                        onChange={(e) => {handleFirmwareChange("firmware_minor",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Build version"
                        value={selectedRow?.firmware_build}
                        onChange={(e) => {handleFirmwareChange("firmware_build",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Hardware revision"
                        value={selectedRow?.hardware_revision}
                        onChange={(e) => {handleFirmwareChange("hardware_revision",e.target.value)}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Description(Put '\n' to add line break)"
                        multiline={true}
                        value={selectedRow?.description}
                        onChange={(e) => {handleFirmwareChange("description", e.target.value)}}
                    />
                    <MTextField
                        id="originalFileName"
                        type="file"
                        inputProps={{ accept:  '.bin' }}
                        required
                        label="Package file"
                        name="originalFileName"
                        size="medium"
                        variant="standard"
                        onChange={e => handleFileRead(e)}
                    />

                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsEditModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler} >Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >

                <Typography variant="h4">
                    Firmware packages
                </Typography>

                {canWrite ? (
                    <MButton variant="contained" color="primary" onClick={() => {
                        setIsEditModal(false);
                        setIsEditModalOpen(true);
                        setSelectedRow(defaultRow)
                    }}>
                        Add firmware package
                    </MButton>
                ) : <></>}
            </Box>
            <MTable
                headings={headings} 
                rows={rows}
                renderCell={renderCell}
            />
        </div>
    );
}

export default MFirmwarePackagesTable;
