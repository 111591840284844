import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { Paper, Accordion, AccordionSummary, AccordionDetails, Grid, alpha, Chip } from "@mui/material";
import { MTextField } from "../components/MTextField";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { adminAddDevice, adminListDevices, NewDeviceTypeOption } from "../api/device";
import { MModal } from "../components/MModal";
import { MButton } from "../components/MButton";
import MRadio from "../components/MRadio";
import { listStudiesAdmin } from "../api/study";
import AdminMeasurementsGrid from "../containers/AdminMeasurementsGrid";
import AdminDevicesGrid from "../containers/AdminDevicesGrid";
import { languages, studiesList, studyStatuses } from "../utils/constants";
import MSelect from "../components/MSelect";
import { getClustersList } from "../api/cluster";
import { getClinicsList } from "../api/clinic";
import MDatePicker from "../components/MDatePicker";
import { formatDateAPI, useDebounce, formatDateToShow } from "../utils/utils";
import { getRegionsList } from "../api/region";
import { usePermissions, PermissionsE } from "../context/usePermissions";

import ExpandMore from "@mui/icons-material/ExpandMore";

const newDeviceTypeOptions = [
    {
        value: "mawi_watch",
        label: "Mawi Watch(RPM)"
    },
    {
        value: "mawi_patch",
        label: "Mawi Patch(Holter)"
    },
    {
        value: "mawi_vital",
        label: "Mawi Vital(RPM)"
    },
    {
        value: "dms",
        label: "DMS Holter"
    },
];


export const AdminSearch = () => {
    const { hasPermission } = usePermissions();
    // Dropdown lists
    const [regions, setRegions] = useState<any>([]);

    const [clusters, setClusters] = useState<any>([]);
    const [clinics, setClinics] = useState<any>([]);


    // Filter options
    const [region, setRegion] = useState<string | undefined>(undefined);
    const [cluster, setCluster] = useState<string | undefined>(undefined);
    const [clinic, setClinic] = useState<string | undefined>(undefined);

    const [searchTerm, setSearhTerm] = useState<string>();
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [studyStatus, setStudyStatus] = useState<string | undefined>(undefined);
    const [studyType, setStudyType] = useState<string | undefined>(undefined);
    const [studyLanguage, setStudyLanguage] = useState<string | undefined>(undefined);

    /* Dates */
    const [dateStartFrom, setDateStartFrom] = useState<Date | null>(null);
    const [dateStartTo, setDateStartTo] = useState<Date | null>(null);

    const [dateFinishFrom, setDateFinishFrom] = useState<Date | null>(null);
    const [dateFinishTo, setDateFinishTo] = useState<Date | null>(null);
    /* end Dates */

    /* Accordion */
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    /* end Accordion */

    const [measurements, setMeasurements] = useState<any[]>([]);
    const [isMeasurementAvailableMore, setIsMeasurementAvailableMore] = useState<boolean>(true);

    const [devices, setDevices] = useState<any[]>([]);
    const [isDevicesAvailableMore, setIsDevicesMore] = useState<boolean>(true);

    const [isDeviceAddOpen, setIsDeviceAddOpen] = useState<boolean>(false);

    const [newDeviceSN, setNewDeviceSN] = useState<string>("");
    const [newDeviceType, setNewDeviceType] = useState<NewDeviceTypeOption>("mawi_patch");

    useEffect(() => {
        fetchMeasurements();
    }, [debouncedSearchTerm, dateFinishFrom, dateFinishTo, dateStartFrom, dateStartTo, region, cluster, clinic, studyStatus, studyType, studyLanguage]);

    useEffect(() => {
        fetchDevices();
    }, [debouncedSearchTerm]);

    const fetchRegionsList = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        const regionsList = await getRegionsList();
        regionsList.sort((a: any, b: any) => b.departments_number - a.departments_number);

        let newList = regionsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setRegions(newList);
    }, [])

    const fetchCompanyList = async (regionId: string) => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        const companyList = await getClustersList(regionId);
        companyList.sort((a: any, b: any) => b.departments_number - a.departments_number);

        let newList = companyList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClusters(newList);
    }

    let fetchDepartmentsList = async (id: string) => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        let data = await getClinicsList(id);
        let newDepartmentsList = data;
        let newList = newDepartmentsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClinics(newList);
        // @ts-ignore
        setClinic(undefined);

    }

    useEffect(() => {
        fetchRegionsList()
    }, [fetchRegionsList]);


    const fetchDevices = async () => {
        if (!debouncedSearchTerm || (debouncedSearchTerm && debouncedSearchTerm.length < 3)) {
            setDevices([]);
            return;
        };
        if (!hasPermission!(PermissionsE["device.r"])) return;
        setIsDevicesMore(false);
        const devices = await adminListDevices(debouncedSearchTerm || '');
        setDevices(devices.data.devices)
    }

    const fetchMeasurements = async () => {
        let filter_values = {
            'search_term': debouncedSearchTerm && debouncedSearchTerm.length > 2 ? debouncedSearchTerm : '',
            'id_region': region,
            'id_company': cluster,
            'id_department': clinic,
            'date_start__from': formatDateAPI(dateStartFrom),
            'date_start__to': formatDateAPI(dateStartTo),
            'date_finished__from': formatDateAPI(dateFinishFrom),
            'date_finished__to': formatDateAPI(dateFinishTo),
            'study_language': studyLanguage,
            'study_type': studyType,
            'status': studyStatus
        }
        setIsMeasurementAvailableMore(false);
        const measurements = await listStudiesAdmin(filter_values)
        setMeasurements(measurements.data)
    }

    const addNewDevice = async () => {
        if (!hasPermission!(PermissionsE["device.w"])) return;
        let data = {
            "serial_number": newDeviceSN,
            "device_type": newDeviceType,
        }
        await adminAddDevice(data)
        setIsDeviceAddOpen(false)
    }

    const modal = (
        <MModal
            open={isDeviceAddOpen}
            handleClose={() => {
                setIsDeviceAddOpen(false)
            }}
            width={480}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{ textAlign: "center", mb: 4 }} paragraph={true}>
                    Add new Device
                </Typography>
                <MTextField
                    sx={{ textAlign: "center", mb: 4 }}
                    label="Device Serial Number"
                    value={newDeviceSN}
                    onChange={(e) => {
                        setNewDeviceSN(e.target.value);
                    }}
                />
                <MRadio
                    label="Device type"
                    value={newDeviceType}
                    options={newDeviceTypeOptions}
                    onChange={(e) => setNewDeviceType(e.target.value as NewDeviceTypeOption)}
                />
                <Box sx={{ textAlign: "center", mt: 4 }}>
                    <MButton color="error" onClick={() => {
                        setIsDeviceAddOpen(false);
                        setNewDeviceSN("")
                    }}>Cancel</MButton>
                    <MButton sx={{ ml: 2 }} onClick={addNewDevice}>Confirm it’s done</MButton>
                </Box>
            </Box>
        </MModal>
    );

    const pageHeaderActions = [
        hasPermission!(PermissionsE["device.w"]) ? {
            onClick: () => setIsDeviceAddOpen(true),
            name: "Add device"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            {modal}
            <PageHeader title="Device/Study Management" actions={pageHeaderActions} />
            <Paper sx={{ p: 2, mb: 2 }}>
                <Box display="flex" alignItems="center" sx={{ px: 2 }}>
                    <Box display="flex" sx={{ '& > div:not(:last-child)': { mr: 1 } }}>
                        {debouncedSearchTerm ? (
                            <Chip
                                label={debouncedSearchTerm}
                                onDelete={() => setSearhTerm('')}
                            />
                        ) : <></>}
                        {region && regions.find((r: any) => r.value === region) ? (
                            <Chip
                                label={regions.find((r: any) => r.value === region)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.45),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.45)
                                    }
                                }}
                                onDelete={() =>{
                                    setRegion(undefined);
                                    setCluster(undefined);
                                    setClinic(undefined);
                                }}
                            />
                        ) : <></>}
                        {cluster && clusters.find((r: any) => r.value === cluster) ? (
                            <Chip
                                label={clusters.find((r: any) => r.value === cluster)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.30),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.30)
                                    }
                                }}
                                onDelete={() => {
                                    setCluster(undefined);
                                    setClinic(undefined);
                                }}
                            />
                        ) : <></>}
                        {clinic && clinics.find((r: any) => r.value === clinic) ? (
                            <Chip
                                label={clinics.find((r: any) => r.value === clinic)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.15),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.15)
                                    }
                                }}
                                onDelete={() => setClinic(undefined)}
                            />
                        ) : <></>}
                        {studyStatus ? (
                            <Chip
                                label={studyStatuses.find(ss => ss.value === studyStatus)?.label}
                                onDelete={() => setStudyStatus('')}
                            />
                        ) : <></>}
                        {studyType ? (
                            <Chip
                                label={studiesList.find(st => st.value === studyType)?.label}
                                onDelete={() => setStudyType('')}
                            />
                        ) : <></>}
                        {studyLanguage ? (
                            <Chip
                                label={languages.find(l => l.value === studyLanguage)?.label}
                                onDelete={() => setStudyLanguage('')}
                            />
                        ) : <></>}
                        {dateStartFrom ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateStartFrom)}
                                    onDelete={() => setDateStartFrom(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateStartTo ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateStartTo)}
                                    onDelete={() => setDateStartTo(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateFinishFrom ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateFinishFrom)}
                                    onDelete={() => setDateFinishFrom(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateFinishTo ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateFinishTo)}
                                    onDelete={() => setDateFinishTo(null)}
                                />
                            </Box>
                        ) : <></>}
                    </Box>
                </Box>
                <AccordionDetails>
                    {hasPermission!(PermissionsE["device.r"]) ? (
                        <Box mb={2}>
                            <MTextField
                                label="Search term"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearhTerm(e.target.value);
                                }}
                                helperText="Device SN / ID Patient / ID Study"
                            />
                        </Box>
                    ) : <></>}
                    {hasPermission!(PermissionsE["study.r"]) ? (
                        <Accordion expanded={expanded === 'units'} onChange={handleChange('units')}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Units filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Region"
                                            value={region}
                                            options={regions}
                                            onChange={(e) => {
                                                setRegion(e.target.value);
                                                fetchCompanyList(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Cluster"
                                            value={cluster}
                                            options={clusters}
                                            onChange={(e) => {
                                                setCluster(e.target.value);
                                                fetchDepartmentsList(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Clinic"
                                            value={clinic}
                                            options={clinics}
                                            onChange={(e) => {
                                                setClinic(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : <></>}
                    {hasPermission!(PermissionsE["device.r"]) ? (
                        <Accordion expanded={expanded === 'study'} onChange={handleChange('study')}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Study filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Study status"
                                            value={studyStatus}
                                            options={studyStatuses}
                                            onChange={(e) => setStudyStatus(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Study type"
                                            value={studyType}
                                            options={studiesList}
                                            onChange={(e) => setStudyType(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MSelect
                                            label="Study language"
                                            value={studyLanguage}
                                            options={languages}
                                            onChange={(e) => setStudyLanguage(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : <></>}
                    {hasPermission!(PermissionsE["study.r"]) ? (
                        <Accordion expanded={expanded === 'dates'} onChange={handleChange('dates')}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Dates filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography>Date start</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDatePicker
                                                    label="Date start (from)"
                                                    value={dateStartFrom}
                                                    onChange={(value) => setDateStartFrom(value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDatePicker
                                                    label="Date start (to)"
                                                    value={dateStartTo}
                                                    onChange={(value) => setDateStartTo(value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography>Date finished</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDatePicker
                                                    label="Date finish (from)"
                                                    value={dateFinishFrom}
                                                    onChange={(value) => setDateFinishFrom(value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDatePicker
                                                    label="Date finish (to)"
                                                    value={dateFinishTo}
                                                    onChange={(value) => setDateFinishTo(value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : <></>}
                </AccordionDetails>
            </Paper>
            <Paper sx={{ minHeight: '70vh', px: 4, py: 2 }}>
                {hasPermission!(PermissionsE["study.r"]) ? (
                    <Box my={2}>
                        <Box mb={2}>
                            <Typography variant="h6">Studies matching search criteria</Typography>
                        </Box>
                        <AdminMeasurementsGrid
                            data={measurements}
                        />
                    </Box>
                ) : <></>}
                {hasPermission!(PermissionsE["device.r"]) ? (
                    <Box my={2}>
                        <Box mb={4} mt={4}>
                            <Typography variant="h6">Devices matching "{searchTerm}"</Typography>
                        </Box>
                        <AdminDevicesGrid
                            data={devices}
                        />
                    </Box>
                ) : <></>}
            </Paper >
        </>
    );
};

export default AdminSearch;