import { Box, FormLabel, IconButton } from '@mui/material';
import { MTextField, MTextFieldProps } from './MTextField';
import { MButton } from './MButton';
import { useRef, useState } from 'react';
import Close from "@mui/icons-material/Close";
import { v4 } from 'uuid';

export interface MFileUpload extends MTextFieldProps {
  onFileChangeHandler?: (file?: File) => void;
}

export const MFileUpload = (props: MFileUpload) => {
  const { onFileChangeHandler, id = 'fileUpload', size = "small", label = 'Choose a file', InputProps, variant = 'outlined' } = props;

  const [key, setKey] = useState(v4());
  const [file, setFile] = useState<File | undefined>(undefined);

  const inputRef = useRef<any>(null);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const reset = () => {
    setKey(v4());
    inputRef.current.value = '';
    setFile(undefined);
    onFileChangeHandler?.();
  };

  return (
    <Box key={key}>
      <FormLabel
        htmlFor={id}
        sx={{
          '& fieldset': {
            border: 'none',
            padding: 0
          },
        }}
      >
        <MTextField
          {...props}
          id={id}
          size={size}
          fullWidth
          label=""
          type="file"
          InputProps={{ ...InputProps, ref: inputRef }}
          sx={{
            padding: 0,
            display: 'none'
          }}
          onChange={(e: any) => {
            const files = e.target.files;
            const file = files && files[0];
            setFile(file);
            onFileChangeHandler?.(file);
          }}
        />
      </FormLabel>
      <Box display="flex" alignItems="center">
        <Box>
          <MButton
            variant="outlined"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onButtonClick();
            }}
          >
            {label}
          </MButton>
        </Box>
        {
          file?.name ? (
            <Box mx={2} sx={{ borderBottom: '1px solid #1D80E8' }}>
              {file?.name}
            </Box>
          ) : <></>
        }
        {
          file && (
            <Box>
              <IconButton onClick={reset}><Close /></IconButton>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

export default MFileUpload;