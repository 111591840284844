import InputLabel from '@mui/material/InputLabel';
import { DateTimePicker } from "@mui/x-date-pickers";
import { pickersMigrationHack } from '../utils/utils';

interface MDateTimePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  variant? : "outlined" | "filled" |  "standard";
}

export const MDateTimePicker = (props: MDateTimePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText,variant="outlined" } = props;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <DateTimePicker
          value={value ? new Date(value) : null}
          closeOnSelect={true}
          views={['year', 'day', 'hours', 'minutes']}
          onChange={onChange}
          disabled={disabled}
          sx={{
            width: "100%",
            ...pickersMigrationHack(error)
          }}
        />
    </>
  );
}

export default MDateTimePicker;
