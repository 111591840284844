import { StudyStatusesEnum } from "../api/study"

export const studiesList = [
    {
        value: "holter",
        label: "Holter"
    },
    {
        value: "two_week_holter",
        label: "Multi-Holter"
    },
    {
        value: "rpm",
        label: "Mawi Watch"
    }
]

export const studyStatuses = [
    {
        value: StudyStatusesEnum.in_review,
        label: "In review"
    },
    {
        value: StudyStatusesEnum.inprogress,
        label: "In progress"
    },
    {
        value: StudyStatusesEnum.interpretating,
        label: "Interpreting"
    },
    {
        value: StudyStatusesEnum.completed,
        label: "Completed"
    }
]

export const allStudyStatuses = [
    {
        value: StudyStatusesEnum.draft,
        label: "Draft"
    },
    ...studyStatuses
]

export const languages = [
    {
        value: "EN",
        label: "English(EN)"
    },
    {
        value: "UK",
        label:"Ukrainian(UK)"
    }
]

export const analysisPlatforms = [
    {
        value: 'amps',
        label: 'AMPS',
    },
    {
        value: 'amps_cers',
        label: 'CER-SCor',
    }
]

export const featureFlagsList = [
    {
        value: 'raw_data_view',
        label: 'Raw data view - Clinicans access to review raw data(CER-SCor)',
    }
]

export const NO_PERMISSIONS_MESSAGE = "You haven't permissions to see this page";