export enum AccountRolesEnum {
    'Super Admin' = 'super_admin',
    'IT Admin' = 'it_admin',
    'Account Manager' = 'account_manager',
    // Technicians
    'Lead Technician' = 'lead_technician',
    'Senior Technician' = 'senior_technician',
    'Technician' = 'technician',
    // Units 
    'Unit Owner' = 'unit_owner',
    'Clinician' = 'clinician',
}

export interface AccountRole {
    label: string;
    value: AccountRolesEnum;
}

export const allAccountRoles: AccountRole[] = Object.entries(AccountRolesEnum).map(i => ({
    label: i[0],
    value: i[1]
}));

export const generalAccountRoles: AccountRole[] = allAccountRoles.filter(i => 
    i.value !== AccountRolesEnum["Clinician"]
)

export const clinicianRoles: AccountRole[] = [allAccountRoles.find(ar => ar.value === AccountRolesEnum.Clinician) as AccountRole];
export const unitOwnerRoles: AccountRole[] = [allAccountRoles.find(ar => ar.value === AccountRolesEnum["Unit Owner"]) as AccountRole];
// export const clusterRoles: AccountRole[] = [allAccountRoles.find(ar => ar.value === AccountRolesEnum["Cluster Owner"]) as AccountRole];
// export const regionRoles: AccountRole[] = [allAccountRoles.find(ar => ar.value === AccountRolesEnum["Regional Owner"]) as AccountRole];

export const connectedUnitsRoles: AccountRole[] = generalAccountRoles.filter(i => 
    i.value !== AccountRolesEnum["Super Admin"] && 
    i.value !== AccountRolesEnum["IT Admin"]
)
