import {useEffect, useState, useCallback, ChangeEventHandler, useRef} from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {MTextField} from "../components/MTextField";
import {MAutocomplete} from "../components/MAutocomplete";
import {MSelect} from "../components/MSelect";
import {MDateTimePicker} from "../components/MDateTimePicker";
import {MDateAndTimePicker} from "../components/MDateAndTimePicker";
import {MDatePicker} from "../components/MDatePicker";
import {MButton} from "../components/MButton";
import {MRadioPlates} from "../components/MRadioPlates";
import {MRadio} from "../components/MRadio";
import FormSection from "./FormSection";
import {Gender} from "../api/patient";
import {fetchICD10Codes} from "../api/clinicalReasons";
import {BillingMethod} from "../api/insuranceInforamation";
import {ICD10Option} from "../api/clinicalReasons";
import {StudyType, Enrollment, createCalibrationObject, createCalibrationObjectCheckCast} from "../api/study";
import {baseUrls} from "../routes";
import {validateDate, validateRequiredField, validateDeviceSerialNumber} from "../utils/validator";
import {checkIsDeviceExist, checkIsDevicesExist, PPGDataFrequency} from "../api/device";
import {isHolter, isMCT, isRPM, isTwoWeekHolter} from "../utils/utils";
import {MModal} from "../components/MModal";
import Typography from "@mui/material/Typography";
import {getValueFromLocalStorage, getValueFromSessionStorage, removeValueFromSessionStorage} from "../api/auth";
import MTimePicker from "../components/MTimePicker";
import MCheckbox from "../components/MCheckbox";

const MAX_DEVICE_COUNT = 3;
const MAX_ECG_REMINDERS = 5;

interface StudyFormProps {
    values?: any;
    edit?: boolean;
    draftStudyHandler?: (values: any) => void;
    createStudyHandler?: (values: any) => void;
    deleteStudyHandler?: (values: any) => void;
}

const genderOptions = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
];

const PPGDataCollectionOptions = [
    {
        label: "Every 30 minutes",
        value: "*/30 * * * *"
    },
    {
        label: "Every hour",
        value: "0 * * * *"
    }
];


const billingMethodOptions = [
    {
        value: "medicare",
        label: "Medicare"
    },
    {
        value: "private_insurance",
        label: "Private insurance"
    },
    {
        value: "self_pay",
        label: "Self pay"
    }
];

const yesNoOptions = [
    {
        value: "yes",
        label: "Yes"
    },
    {
        value: "no",
        label: "No"
    }
];

const enrollmentOptions = [
    {
        value: "clinic",
        label: "In-clinic"
    },
    {
        value: "home",
        label: "At home"
    }
];

const studies = [
    {
        value: "holter",
        label: "Holter",
        // subLabel: "3-7 days long"
    },
    {
        value: "two_week_holter",
        label: "Multi-Holter",
        // subLabel: "7-14 days long"
    },

    {
        value: "mct",
        label: "MCT",
        subLabel: "Up to 30 days long, realtime"
    },
    {
        value: "rpm",
        label: "RPM",
        subLabel: "Continuous BP and Afib"
    },
]

interface Device {
    value: string;
    deviceType?: string;
    error?: string;
}

export const StudyForm = ({
                              values,
                              edit,
                              draftStudyHandler,
                              createStudyHandler,
                              deleteStudyHandler
                          }: StudyFormProps) => {
    const navigate = useNavigate();

    const [allowedStudies,setAllowedStudies] = useState<string[]>([]);
    const [busy, setBusy] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    //const handleClose = () => setOpen(false);
    const [rpmStep, setRPMStep] = useState(1);

    const [firstName, setFirstName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [idUnique, setIdUnique] = useState<string>("");

    const [lastNameError, setLastNameError] = useState<string>("");
    const [gender, setGender] = useState<Gender>("male");
    const [birthday, setBirthday] = useState<Date | null>(null);
    const [birthdayError, setBirthdayError] = useState<string>("");

    const [billingMethod, setBillingMethod] = useState<BillingMethod>("self_pay");

    const [primaryIndication, setPrimaryIndication] = useState<ICD10Option | undefined>();
    const [reasonForTest, setReasonForTest] = useState<string>("");
    const [medications, setMedications] = useState<string>("");
    const [hadStroke, setHadStroke] = useState<string>("no");
    const [hasPacemaker, setHasPacemaker] = useState<string>("no");

    const [studyType, setStudyType] = useState<StudyType>("holter");
    const [enrollment, setEnrollment] = useState<Enrollment>("clinic");

    // timeout for checkCase in seconds
    const rpmCheckCastRequestTimeout = 5;
    // RPM create calibraiton object
    const rpmCheckCastMaxAttemptsCount = 40;
    const [checkCastAttempts, setCheckCastAttempts] = useState(rpmCheckCastMaxAttemptsCount);


    const deviceInitialValue = {value: ""};
    const [devices, setDevices] = useState<Device[]>([deviceInitialValue]);
    const [startDates, setStartDates] = useState<{ value: Date | null, error?: string }[]>([{value: null},{value: null}]);

    const [passivePPGDataCollectionFrequency, setPassivePPGDataCollectionFrequency] = useState<PPGDataFrequency>("*/30 * * * *");

    const ecgReminder = {value: new Date(new Date().setHours(10,0))}
    const [ECGDataCollectionFrequency, setECGDataCollectionFrequency] = useState<{value: Date}[]>([]);

    const [wifiLogin, setWifiLogin] = useState<String>("")
    const [wifiPassword, setWifiPassword] = useState<String>("")

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [startDateError, setStartDateError] = useState<string>("");

    const [systolic, setSystolic] = useState<string>("");
    const [systolicError, setSystolicError] = useState<string>("");
    const [diastolic, setDiastolic] = useState<string>("");
    const [diastolicError, setDiastolicError] = useState<string>("");
    const [heartRate, setHeartRate] = useState<string>("");
    const [heartRateError, setHeartRateError] = useState<string>("");

    const [finalLink, setFinalLink] = useState<string>("");

    const [ICD10Codes, setICD10Codes] = useState<ICD10Option[]>([]);

    const getICDCodes = useCallback(async (code: string) => {
        await onAutoCompleteType(code);
    }, []);

    useEffect(() => {
        return () => {
            removeValueFromSessionStorage('study');
        }
    }, []);

    useEffect(() => {
        getAllowedStudies();

        if (values) {
            values.primary_indication && getICDCodes(values.primary_indication);
            setFirstName(values.patient.first_name);
            setIdUnique(values.patient.id_unique);
            setLastName(values.patient.last_name);
            setGender(values.patient.gender);
            setBirthday(values.patient.birthday);

            setBillingMethod(values.billing_method || "");

            setReasonForTest(values.reason_for_test || "");

            setMedications(values.medications || "");
            setHasPacemaker(values.has_pacemaker ? "yes" : "no");
            setHadStroke(values.had_stroke ? "yes" : "no");

            setStudyType(values.study_type);
            setEnrollment(values.enrollment);
            setStartDate(values.date_start);
            setPassivePPGDataCollectionFrequency(values.passivePPGDataCollectionFrequency || "");
            setECGDataCollectionFrequency(values?.ECGDataCollectionFrequency || []);

            // TODO: add WiFI options
        }

    }, [values]);

    useEffect(() => {
        if (isTwoWeekHolter(studyType)) {
            setDevices([deviceInitialValue, deviceInitialValue]);
        } else {
            setDevices([deviceInitialValue]);
        }
    }, [studyType]);

    useEffect(() => {
        if (values && ICD10Codes.length) {
            setPrimaryIndication(ICD10Codes.find(item => item.value === values.primary_indication));
        }
    }, [values, ICD10Codes]);


    function customCompare(value:any){
        return allowedStudies.includes(value.value);
    }
    const studyTypeOptions = () => {
        return studies.filter(function(value) {
            return customCompare(value);
        });
    }

    const onAutoCompleteType = async (value: string) => {
        const codes = await fetchICD10Codes(value) || [];
        setICD10Codes(codes);
    };

    const getAllowedStudies = async () => {
        let profile = await getValueFromLocalStorage('staff_profile');
        setAllowedStudies(profile.allowed_studies);

    }

    const prepareStudyValues = async (skipValidation = false) => {
        const { data } = await checkIsDevicesExist({serial_numbers: devices.map(i => i.value)});

        const formValues = {
            study_id: values?.study_id || undefined,
            firstName,
            lastName,
            gender,
            id_unique: idUnique,
            birthday,
            /**/
            billingMethod,
            /**/
            primaryIndication: primaryIndication?.value,
            reasonForTest,
            medications,
            hadStroke,
            hasPacemaker,
            /**/
            studyType,
            enrollment,
            /**/
            startDate: startDate || new Date().toISOString(),
            startDates: startDates.map(d => d.value),
            /**/
            devices: [...devices].map((d) => {
                const currentDevice = data.devices.find((i: any) => i.serial_number === d.value);
                return {
                    ...d,
                    deviceType: currentDevice?.device_type,
                }
            }),
            // deviceSerialNumber: (isMCT(studyType) || isTwoWeekHolter(studyType)) ? deviceSerialNumbers.map(d => d.value) : deviceSerialNumber,
            ppg_frequency: isRPM(studyType) ? passivePPGDataCollectionFrequency : null,
            ecg_frequency: isRPM(studyType) ? ECGDataCollectionFrequency : null,
            wifi_login: wifiLogin,
            wifi_password: wifiPassword
        };

        if (!skipValidation) {
            const firstNameMessage = validateRequiredField("First name", firstName);
            const lastNameMessage = validateRequiredField("Last name", lastName);
            const birthdayMessage = validateDate("Birthday", birthday);
            const startDateMessage = isHolter(studyType) ? validateDate("Start date", startDate) : "";
            let isStartDatesError = (isMCT(studyType) || isTwoWeekHolter(studyType)) ? [...startDates].filter(i => i.value).length !== startDates.length : false;
            let isDevicesError = [...devices].filter(i => i.value).length !== devices.length;
        
            let currentBEDevices: any[] = data.devices;
            let updatedDevices: any[] = []
            let isDeviceConnectableOrExists: any[] = []

            if (!isDevicesError && [...devices].filter(d => d.value).length) {
                isDeviceConnectableOrExists = [...devices].map(d => {
                    const currentDevice = currentBEDevices.find(i => i.serial_number === d.value);
                    return validateDeviceSerialNumber(currentDevice.is_exists, currentDevice.is_connectable);
                });
            }

            const isDevicesBEError = isDeviceConnectableOrExists.some(i => i.length);

            if (firstNameMessage || lastNameMessage || birthdayMessage || startDateMessage || isDevicesError || isDevicesBEError) {
                setFirstNameError(firstNameMessage);
                setLastNameError(lastNameMessage);
                setBirthdayError(birthdayMessage);
                setStartDateError(startDateMessage);

                if (isTwoWeekHolter(studyType) && isStartDatesError) {
                    const newStartDates = [...startDates].map(i => {
                        if (!i.value) {
                            return {
                                ...i,
                                error: validateRequiredField("Start Date", i.value)
                            };
                        }

                        return i;
                    });

                    setStartDates(newStartDates);
                }

                if (isDevicesError) {
                    updatedDevices = [...devices].map(i => {
                        if (!i.value) {
                            return {
                                ...i,
                                error: validateRequiredField("Device SN", i.value)
                            };
                        }

                        return i;
                    });
                } 
                
                if (isDevicesBEError){
                    updatedDevices = [...devices].map((d) => {
                        const currentDevice = currentBEDevices.find(i => i.serial_number === d.value);
                        return {
                            ...d,
                            error: validateDeviceSerialNumber(currentDevice.is_exists, currentDevice.is_connectable)
                        }
                    });
                }

                setDevices(updatedDevices);

                return;
            }
        }

        return formValues;
    };

    const prepareCalibrationObjectValues = async () => {
        const systolicMessage = validateRequiredField("Systolic Blood Pressure", systolic);
        const diastolicMessage = validateRequiredField("Diastolic Blood Pressure", diastolic);
        const heartRateMessage = validateRequiredField("Heart Rate", heartRate);

        if (systolicMessage || diastolicMessage || heartRateMessage) {
            setSystolicError(systolicMessage);
            setDiastolicError(diastolicMessage);
            setHeartRateError(heartRateMessage);
            return;
        }

        return {
            systolic: +systolic,
            diastolic: +diastolic,
            heartRate: +heartRate
        }
    }

    const handleCalibrationProcess = async () => {
        const values = await prepareCalibrationObjectValues();
        const studyCreated = await getValueFromSessionStorage('study');

        if (values) {
            const calibrationObject = await createCalibrationObject({
                ...values,
                studyId: studyCreated.id
            });

            if (calibrationObject.message === '200_1') {
                setRPMStep(5);
                setFinalLink(`${baseUrls.study}/${studyCreated.id}`);
                await checkCast();
            }
        }
    }

    const checkCast = async() => {
        const studyCreated = await getValueFromSessionStorage('study');

        const calibrationObjectCheckCast = await createCalibrationObjectCheckCast(studyCreated.id);

        if (calibrationObjectCheckCast.data.has_human_cast) {
            setTimeout(() => setRPMStep(6), 5000);
        } else {
            if (checkCastAttempts === 0){
                setRPMStep(7);
            } else {
                setTimeout(async () => {
                    setCheckCastAttempts((currentCount) => currentCount - 1);
                    await checkCast();
                }, rpmCheckCastRequestTimeout * 1000);
            }
        }
    }

    const handleDraftStudyCreate = async () => {
        const values = await prepareStudyValues(true);
        draftStudyHandler && draftStudyHandler(values);
    };

    const handleStudyCreate = async () => {
        const values = await prepareStudyValues();
        const studyCreated = await getValueFromSessionStorage('study');

        setBusy(true);

        if (values && createStudyHandler) {
            if (isRPM(studyType)) {
                if (!studyCreated) {
                    await createStudyHandler(values);
                }
                handleOpen();
                return;
            } else {
                await createStudyHandler(values);
                return;
            }
        }
        setTimeout(() => setBusy(false), 500);
    };

    const handleStudyDelete = async () => {
        deleteStudyHandler && deleteStudyHandler(values.study_id);
    };

    const  handleEnableReminders= async (checked: any) => {
        if(checked){
            setECGDataCollectionFrequency([ecgReminder]);
        } else{
            setECGDataCollectionFrequency([]);
        }
    };

    const onDevicesCountChange = (key: number, isIncrease?: boolean) => {
        if (isIncrease) {
            const newDevices = [...devices, deviceInitialValue];
            setDevices(newDevices);
    
            const newStartDates = [...startDates, {value: null}];
            setStartDates(newStartDates);
        } else {
            delete devices[key];
            delete startDates[key];

            const newDevices = [...devices].filter(i => !!i);
            const newStartDates = [...startDates].filter(i => !!i);
            setDevices(newDevices);
            setStartDates(newStartDates);
        }
    }

    const handleDeviceChange = (value: string, key: number) => {
        const updatedDevices: Device[] = [...devices].map((d, k) => {
            if (k !== key) {
                return d as Device;
            } else {
                return {
                    ...d,
                    value,
                    error: undefined
                }
            }
        })

        setDevices(updatedDevices);
    }

    const renderDeviceField = (value: string, key: number, error?: string, label = 'Device serial number') => {
        return (
            <MTextField
                label={label}
                disabled={edit}
                value={value}
                error={!!error}
                helperText={error}
                onChange={e => handleDeviceChange(e.target.value.toUpperCase(), key)}
                placeholder="XXXXX"
            />
        )
    }

    const renderRPMContent = () => {
        switch (rpmStep) {
            case 1:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}} paragraph={true}>
                            Sync the settings on the watch
                        </Typography>
                        <Typography variant="body1" sx={{mb: 15}}>
                            Please press the left and upper right buttons simultaneously for ten seconds.
                            This will personalize the device for <b>{firstName} {lastName}</b>.
                        </Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => setRPMStep(2)}>Confirm it’s done</MButton>
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            Settings uploaded
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "left", mb: 15}}>
                            Settings update is complete.
                            The watch is now personalised for <b>{firstName} {lastName}</b>
                        </Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => setRPMStep(3)}>Continue</MButton>
                        </Box>
                    </Box>
                );
            case 3:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            Calibrate the device
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "left", mb: 15}}>
                            Instructions: <br/> <br/>
                            1) Put the device on the patient's wrist and the BP cuff on the opposite arm.<br/><br/>
                            2) Ask the patient to sit calm and comfortable<br/><br/>
                            3) Simultaneously start the device measurement by pressing the upper
                            right button and the cuff measurement<br/>
                        </Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => setRPMStep(4)}>Confirm it’s done</MButton>
                        </Box>
                    </Box>
                );
            case 4:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            Please enter the calibration values from the cuff
                        </Typography>
                        <Box mb={1}>
                            <MTextField
                                label="Systolic Blood Pressure"
                                disabled={edit}
                                value={systolic}
                                error={!!systolicError}
                                helperText={systolicError}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");

                                    if (systolicError) {
                                        setSystolicError("");
                                    }

                                    setSystolic(value);
                                }}
                            />
                        </Box>
                        <Box mb={1}>
                            <MTextField
                                label="Diastolic Blood Pressure"
                                disabled={edit}
                                value={diastolic}
                                error={!!diastolicError}
                                helperText={diastolicError}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");

                                    if (diastolicError) {
                                        setDiastolicError("");
                                    }
                                    setDiastolic(value);
                                }}
                            />
                        </Box>
                        <Box mb={5}>
                            <MTextField
                                label="Heart Rate"
                                disabled={edit}
                                value={heartRate}
                                error={!!heartRateError}
                                helperText={heartRateError}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");

                                    if (heartRateError) {
                                        setHeartRateError("");
                                    }
                                    setHeartRate(value);
                                }}
                            />
                        </Box>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={handleCalibrationProcess}>Submit</MButton>
                        </Box>
                    </Box>
                );
            case 5:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            The device is being calibrated
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "left", mb: 15}}>Waiting for data from a
                            device...</Typography>
                    </Box>
                );
            case 6:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            Complete!
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "left", mb: 15}}>Now it’s all ready. You can
                            let <b>{firstName} {lastName}</b> go, but monitor his vitals remotely</Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => navigate(finalLink)}>Done</MButton>
                        </Box>
                    </Box>
                );
            case 7:
                return (
                    <Box p={3}>
                        <Typography variant="h4" sx={{textAlign: "center", mb: 10}}>
                            Calibration in progress
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: "left", mb: 15}}>
                            Device is still being calibrated. It might take a couple of minutes.
                        </Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => setRPMStep(5)}>Check calibration status</MButton>
                        </Box>
                    </Box>
                );
            default:
                return (
                    <Box p={3}>
                        <Typography variant="h3" sx={{textAlign: "center", mb: 10}}>
                            Now upload the <br/> settings to the watch
                        </Typography>
                        <Typography variant="h5" sx={{mb: 15}}>
                            Instructions: <br/>
                            Please push two buttons on the side of device and study settings will be uploaded to the
                            watch and the
                            device will be personalised for {firstName} {lastName}
                        </Typography>
                        <Box sx={{textAlign: "center"}}>
                            <MButton onClick={() => setRPMStep(2)}>Confirm it’s done</MButton>
                        </Box>
                    </Box>
                );
        }
    };

    return (
        <>
            <MModal
                open={open}
                // handleClose={handleClose}
                width={480}
                height="auto"
            >
                {renderRPMContent()}
            </MModal>
            <Box
                sx={{maxWidth: 750, margin: "0 auto"}}
            >
                <FormSection
                    sectionName="Patient details"
                >
                    <>
                        <MTextField
                            label="First name"
                            disabled={edit}
                            value={firstName}
                            error={!!firstNameError}
                            helperText={firstNameError}
                            onChange={(e) => {
                                if (firstNameError) {
                                    setFirstNameError("");
                                }
                                setFirstName(e.target.value);
                            }}
                        />
                        <MTextField
                            label="Last name"
                            disabled={edit}
                            value={lastName}
                            error={!!lastNameError}
                            helperText={lastNameError}
                            onChange={(e) => {
                                if (lastNameError) {
                                    setLastNameError("");
                                }
                                setLastName(e.target.value);
                            }}
                        />
                        <MTextField
                            label="Patient ID(optional)"
                            InputProps={{
                                inputProps: { maxLength: 45 }
                            }}
                            disabled={edit}
                            value={idUnique}
                            multiline={true}
                            onChange={(e) => {
                                setIdUnique(e.target.value);
                            }}
                        />
                        <MSelect
                            label="Sex"
                            value={gender}
                            disabled={edit}
                            options={genderOptions}
                            onChange={(e) => setGender(e.target.value as Gender)}
                        />
                        <MDatePicker
                            label="Date of Birth"
                            disabled={edit}
                            value={birthday}
                            error={!!birthdayError}
                            helperText={birthdayError}
                            onChange={(value) => {
                                if (birthdayError) {
                                    setBirthdayError("");
                                }
                                setBirthday(value);
                            }}
                        />
                    </>
                </FormSection>
                <FormSection
                    sectionName="Insurance"
                >
                    <>
                        <MSelect
                            label="Billing method"
                            value={billingMethod}
                            disabled={edit}
                            options={billingMethodOptions}
                            onChange={(e) => setBillingMethod(e.target.value as BillingMethod)}
                        />
                    </>
                </FormSection>
                <FormSection
                    sectionName="Clinical reasons"
                >
                    <>
                        <MAutocomplete
                            label="Primary Indication"
                            disabled={edit}
                            options={ICD10Codes}
                            onType={(value) => onAutoCompleteType(value)}
                            value={primaryIndication}
                            onInputChange={(value: ICD10Option | undefined) => setPrimaryIndication(value)}
                        />
                        <MTextField
                            label="Reason for test"
                            disabled={edit}
                            value={reasonForTest}
                            onChange={(e) => setReasonForTest(e.target.value)}
                        />
                        <MTextField
                            label="Medications"
                            disabled={edit}
                            value={medications}
                            onChange={(e) => setMedications(e.target.value)}
                        />
                        <MSelect
                            label="Does patient have a pacemaker?"
                            value={hasPacemaker}
                            disabled={edit}
                            options={yesNoOptions}
                            onChange={(e) => setHasPacemaker(e.target.value)}
                        />
                        <MSelect
                            label="Has patient had a stroke?"
                            value={hadStroke}
                            disabled={edit}
                            options={yesNoOptions}
                            onChange={(e) => setHadStroke(e.target.value)}
                        />
                    </>
                </FormSection>
                <FormSection
                    sectionName="Recording type"
                >
                    <>
                        <MRadioPlates
                            label="Choose a recording type you want to perform"
                            value={studyType}
                            disabled={edit}
                            options={studyTypeOptions()}
                            onChange={(val) => setStudyType(val as StudyType)}
                            isSmall
                        />
                        <MRadio
                            label="Enrollment"
                            options={enrollmentOptions}
                            disabled={edit}
                            value={enrollment}
                            onChange={(e, val) => setEnrollment(val as Enrollment)}
                        />
                    </>
                </FormSection>
                <FormSection
                    sectionName="Device details"
                    divider={isRPM(studyType)}
                >
                    {
                        (isMCT(studyType) || isTwoWeekHolter(studyType))  ? (
                            <>
                                {
                                    devices.map((device, key) => (
                                        <Box mb={2} key={key}>
                                            {renderDeviceField(device.value, key, device?.error, `Device serial number ${key + 1}`)}
                                            {isTwoWeekHolter(studyType) && <>
                                                    <MDateAndTimePicker 
                                                        dateLabel={`Test start date (${key + 1} Patch)`}
                                                        timeLabel={`Test start time (${key + 1} Patch)`}
                                                        disabled={edit}
                                                        value={startDates[key].value}
                                                        error={!!startDates[key].error}
                                                        dateErrorText={`Start date (${key + 1} Patch) is required field`}
                                                        timeErrorText={`Start time (${key + 1} Patch) is required field`}
                                                        onChange={(value) => {
                                                            const newStartDates = [...startDates];
                                                            newStartDates[key].value = value;
                                                            if (newStartDates[key].error) {
                                                                newStartDates[key].error = undefined;
                                                            }
                                                            setStartDates(newStartDates);
                                                        }}
                                                    />
                                                </>
                                            }
                                            {
                                                !edit && devices.length - 1 === key && devices.length !== MAX_DEVICE_COUNT && (
                                                    <IconButton
                                                        sx={{marginLeft: 1, verticalAlign: 'bottom'}}
                                                        onClick={() => onDevicesCountChange(key, true)}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                )
                                            }
                                            {
                                                !edit && !!key && (
                                                    <IconButton
                                                        sx={{marginLeft: 1, verticalAlign: 'bottom'}}
                                                        onClick={() => onDevicesCountChange(key)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                )
                                            }

                                        </Box>
                                    ))
                                }
                            </>
                        ) : isRPM(studyType) ? (
                            <>
                                {renderDeviceField(devices[0].value, 0, devices[0]?.error)}
                                <MTextField
                                    label="Patient WiFi network login"
                                    disabled={edit}
                                    value={wifiLogin}
                                    onChange={(e) => setWifiLogin(e.target.value)}
                                    placeholder="xxxxxxx"
                                />
                                <MTextField
                                    label="Patient WiFi network password"
                                    disabled={edit}
                                    value={wifiPassword}
                                    onChange={(e) => setWifiPassword(e.target.value)}
                                    placeholder="xxxxxxx"
                                />

                            </>
                        ) : (
                            <>
                                {renderDeviceField(devices[0].value, 0, devices[0]?.error)}
                                <MDateAndTimePicker 
                                    dateLabel="Test start date"
                                    timeLabel="Test start time"
                                    disabled={edit}
                                    value={startDate}
                                    error={!!startDateError}
                                    dateErrorText="Start date is required field"
                                    timeErrorText="Start time is required field"
                                    onChange={(value) => {
                                        if (startDateError) {
                                            setStartDateError("");
                                        }
                                        setStartDate(value);
                                    }}
                                />  
                            </>
                        )
                    }
                </FormSection>
                {
                    isRPM(studyType) ? (
                        <FormSection
                            sectionName="Device schedule"
                            divider={false}
                        >
                            <>
                                <MSelect
                                    label="Passive PPG data collection frequency"
                                    value={passivePPGDataCollectionFrequency}
                                    disabled={edit}
                                    options={PPGDataCollectionOptions}
                                    onChange={(e) => setPassivePPGDataCollectionFrequency(e.target.value as PPGDataFrequency)}
                                    placeholder="Every 10 minutes"
                                />
                                <MCheckbox
                                    label="Enable ECG measurement reminders"
                                    disabled={edit}
                                    checked={!!ECGDataCollectionFrequency.length}
                                    onChange={(e,checked) => handleEnableReminders(checked)}
                                />
                                {
                                    ECGDataCollectionFrequency.map((schedule, key) => (
                                        <Box mb={2} key={key}>
                                            <MTimePicker
                                                label={`ECG Reminder ${key + 1}`}
                                                disabled={edit}
                                                value={schedule.value}
                                                onChange={(rem) => {
                                                    const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency];

                                                    if (rem) newECGDataCollectionFrequency[key].value = rem;

                                                    // if (schedule.error) {
                                                    //     newECGDataCollectionFrequency[key].error = undefined;
                                                    // }

                                                    setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                                }}
                                                //placeholder="12:00"
                                            />
                                            {
                                                !edit && ECGDataCollectionFrequency.length - 1 === key && ECGDataCollectionFrequency.length !== MAX_ECG_REMINDERS && (
                                                    <IconButton
                                                        sx={{marginLeft: 1}}
                                                        onClick={() => {
                                                            const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency, ecgReminder];

                                                            setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                                        }}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                )
                                            }
                                            {
                                                !edit && !!key && (
                                                    <IconButton
                                                        sx={{marginLeft: 1}}
                                                        onClick={() => {
                                                            delete ECGDataCollectionFrequency[key];
                                                            const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency].filter(i => !!i);
                                                            setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                )
                                            }

                                        </Box>
                                    ))
                                }
                            </>
                        </FormSection>

                    ) : null}
            </Box>
            {
                !edit && (
                    <>
                        <Divider/>

                        <Box
                            py={3.5}
                            textAlign="center"
                            sx={{
                                "& .MuiButton-root": {marginRight: 3}
                            }}
                        >
                            <MButton disabled={busy} onClick={() => navigate(baseUrls.studies)} variant="text">Cancel</MButton>
                            {/*{deleteStudyHandler &&*/}
                            {/*<MButton variant="outlined" color="error" onClick={handleStudyDelete}>Delete*/}
                            {/*    draft</MButton>}*/}
                            {/*<MButton variant="outlined" onClick={handleDraftStudyCreate}>Save as draft</MButton>*/}
                            <MButton busy={busy} onClick={handleStudyCreate}>Create study</MButton>
                        </Box>
                    </>
                )
            }

        </>
    );
};

export default StudyForm;