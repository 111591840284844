import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from "@mui/material/Typography";

type TextFieldOmittedFields = 'variant' | 'label';

export interface MTextFieldProps extends Omit<OutlinedTextFieldProps, TextFieldOmittedFields> {
  id?: string;
  label?: string;
  fakeLabel?: boolean;
  InputProps?: any;
  variant? : "outlined" | "filled" |  "standard";
}

export const MTextField = (props: MTextFieldProps) => {
  const { size = "small", label, fakeLabel = false,InputProps, variant = "outlined" } = props;

  return (
    <>
      {label && (fakeLabel ?
        <Typography variant="h6" sx={{ mb: 0.5 }}>{label}</Typography> : <InputLabel>{label}</InputLabel>
      )}
      <TextField
        {...props}
        size={size}
        fullWidth
        variant={variant}
        label=""
        InputProps={InputProps}
      />
    </>
  );
}

export default MTextField;