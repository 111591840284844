// theme
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    typography: {
        fontFamily: 'Inter',
        h3: {
          fontWeight: 700,
          fontSize: 32,
          lineHeight: '39px'
        },
        h4: {
          fontWeight: 700,
          fontSize: 24,
          lineHeight: '29px'
        },
        h5: {
          fontWeight: 600,
          fontSize: 20,
          lineHeight: '24px'
        },
        h6: {
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '18px'
        },
        body1: {
          fontWeight: 600,
          fontSize: 18,
          lineHeight: '22px',
          margin: 0
        }
    },
    palette: {
      background: {
        default: "#F3F5E8"
      },
      primary: {
        light: "#63b8ff",
        main: "#1D80E8",
        dark: "#005db0",
      },
      secondary: {
        main: "#4db6ac",
        light: "#82e9de",
        dark: "#00867d",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 16,
            lineHeight: '19px',
            marginBottom: 4,
            color: '#4F4949'
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 600
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 400,
            '&.Mui-selected': {
              color: '#000000',
              fontWeight: 600,
            }
          },
        }
      }
    }
  });

  export const border = {
    border: "1px solid #DEDEDE",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
  }

  export const centered = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
