import {Box, Checkbox, FormControlLabel, Skeleton} from "@mui/material";
import {OutlinedTextFieldProps} from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {MButton, MButtonProps} from "./MButton";
import {ChangeEvent, useEffect, useState} from "react";

export interface MListProps {
    newItems: any;
    newIsAvailableMore: boolean;
    handleListLoading: any;
    handleItemLoading?: any;
    renderChild: any;
    childProps: any;
}

export const MList = ({newItems,newIsAvailableMore,handleListLoading,handleItemLoading,renderChild,childProps}: MListProps) => {

    const [items, setItems] = useState<any[]>([]);
    const [isAvailableMore,setIsAvailableMore] = useState<boolean>(true);

    useEffect(() => {
        setItems(newItems);
        setIsAvailableMore(newIsAvailableMore);

    }, [newItems,newIsAvailableMore]);


    if (items.length == 0 && isAvailableMore){
        return <>
            <Box
                justifyContent="center"
                mb={5}
            >
                <Skeleton variant="rectangular" width='100%' height={40} />
                <br/>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
        </>

    }

    if(items.length == 0 && !isAvailableMore){

        return <Box justifyContent="center" display="flex" width="100%">
                <div>No data</div>
            </Box>
    }

    return <>
                <Box width="inherit">
                    <Box width="inherit" >
                        {items.map((item: any, key: any) => renderChild(item,handleItemLoading,childProps))}
                    </Box>
                    <Box justifyContent="center" display="flex" width="100%">
                        {isAvailableMore && <MButton variant="text" onClick={handleListLoading}>Load more</MButton>}
                    </Box>
                </Box>
        <br/>

            </>
}

export default MList;

