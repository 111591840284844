import {useState} from "react";
import {deleteAllEntries, writeToBinaryFile, writeToFile} from "../services/FileSystem";
import {Box, TextField, Typography} from "@mui/material";
import {
    openDevice,
    readData,
    requestPort, sendBinaryCommand,
    sendRequestSNCommand, sendRequestTimeCommand
} from "../services/WebSerial";
import {getDMSConfigs} from "../api/dms";
import {
    bufferToString,
    CONFIGURATION_JSON,
    dateToBuffer,
    ENROLLMENT_JSON,
    parseDateFromBuffer
} from "../utils/dms_utils";
import {parseLogFile} from "../utils/fileUtils";
import UploadDMS from "../containers/UploadDMS";



export const DMSTestPage = () => {
    const [directoryHandle, setDirectoryHandle] = useState<FileSystemDirectoryHandle | null>(null);

    const [enrollmentJSON, setEnrollmentJSON] = useState<string>(JSON.stringify(ENROLLMENT_JSON));
    const [configurationJSON, setConfigurationJSON] = useState<string>(JSON.stringify(CONFIGURATION_JSON));

    const [enrollmentEncrypted, setEnrollmentEncrypted] = useState<string>("--");
    const [configEncrypted, setConfigEncrypted] = useState<string>("--");

    const [dmsDevice, setDMSDevice] = useState<USBDevice | undefined>(undefined);
    const [deviceSN, setDeviceSN] = useState<string>("no data");
    const [deviceDate, setDeviceDate] = useState<string>("no data");
    const [updateTimeStamp, setUpdateTimeStamp] = useState<Date>(new Date());

    // 7. Uploading part
    const [CDMFile, setCDMFile] = useState<string | null>(null);
    const [events, setEvents] = useState<any>([]);

    const sleep = (milliseconds: number): Promise<void> => {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    };

    const handleRequestAccess = async () => {
        try {
            const handle = await window.showDirectoryPicker(
                {
                    startIn: 'desktop'
                }
            );
            setDirectoryHandle(handle);
        } catch (error) {
            console.error('Error requesting file system access:', error);
        }
    };



    async function readFirst100Bytes(fileHandle: FileSystemFileHandle): Promise<string> {
        const file = await fileHandle.getFile();
        const blob = file.slice(0, 100); // Get the first 100 bytes as a Blob
        const arrayBuffer = await blob.arrayBuffer(); // Convert Blob to ArrayBuffer
        const uint8Array = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Uint8Array

        // Decode the bytes to a string using TextDecoder
        const decoder = new TextDecoder();
        return decoder.decode(uint8Array);
    }

    const handleReadFiles = async () => {
        let newHandle = directoryHandle;

        if(newHandle === null) {
            newHandle = await window.showDirectoryPicker(
                {
                    startIn: 'desktop'
                }
            );
        }

        // raw data
        let extension = ".cdm"
        for await (const [name, handle] of newHandle.entries()) {
            if (handle.kind === 'file' && name.endsWith(extension)) {
                console.log(name);
                let data = await readFirst100Bytes(handle);
                setCDMFile(data);
            }
        }

        // logs
        let log_extension = ".log";
        for await (const [name, handle] of newHandle.entries()) {
            if (handle.kind === 'file' && name.endsWith(log_extension)) {
                console.log(name);
                let data = await parseLogFile(handle);
                setEvents(data);
            }
        }

    };

    const handleRequestSerialPortAccess = async () => {
        let device = await requestPort();
        console.log(device);
        if (device) {
            await setDMSDevice(device);
            //await sleep(1000);
            if(device){
                await openDevice(device);
                //await sleep(1000);
                await setDeviceInfo();
            }
        }
    }

    const handleSetDeviceSN = async() => {
        if (!dmsDevice) {
            return;
        }

        await sendRequestSNCommand(dmsDevice);
        let serial_number_binary = await readData(dmsDevice, 64);
        console.log("SN", serial_number_binary);
        if (serial_number_binary) {
            setDeviceSN(bufferToString(serial_number_binary))
        }
    }

    const handleSetDeviceDate = async() => {
        if(!dmsDevice) {
            return;
        }

        await sendRequestTimeCommand(dmsDevice);
        await sleep(1000);

        let date_binary = await readData(dmsDevice,64);

        if(date_binary){
            date_binary = new DataView(date_binary.buffer);
            let date_parsed = parseDateFromBuffer(date_binary);
            await setDeviceDate(date_parsed.toString());
        }
    }

    const setDeviceInfo = async () => {
        if(!dmsDevice){
            await handleRequestSerialPortAccess();
        } else {
            //await sleep(1000);
            // Request device time
            await handleSetDeviceDate();
            await sleep(1000);

            await dmsDevice.reset();
            // Request device SN
            await handleSetDeviceSN();
            // await sleep(1000);
            setUpdateTimeStamp(new Date());
        }
    }

    const handleWriteDMSConfig = async () => {
            if(dmsDevice){
                await sendBinaryCommand(dmsDevice,dateToBuffer(),false);
                await sleep(5000);
                await setDeviceInfo();
            }

            // await _sendCommand(port,"login cust1 cust1",true,1500);
            // await readData(port);
            // await _sendCommand(port,"info",true,1500);
            // await _sendCommand(port,"set MeasureDuration 1440",true,1500);
            // await _sendCommand(port,"save",true,5000);
            // await _sendCommand(port,"get MeasureDuration",true,1500);
            //
            // const formattedDateTime = new Date().toISOString().replace('.000Z', 'Z');
            // await _sendCommand(port,`settime ${formattedDateTime}`,true,1500);
            // await _sendCommand(port,"gettime",true,1500);
            // await _sendCommand(port,"logoff",true,3000);
            //await port.close();
            //let data = await
            //console.log(data);
            console.log("Finished");
    }

const handleChangeEnrollemntJSON = (event: any) => {
        const { value } = event.target;
        try {
            JSON.parse(value);
        } catch (e) {
            alert("Enrollment file error");
        }
        setEnrollmentJSON(value);
    };

    const handleChangeConfigurationJSON = (event: any) => {
        const { value } = event.target;
        try {
            JSON.parse(value);
        } catch (e) {
            alert("Enrollment file error");
        }
        setConfigurationJSON(value);
    };

    const handleEncryptFiles = async() => {
        const enrollmentData = JSON.parse(enrollmentJSON);
        const configData = JSON.parse(configurationJSON);

        const payload = {
            "config_data": configData,
            "enrollment_data": enrollmentData
        }
        let data = await getDMSConfigs(payload);
        console.log(data);

        setEnrollmentEncrypted(data.data.enrollment);
        setConfigEncrypted(data.data.config);
    }

    const handleDeleteAllEntries = async () => {
        if (!directoryHandle) return;
        try {
            await deleteAllEntries(directoryHandle);
        } catch (error) {
            console.error('Error deleting all entries:', error);
        }
    };


    const handleUploadFilesToDevice = async () => {
        await writeToFile("myPatchEnrollment.enc",enrollmentEncrypted,directoryHandle);
        await writeToBinaryFile(".config.enc",configEncrypted,directoryHandle);
    }

    return (
        <>
            <br/>
            <Typography variant="h4" paragraph={true}>Study setup</Typography>
            <br/>
            <Box display="flex" flexDirection="row" gap={2}>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h4" paragraph={true}>
                        1. Request folder access
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                        Access will remain until page closed/refreshed. For demo purposes it highlighted as separate step.
                        We request it once for all FILE RELATED OPERATIONS(delete files, create files, etc.)
                    </Typography>
                    <button onClick={handleRequestAccess}>Request File System Access</button>
                    <br/>
                    <br/>
                </Box>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h4" paragraph={true}>
                        2. Delete all previous files(clear
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                        This command will clear all previous files in directory(*.enc, *.cdm - all previous study data)
                    </Typography>
                    <button onClick={handleDeleteAllEntries}>Delete All Files</button>
                </Box>
            </Box>
            <br/>
            <br/>
            <Typography variant="h4" paragraph={true}>
                3. Form configuration files
            </Typography>

            <Box display="flex" flexDirection="row" gap={2}>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h6" paragraph={true}>
                        Enrollment file(patient's data, this file just being encrypted and copied to the device)
                    </Typography>
                    <TextField
                        label="Enrollment JSON"
                        multiline
                        minRows={3}
                        fullWidth
                        variant="outlined"
                        value={enrollmentJSON}
                        onChange={handleChangeEnrollemntJSON}
                    />

                </Box>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h6" paragraph={true}>
                        Configuration file(study parameters, this file will be encrypted and copied to the device, also some parameters will be written to the device via Web Serial)
                    </Typography>
                    <TextField
                        label="Config JSON"
                        multiline
                        minRows={3}
                        fullWidth
                        variant="outlined"
                        value={configurationJSON}
                        onChange={handleChangeConfigurationJSON}
                    />

                </Box>
            </Box>
            <br/>
            <br/>
            <Typography variant="h6" paragraph={true}>
                Convert JSONs in encrypted format required by the device
            </Typography>
            <button onClick={handleEncryptFiles}>Encrypt files</button>

            <br/>
            <br/>

            <Box display="flex" flexDirection="row" gap={2}>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h6" paragraph={true}>
                        Enrollment file encrypted
                    </Typography>
                    <TextField
                        disabled={true}
                        multiline
                        minRows={3}
                        fullWidth
                        value={enrollmentEncrypted}
                    />


                </Box>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h6" paragraph={true}>
                        Configuration file encrypted
                    </Typography>
                    <TextField
                        disabled={true}
                        multiline
                        minRows={3}
                        fullWidth
                        value={configEncrypted}
                    />

                </Box>
            </Box>


            <br/>
            <br/>
            <Typography variant="h4" paragraph={true}>
                4. Export files to device
            </Typography>
            <button onClick={handleUploadFilesToDevice}>Export files</button>

            <br/>
            <br/>

            <Box display="flex" flexDirection="row" gap={2}>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h4" paragraph={true}>
                        6. Request access to USB device
                    </Typography>
                    <button onClick={handleRequestSerialPortAccess}>Request device access</button>
                    <br/>
                    <br/>
                    <Typography variant="h6" paragraph={true}>
                        {"Device S/N: "+deviceSN}
                    </Typography>
                    <br/>
                    <Typography variant="h6" paragraph={true}>
                        {"Device time: "+deviceDate}
                    </Typography>
                    <br/>
                    <Typography variant="h6" paragraph={true}>
                        {"Updated on: "+updateTimeStamp.toLocaleTimeString()}
                    </Typography>

                </Box>
                <Box sx={{width:"40%"}}>
                    <Typography variant="h4" paragraph={true}>
                        7. Set device configuration
                    </Typography>
                    <button onClick={handleWriteDMSConfig}>Write configuration</button>
                </Box>
            </Box>

            <br/>
            <br/>
            <Box sx={{width:"100%"}}>
                <Typography variant="h4" paragraph={true}>
                    7. Parse logs and read file data(Uploading simulation)
                </Typography>
                <button onClick={handleReadFiles}>Upload data</button>
                <br/>
                <br/>
                <Box display="flex" flexDirection="row" gap={2}>
                    <Box sx={{width:"50%"}}>
                        {events.length > 1 && (
                            <div>
                                <h5>Events List</h5>
                                    {events.map((event:any, index: number) => (
                                        <li key={index}>
                                            {event?.timestamp.toLocaleString()} - {event?.level} - {event?.message}
                                        </li>
                                    ))}
                            </div>
                        )
                        }

                    </Box>
                    <Box sx={{width:"50%"}}>
                        {CDMFile && (
                            <div>
                                <h5>CDM file reading simulation(Loaded 100 first bytes of file)</h5>
                                {CDMFile}
                            </div>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        </>

    );


}