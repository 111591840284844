import { useNavigate } from "react-router-dom";
import { shortenString } from '../utils/utils';
import { baseUrls } from "../routes";
import { UnitsListTable } from "../components/UnitsListTable";
import { usePermissions, PermissionsE } from "../context/usePermissions";
import { Paper, Typography } from "@mui/material";
import { NO_PERMISSIONS_MESSAGE } from "../utils/constants";

const tableHeadings = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Unit Name',
        field: 'name'
    },
];

interface UnitsListGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;
    type: "region" | "cluster" | "clinic"
    disableEmptyMessage?: boolean;
}

function getUrl(row: any, type: string) {
    if (type == "region") {
        return baseUrls.regions + "/" + row.id
    }

    if (type == "cluster") {
        return baseUrls.clusters + "/" + row.id
    }

    return baseUrls.clinics + "/" + row.id;
}

export const UnitsListGrid = (props: UnitsListGridProps) => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const { data, type, disableEmptyMessage } = props;

    const rows = data.map((row) => ({
        ...row,
        id: row.id,
        name: shortenString(row.name),
        departments_number: row.departments_number,
        staff_number: row.staff_number,
        patients_number: row.patients_number,
        url: getUrl(row, type),
        allowed_studies: row.allowed_studies,
        analysis_platform: row.analysis_platform,
        id_organization_external: row.id_organization_external
    }));

    if (!hasPermission!(PermissionsE["units.r"])) return (
        <Paper sx={{ padding: 3 }}>
        <Typography>{NO_PERMISSIONS_MESSAGE}</Typography>
    </Paper>
    )

    return (
        <UnitsListTable
            headings={tableHeadings}
            rows={rows}
            disableEmptyMessage={disableEmptyMessage}
        />
    );
};

export default UnitsListGrid;