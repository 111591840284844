import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import {PermissionsE, usePermissions} from "../context/usePermissions";

import {listCheckpoints, requestCEREditLink, requestCERReadLink} from "../api/checkpoints";
import IframeIcon from "@mui/icons-material/Computer"; // Custom icon for "Show IFrame"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // PDF icon
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Tooltip from "@mui/material/Tooltip"; // Fullscreen icon
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const SignalReview = () => {
    const {studyId, checkpointId} = useParams();
    const navigate = useNavigate();
    const {state} = useLocation();
    const {hasPermission} = usePermissions();

    const [reviewLink, setReviewLink] = useState<string>("");
    const [diaryLink, setDiaryLink] = useState<string>("");
    const [activeComponent, setActiveComponent] = useState('cer');
    const iframeRef = useRef<HTMLIFrameElement>(null);



    const fetchLink = useCallback(async () => {
        if (!hasPermission!(PermissionsE["study.report_comment"])) return;

        if (studyId !== undefined && checkpointId !== undefined) {
            let data = await requestCEREditLink(studyId, checkpointId);
            setReviewLink(data.data.link);
            setDiaryLink(data.data.diary_link);
        }

    }, [])

    useEffect(() => {
        fetchLink();
    }, []);

    function requestFullscreen() {
        const iframe = iframeRef.current;

        try {
            if (iframe) {
                // Ensure fullscreen API is supported and request fullscreen
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                    // @ts-ignore
                } else if (iframe.mozRequestFullScreen) { // Firefox
                    // @ts-ignore
                    iframe.mozRequestFullScreen();
                    // @ts-ignore
                } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, Opera
                    // @ts-ignore
                    iframe.webkitRequestFullscreen();
                    // @ts-ignore
                } else if (iframe.msRequestFullscreen) { // IE/Edge
                    // @ts-ignore
                    iframe.msRequestFullscreen();
                } else {
                    console.log("Fullscreen API is not supported in this browser.");
                }
            }
        } catch (error) {

        }
    }




    return (
        <>
            <Box display="flex" height="90vh" width="97vw">
                {/* Left Vertical Button Group */}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: "2vw",
                        // backgroundColor: "#f0f0f0",
                        // padding: 1,
                        paddingTop: 1
                    }}
                >
                    {reviewLink !== "" &&
                        <Tooltip title="Show CER-SCor window">
                            <IconButton size="small" onClick={() => setActiveComponent("cer")}>
                                <IframeIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                    {diaryLink !== "" &&
                        <Tooltip title="Show PDF Diary">
                            <IconButton size="small" onClick={() => setActiveComponent("report")}>
                                <PictureAsPdfIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                    {reviewLink !== "" &&
                        <>
                            <Tooltip title="CER-SCor Full Screen">
                                <IconButton size="small" onClick={() => requestFullscreen()}>
                                    <FullscreenIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <IconButton size="small" onClick={() => window.open(reviewLink, "_blank")}>
                                <OpenInNewIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                </Box>

                {/* IFrame */}
                <Box flexGrow={1}>
                        <iframe
                            ref={iframeRef}
                            src={reviewLink}
                            frameBorder="0"
                            allowFullScreen
                            width="100%"
                            height="100%"
                            style={{ display: activeComponent === 'cer' ? 'block' : 'none',overflow: 'hidden' }}
                        />

                    <iframe
                        src={diaryLink}
                        frameBorder="0"
                        allowFullScreen
                        width="100%"
                        height="100%"
                        style={{ display: activeComponent === 'report' ? 'block' : 'none' }}
                    />

                </Box>
            </Box>
        </>
    );
}
