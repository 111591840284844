import MInfrastructuresTable from "../components/MInfrastructuresTable";
import { TableHeading } from "../components/MTable";
import { PermissionsE, usePermissions } from "../context/usePermissions";

interface InfrastructuresGridProps {
    data: any[];
}

export const InfrastructuresGrid = (props: InfrastructuresGridProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["infrastructures.w"]);
    
    const { data } = props;

    const tableHeadings = [
        {
            fieldName: 'Name',
            field: 'name'
        },
        {
            fieldName: 'Data service',
            field: 'data'
        },
        {
            fieldName: 'Dashboard service',
            field: 'dashboard'
        },
        {
            fieldName: 'Device service',
            field: 'device'
        },
        {
            fieldName: 'Users service',
            field: 'users'
        },
        canWrite ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    const rows = data.map((row) => ({
        ...row,
        id: row.id,
        name: row.name,
        data: row.data,
        device: row.device,
        users: row.users,
        dashboard: row.dashboard
    }));

    return (
        <MInfrastructuresTable
            headings={tableHeadings}
            rows={rows}
        />
    );
};

export default InfrastructuresGrid;