import { useState, useEffect } from "react";
import { Box, InputLabel, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isValidDate, pickersMigrationHack } from '../utils/utils';

type Value = Date | null;

interface MDateTimePickerProps {
  dateLabel?: string;
  timeLabel?: string;
  value?: Value;
  onChange: (date: Value) => void;
  disabled?: boolean;
  error?: boolean;
  dateErrorText?: string;
  timeErrorText?: string;
}

export const MDateAndTimePicker = (props: MDateTimePickerProps) => {
  const { dateLabel, timeLabel, value = null, onChange, disabled, error, dateErrorText, timeErrorText } = props;

  const [date, setDate] = useState<Value>(null);
  const [time, setTime] = useState<Value>(null);

  useEffect(() => {
    if (date && time) {
      const formattedHours = new Date(time).getHours();
      const formattedMinutes = new Date(time).getMinutes();
      const formattedDate = new Date(date);
      formattedDate.setHours(formattedHours);
      formattedDate.setMinutes(formattedMinutes);
      if (isValidDate(formattedDate)) {
        onChange(formattedDate)
      } else {
        onChange(null)
      }
    } else {
      onChange(null)
    }
  }, [date, time]);

  const dateError = error && !isValidDate(date);
  const timeError = error && !isValidDate(time);

  const dateErrorTextToShow = dateError && isValidDate(date) ? dateErrorText : `${dateLabel} is invalid`
  const timeErrorTextToShow = timeError && isValidDate(time) ? timeErrorText : `${timeLabel} is invalid`

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        minWidth: 0,
        width: 320
      }} 
    >
      <Box>
        {dateLabel && <InputLabel>{dateLabel}</InputLabel>}
        <DatePicker
          value={date}
          onChange={(d: Value) => setDate(d)}
          disabled={disabled}
          sx={{
            width: "100%",
            ...pickersMigrationHack(error)
          }}
        />
        {dateError ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit'}}>{dateErrorTextToShow}</FormHelperText> : <></>}
      </Box> 
      <Box mt={2}>
        {timeLabel && <InputLabel>{timeLabel}</InputLabel>}
        <TimePicker
          value={time}
          onChange={(t: Value) => setTime(t)}
          disabled={disabled}
          sx={pickersMigrationHack(timeError)}
        />
        {timeError ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit'}}>{timeErrorTextToShow}</FormHelperText> : <></>}
      </Box> 
    </Box> 
  );
}