import { config } from "../config";
import { networkLayer } from './networkLayer';

export interface BaseDeviceInterface {
  id_unique?: string;
  study_id?: string;
}

export type NewDeviceTypeOption = 'mawi_watch' | 'mawi_patch' |  'mawi_vital';

export type PPGDataFrequency = '*/30 * * * *' |  '0 * * * *' ;


export interface DeviceInterface extends BaseDeviceInterface {
  serial_number: string;
}

export interface DeviceRPMInterface extends BaseDeviceInterface {
  serial_number: string;
  ppg_frequency : string,
  ecg_frequency : any,
  wifi_login : string,
  wifi_password : string
}

export interface MultipleDeviceInterface extends BaseDeviceInterface {
  serial_numbers: string[];
}

export interface DeviceMeasurementInterface extends BaseDeviceInterface {
  serial_number: string;
  date_start?: Date | string;
  sharable_code?: string;
}

export const checkIsDeviceExist = async (values: DeviceInterface) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/is_exists/`, {
    serial_number: values.serial_number
  });
}

export const checkIsDevicesExist = async (values: MultipleDeviceInterface) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/are_devices_exist/`, {
    serial_numbers: values.serial_numbers
  });
}

export const connectDevice = async (values: DeviceInterface ) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/connect/`, {
    serial_number: values.serial_number,
    id_unique: values.id_unique,
    study_id: values.study_id
  });
}

export const connectRPMDevice = async (values: DeviceRPMInterface) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/connect/`, {
    serial_number: values.serial_number,
    id_unique: values.id_unique,
    study_id: values.study_id,
    ppg_frequency : values.ppg_frequency,
    ecg_frequency : values.ecg_frequency,
    wifi_login : values.wifi_login.length > 0 ? values.wifi_login : null,
    wifi_password : values.wifi_password.length > 0 ? values.wifi_password : null
  });
}


export const connectMultipleDevices = async (values: MultipleDeviceInterface) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/connect_multiple_devices/`, {
    serial_numbers: values.serial_numbers,
    id_unique: values.id_unique,
    study_id: values.study_id
  });
}

export const getMeasurmentChunks = async (offset: number, limit: number) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/measurement_chunks/`, null, {
    offset,
    limit
  });
}


export const createDeviceMeasurement = async (values: DeviceMeasurementInterface) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/create_patient_record_patch/`, {
    device_serial_number: values.serial_number,
    study_id: values.study_id,
    id_unique: values.id_unique,
    date_start: values.date_start,
    device_type: 'lifesignal_patch',
    sharable_code: values.sharable_code
  });
}

export const adminListDevices = async (device_serial_number: string | undefined) => {
  return await networkLayer('get', `${config.deviceUrl}/dashboard/admin/list_devices/`, null,{
    device_serial_number
  });
}

export const adminUpdateConnection = async (device_serial_number: string, data: any) => {
  return await networkLayer('put', `${config.deviceUrl}/dashboard/admin/update_connection/`, data,{
    device_serial_number
  });
}

export const adminDisableConnection = async (device_serial_number: string, data: any) => {
  return await networkLayer('put', `${config.deviceUrl}/dashboard/admin/disable_connection/`, data,{
    device_serial_number
  });
}

export const adminAddDevice = async (data: any) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/admin/add_device/`, data,null);
}

export const addFirmarePackage = async (data: any) => {
  return await networkLayer('post', `${config.deviceUrl}/dashboard/admin/firmware-packages/`, data,null);
}

export const listFirmwarePackages = async () => {
  return await networkLayer('get', `${config.deviceUrl}/dashboard/admin/firmware-packages/`, null,null);
}

export const deleteFirmwarePackage = async (id:any) => {
  return await networkLayer('delete', `${config.deviceUrl}/dashboard/admin/firmware-packages/${id}/`, null,null);
}

export const updateFirmwarePackage = async (id:any,data: any) => {
  return await networkLayer('put', `${config.deviceUrl}/dashboard/admin/firmware-packages/${id}/`, data,null);
}
