import { useState } from 'react';
import { MButton, MButtonProps } from './MButton';
import MModal from './MModal';
import { Box, Typography } from '@mui/material';

export interface MButtonWithConfirm extends MButtonProps {
    confirmContent?: JSX.Element;
    confirmHandler?: () => void;
    cancelButtonText?: string;
    confirmButtonText?: string;
}

export const MButtonWithConfirm = ({
    confirmContent, 
    confirmHandler,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    ...button
}: MButtonWithConfirm) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const confirmContentToShow = confirmContent || (
        <Box mb={1} textAlign="center">
            <Typography variant="h6">Are you sure?</Typography>
        </Box>
    );

    return (
        <>
            <MModal
                open={isOpen}
                handleClose={() => setIsOpen(false)}
            >
                <>
                {confirmContentToShow}
                <Box display="flex" justifyContent="center">
                    <Box mr={3}>
                        <MButton variant="outlined" onClick={() => setIsOpen(false)}>{cancelButtonText}</MButton>
                    </Box>
                    <Box>
                        <MButton 
                            onClick={() => {
                                confirmHandler?.();
                                setIsOpen(false);
                            }}
                        >
                            {confirmButtonText}
                        </MButton>
                    </Box>
                </Box>
                </>
            </MModal>
            <MButton onClick={() => setIsOpen(true)} {...button} />
        </>
    );
}

export default MButtonWithConfirm;