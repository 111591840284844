import React from "react";
import {useNavigate} from "react-router-dom";

import { Box, Toolbar, Typography } from "@mui/material";

import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import MawiLogo from "../images/logo.svg";
import { border } from '../theme';
import {isAdmin, isClinician, isSuperAdmin, useAuth} from "../context/useAuth";
import { baseUrls, homePageUrl } from "../routes";
import {MLink} from "../components/MLink";
import { usePermissions, PermissionsE } from "../context/usePermissions";

interface SettingsMenuItem {
  name: string;
  onClick?: () => void;
}

const Navbar = () => {
  const { user, logout } = useAuth();
  const { hasPermission } = usePermissions();
  const isAuthenticated = !!user;

  const settings: SettingsMenuItem[] = [ 
    // {
    //   name: 'Profile'
    // },
    // {
    //   name: 'Account'
    // },
    // {
    //   name: 'Dashboard'
    // },
    {
      name: 'Logout',
      onClick: logout
    }
  ];

  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const homePage = homePageUrl(user);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingX: 2,
        ...border
      }}
    >
      <Toolbar 
        disableGutters
        style={{
          justifyContent: isAuthenticated ? "space-between" : "center"
        }}
      >
        <Box display="flex" alignItems="center">
          <Box height="32px">
            <MLink to={homePage}>
                <img src={MawiLogo} alt="Mawi Logo" />
            </MLink>
          </Box>

          {isAuthenticated && !isClinician(user) &&
            <Box 
              display="flex" 
              justifyContent="space-between"
              sx={{
                '& a': {
                  margin: '0 16px'
                }
              }}
            >
              {hasPermission!(PermissionsE["tab.accounts"]) ? 
                <MLink to={baseUrls.accounts}>
                  Accounts
                </MLink> : <></>
              }
              <MLink to={homePage}>
                Clinics
              </MLink>
              <MLink to={baseUrls.clientsTools}>
                Studies/Devices
              </MLink>
              {hasPermission!(PermissionsE["tab.settings"]) ? 
                <MLink to={baseUrls.portalSettings}>
                  Settings
                </MLink> : <></>
              }
            </Box>
          }
        </Box>

        {
          isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="test" src="https://cdn-icons-png.flaticon.com/512/147/147144.png" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting: SettingsMenuItem) => (
                  <MenuItem 
                    key={setting.name} 
                    onClick={() => {
                      setting.onClick && setting.onClick();
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )
        }
      </Toolbar>
    </Box>
  );
};

export default Navbar;
