import PageHeader from "../containers/PageHeader";
import {useNavigate, useParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {CheckpointsGrid} from "../containers/CheckpointsGrid";
import {useCallback, useEffect, useState} from "react";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import {listCheckpoints} from "../api/checkpoints";



export const TechnicianCheckpoints = () => {
    const { studyId } = useParams();

    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const [checkpoints, setCheckpoints] = useState([]);

    const fetchCheckpoints = useCallback(async () => {
        if (!hasPermission!(PermissionsE["study.report_comment"])) return;

        if (studyId !== undefined){
            let data = await listCheckpoints(studyId);
            console.log(data);
            setCheckpoints(data);
        }

    }, [])

    useEffect(() => {
        fetchCheckpoints();

    }, []);

    return (
        <>
            <PageHeader title={"Study " + studyId} actions={
                [{ onClick: () => { navigate(-1) }, name: "Close" }]
            }/>
            <Paper sx={{ minHeight: '70vh', padding : "1%" }}>
                <CheckpointsGrid data={checkpoints}/>
            </Paper>
        </>
    )
}