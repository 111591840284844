import { useNavigate } from "react-router-dom";
import { MStudyTable, FieldType } from '../components/MStudyTable';
import { formatName, formatEnrollment, formatDateToShow, isDraft, formatStudyType } from '../utils/utils';
import { baseUrls } from "../routes";
import MCheckpointsTable from "../components/MCheckpointsTable";

const tableHeadings = [
    {
        fieldName: 'ID',
        field: 'id',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Date modified',
        field: 'date_created',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Report generated',
        field: 'is_report_generated',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Manual',
        field: 'is_manual',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Comment',
        field: 'comment',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Author',
        field: 'author',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Source',
        field: 'source_checkpoint',
        type: 'name' as FieldType
    },
    {
        fieldName: 'Actions',
        field: 'actions'
    }
];

interface CheckpointsGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;
}

export const CheckpointsGrid = (props: CheckpointsGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row,
    }));

    return (
        <MCheckpointsTable
            headings={tableHeadings}
            rows={rows}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
            onRowClick={(id) => navigate(`${baseUrls.study}/${id}`)}
        />
    );
};