import {Link, LinkProps} from "react-router-dom";

export interface MLinkProps extends LinkProps {
    underlined?: boolean;
}

export const MLink = ({ to, children, underlined }: MLinkProps) => {

    return (
        <Link 
            to={to}
            style={{
              textDecoration: underlined ? 'undeline' : 'none',
              display: 'flex',
              alignItems: 'center',
              padding: '6px 8px',
              fontSize: 14,
              color: '#000'
            }}
        >
            {children}
        </Link>
    );
}

export default MLink;