// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import "./styles.css";

interface MTreeViewAsyncProps {
  childrenData?: any[];
  handleItemsLoading?: (id: string) => Promise<void>;
  handleSelectedItems?: (ids: string[]) => void;
  isEdit?: boolean;
  sids?: string[]; // selectedIds
  hsids?: string[]; // half selectedIds
  onCheckboxClicked: () => void;
}

const MTreeViewAsync = (props: MTreeViewAsyncProps) => {
  const { childrenData, handleItemsLoading, handleSelectedItems, isEdit, sids, hsids, onCheckboxClicked } = props;
  const loadedAlertElement = useRef(null);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [halfSelectedIds, setHalfSelectedIds] = useState<string[]>([]);
  const [data, setData] = useState(flattenTree({
    name: '',
    id: null,
    children: []
  }));

  useEffect(() => {
    if (childrenData) {
      setData(flattenTree({
        ...data,
        children: childrenData
      }))
    }
  }, [childrenData])

  useEffect(() => {
    if (data.length > 1) {
      setSelectedIds(sids);
      setHalfSelectedIds(hsids);
    }
  }, [data, isEdit])

  const onLoadData = async ({ element }) => {
    await handleItemsLoading(element.id)
  };

  const handleSelect = (props) => {
    handleSelectedItems([...props.treeState.selectedIds])
  };

  const showOffset = (entityType?: EntityType) => {
    if (entityType === 'clinic') return false;
  
    return true;
  }

  if (!childrenData) return <></>;

  return (
    <>
      <div>
        <div
          className="visually-hidden"
          ref={loadedAlertElement}
          role="alert"
          aria-live="polite"
        ></div>
        <div className="checkbox">
          <TreeView
            data={data}
            onLoadData={onLoadData}
            onSelect={handleSelect}
            multiSelect
            togglableSelect
            selectedIds={selectedIds}
            propagateSelectUpwards
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              isSelected,
              isHalfSelected,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              const branchNode = (isExpanded, element) => {
                return isExpanded && element.children.length === 0 ? (
                  <>
                    <span
                      role="alert"
                      aria-live="assertive"
                      className="visually-hidden"
                    >
                      loading {element.name}
                    </span>
                    <FontAwesomeIcon
                      className="loading-icon"
                      icon={faSpinner}
                    />
                  </>
                ) : (
                  <Box className="tree-node-arrow">
                    {isExpanded ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                      />
                    )}
                  </Box>
                );
              };
              return (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 40 * (level - 1) }}
                >
                  {isBranch && branchNode(isExpanded, element)}
                  {!isBranch && showOffset(element.metadata?.entityType) && (
                    <Box className="tree-node-offset" />
                  )}
                  <FormControlLabel
                    label={element.name}
                    control={
                      <Checkbox
                        checked={isSelected}
                        indeterminate={isHalfSelected || (isEdit && halfSelectedIds.includes(element.id))}
                        onChange={(e) => {
                          onCheckboxClicked();
                          !isExpanded && handleExpand(e);                      
                          handleSelect(e);
                          e.stopPropagation();
                        }}
                      />
                    }
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default MTreeViewAsync;