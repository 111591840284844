import MList from "./MList";
import * as React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {poincareChart, ppgChart, rrTachogramChart} from "./MChart";
import {baseUrls} from "../routes";
import Divider from "@mui/material/Divider";

export const renderAccordionDetailsPPG = (item: any) => {
    return <>
        <Box>
            <Typography variant="h6">PPG Signal</Typography><br/>
            <HighchartsReact
                highcharts={Highcharts}
                options={ppgChart(item)}
            />
        </Box>
        <Box display="flex" justifyContent="space-between">
            <Box width="70%">
                <Box my={2}>
                    <Typography variant="h6">R-R Tachogram</Typography>
                </Box>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={rrTachogramChart(item)}
                />
            </Box>
            <Box width="25%">
                <Box my={2}>
                    <Typography variant="h6">Poincarre</Typography>
                </Box>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={poincareChart(item)}
                />
            </Box>
        </Box>
    </>
}

const renderDepartmentItem = (item : any, handleItemLoading?:any, childProps?: any) => {
    return <>
        <Box sx={{ paddingLeft: 5, padding:3, '&:hover': {backgroundColor: '#F8F8F8',cursor: 'pointer'}}}
             width="100%"
            onClick={() => childProps.extra.childProperies(`${baseUrls.regions}/${item.id}`)}
        >
            <Box display="flex" onClick={()=>{}} width="100%"  >
                <Box mr={5} width="20%" >
                    <Typography variant="body1">{item.name}</Typography>
                </Box>
                <Box mr={5} width="20%">
                    <Typography variant="body2">{item.patients_number} patients</Typography>
                </Box>
                <Box mr={5} width="20%">
                    <Typography variant="body2">{item.staff_number} accounts</Typography>
                </Box>

            </Box>
        </Box>
        <Divider/>
    </>

}

export const renderAccordionDetailsCompaniesList = (item: any,childProperies:any) => {
    return <><MList
        newItems={item?.departments ?? []}
        newIsAvailableMore={false}
        handleListLoading={() => {}}
        handleItemLoading={() => {}}
        renderChild={renderDepartmentItem}
        childProps={{"extra":{childProperies}}}
    /></>
}
