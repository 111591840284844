import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {Box, Button, ButtonBase, IconButton, Paper} from "@mui/material";
import {formatDateToShow, formatTimeToShow} from "../utils/utils";
import {useEffect, useState} from "react";


// interface MAccordionProps {
//     title: any;
//     children: any;
//     defaultExpanded?: string;
//     name?: string;
//     onExpandCallback?: () => void;
//     onCollapseCallback?: () => void;
//     icon?: any;
//     defaultButtonText?: string;
// }

interface MAccordionProps {
    title?: any;
    children?: any;
    handleItemRendering: (item: any,childProperties? : any) => any;
    handleItemLoading: (item: any) => any;
    handleHeaderRendering: (item: any, buttonText: string) => any;
    icon?: any;
    defaultButtonText?: string;
    newItem: any,
    childProperties? : any
}

export const MAccordion = (props: MAccordionProps) => {
    const {
        newItem,
        handleItemLoading,
        handleItemRendering,
        handleHeaderRendering,
        icon = null,
        defaultButtonText = "Show details",
        childProperties
    } = props;

    const [expanded, setExpanded] = React.useState(false);
    const [buttonText, setButtonText] = React.useState<string>(defaultButtonText);
    const [item, setItem] = useState<any>(newItem);

    useEffect(() => {

        }
    )
    const handleChildDataLoad = async () => {
        let data = await handleItemLoading(item.id);
        let updatedItem = {
            ...item,
            ...data
        }
        setItem(updatedItem);
    }

    const handleChange = (isNewExpanded: boolean) => {
        if (isNewExpanded) {
            setExpanded(isNewExpanded);
            //onExpandCallback();
            setButtonText("Hide details");
            handleChildDataLoad();
        } else {
            setButtonText(defaultButtonText);
            setExpanded(isNewExpanded);
        }
    };

    return (
        <Accordion
            square={false}
            expanded={expanded}
            onChange={(e, expanded) => handleChange(expanded)}
            sx={{
                '&.MuiPaper-root': {
                    //boxShadow: 'none',

                    '&:before': {
                        backgroundColor: 'transparent'
                    }
                }
            }}
        >
            {handleHeaderRendering(item, buttonText)}
            <AccordionDetails>
                {handleItemRendering(item,childProperties)}
            </AccordionDetails>
        </Accordion>
    );
}


export const renderAccordionECG = (item: any, handleItemLoading: any, extra: any) => {

    return <>
        <Box sx={(item.is_afib) ?
            {backgroundColor: "#FFEEEE", borderRadius: 1, boxShadow: 1} :
            {backgroundColor: "white", borderRadius: 1, boxShadow: 1}}
             width="100%"
             display="flex"
             mb={2}
             p={1}
             justifyContent="space-between">
            <Box width="75%" height={40} justifyContent='left' display='flex' alignItems='center'>
                {item.is_afib && <Box width="3%"><ReportProblemIcon/></Box>}
                {(item.is_afib) ?
                    <Box><Typography
                        variant="body1">{`Signs of AFib, ${item.heart_rate} bpm average (${formatTimeToShow(item.date)})`}</Typography></Box>
                    :
                    <Box><Typography
                        variant="body1">{`Sinus rhythm, ${item.heart_rate} bpm average (${formatTimeToShow(item.date)})`}</Typography></Box>
                }
            </Box>
            <Box width="10%" justifyContent="right" display='flex' alignItems='center'>
                <Button variant="text"
                        disabled={item.url == null}
                        onClick={() => window.open(item.url, "_blank")}><u>Open PDF</u></Button>
            </Box>

        </Box>

    </>;
}


export const renderAccordionPPG = (item: any, handleItemLoading: any, extra: any) => {
    return <Box mb={2}>
        <MAccordion
            newItem={item}
            handleItemLoading={handleItemLoading}
            handleItemRendering={extra.extra.renderAccordionDetailsPPG}
            handleHeaderRendering={extra.extra.renderAccordionHeaderPPG}
            title={null}/>
    </Box>
}


export const renderAccordionCompanies = (item: any, handleItemLoading: any, extra: any) => {

    return <Box>
        <MAccordion
            newItem={item}
            handleItemLoading={handleItemLoading}
            handleItemRendering={extra.extra.renderAccordionDetailsCompaniesList}
            handleHeaderRendering={extra.extra.renderAccordionHeaderCompaniesList}
            childProperties={extra.extra.navigate}
            title={null}/>
    </Box>
}