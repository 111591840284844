import { ReactElement } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

interface FormSectionProps {
  sectionName: string;
  children: ReactElement;
  divider?: boolean;
}

const FormSection = ({ sectionName, children, divider = true }: FormSectionProps) => {
  return (
    <>
      <Box sx={{ display: 'flex' }} p={5}>
        <Typography variant="h5" sx={{ minWidth: 240 }}>{sectionName}</Typography>
        <Box
          sx={{
            '& .MuiFormControl-root, & .MuiAutocomplete-root': { width: 320, paddingBottom: 1.5, margin: 0 },
            '& .MuiFormControl-root:last-child, & .MuiAutocomplete-root:last-child': { paddingBottom: 0 },
          }}
        >
          {children}
        </Box>
      </Box>
      {divider && <Divider />}
    </>
  );
};

export default FormSection;