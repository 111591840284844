import { createContext, useContext, useMemo, ReactElement, useEffect, useCallback } from "react"
import { useAuth, User } from "./useAuth";

interface PermissionsContextInterface {
  hasPermission?: (permission: string) => boolean;
}

const defaultPermissionsContext = {};

const PermissionsContext = createContext<PermissionsContextInterface>(defaultPermissionsContext);

interface PermissionsProviderInterface {
  children: ReactElement;
}

export enum PermissionsE {
  // Pages (tabs)
  'tab.accounts' = 'tab.accounts',
  'tab.settings' = 'tab.settings',
  'tab.review' = 'tab.review',

  // Id organization externals
  'id_orgs_ext.r' = 'id_orgs_ext.r',
  'id_orgs_ext.w' = 'id_orgs_ext.w',

  // Infrastructure
  'infrastructures.r' = 'infrastructures.r',
  'infrastructures.w' = 'infrastructures.w',

  // Firmware packages
  'fw_packages.r' = 'fw_packages.r',
  'fw_packages.w' = 'fw_packages.w',

  // Roles
  'role_perm.r' = 'role_perm.r',
  'role_perm.w' = 'role_perm.w',

  // Devices
  'device.r' = 'device.r',
  'device.w' = 'device.w',

  // System staff
  'system__staff.r' = 'system__staff.r',
  'system__staff.w' = 'system__staff.w',


  // Unit staff
  'units__staff.r' = 'units__staff.r',
  'units__staff.w' = 'units__staff.w',

  // Patient
  'patient.r' = 'patient.r',
  'patient.w' = 'patient.w',

  // Units 
  'units.r' = 'units.r',
  'units.w' = 'units.w',
  'units.r_tech' = 'units.r_tech',
  'units.w_tech' = 'units.w_tech',
  'units.r_meta' = 'units.r_meta',
  'units.w_meta' = 'units.w_meta',

  // Study
  'study.r' = 'study.r',
  'study.w' = 'study.w',
  'study.r_tech' = 'study.r_tech',
  'study.w_tech' = 'study.w_tech',
  'study.r_meta' = 'study.r_meta',
  'study.w_meta' = 'study.w_meta',
  'study.sign' = 'study.sign',
  'study.report_comment' = 'study.report_comment',
  'study.log' = 'study.log',
  'study.submission' = 'study.submission',
  'study.raw' = 'study.raw',
  'study.rep_replace' = 'study.rep_replace',
}

export const checkPermissions = (user: User | null): boolean => {
  if (!user || !user.staff_profile?.permissions) {
    return false;
  }

  return "permissions" in user.staff_profile;
};

export const PermissionsProvider = ({ children }: PermissionsProviderInterface) => {
  const { user, logout } = useAuth();

  useEffect(() => {
    if (!checkPermissions(user)) {
      logout();
    }
  }, [user]);

  const hasPermission = useCallback((permission: string): boolean => {
    if (!checkPermissions(user)) {
      logout();
    }
    return user?.staff_profile.permissions.includes(permission);
  }, [user])


  const value = useMemo(
    () => ({
      hasPermission
    }),
    [user]
  );

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};