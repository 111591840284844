import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps, SelectChangeEvent  } from '@mui/material/Select';
import { useEffect, useState } from 'react';

type SelectOmittedFields = 'onChange';

interface SelectOption {
  label: string;
  value: string | undefined;
}

interface MSelectProps extends Omit<SelectProps, SelectOmittedFields> {
  value?: string;
  onChange: (e: SelectChangeEvent) => void;
  options: SelectOption[];
  disabled?: boolean;
}

export const MSelect = (props: MSelectProps) => {
  const { label, value, onChange, options, disabled } = props;

  const [currValue, setCurrValue] = useState<string | undefined>('');

  useEffect(() => {
    setCurrValue(value);
  }, [value])

  const shortenName = (value:string) => {
    return (value.length > 30) ? value.substring(0, 30) + "..." : value;
  }

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <FormControl size="small" sx={{ width: '100%' }} disabled={disabled} >
        <Select
          value={currValue}
          onChange={onChange}
        >
          {options.map((option: SelectOption, key: number) =>(
            <MenuItem key={key} value={option.value}>{shortenName(option.label)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default MSelect;
