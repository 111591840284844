import { useState } from "react";
import Box from "@mui/material/Box";
import MModal from "./MModal";
import { Typography } from "@mui/material";
import { MButton } from "./MButton";
import MTextField from "./MTextField";
import MTable, { MTableActionsButtons, MTableProps, TableHeading } from "./MTable";
import MTextarea from "./MTextarea";
import MCheckbox from "./MCheckbox";
import { 
    UserRolePermissionsEnum, 
    addRolePermissions,
    updateRolePermissions,
    deleteRolePermissions
} from "../api/permissions";
import { PermissionsE, usePermissions } from "../context/usePermissions";

interface MPermissionsTableProps extends MTableProps {
    onRowClick?: (id: string) => void;
}

export const MPermissionsTable = (props: MPermissionsTableProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["role_perm.w"]);

    const [busy, setBusy] = useState(false);
    const { headings, rows, sx, onRowClick } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();


    const renderCell = (heading: TableHeading, row: any) => {
        if (Object.values(UserRolePermissionsEnum).includes(heading.field as UserRolePermissionsEnum)) {
            return (
                <MCheckbox
                    checked={row?.[heading.field]}
                    onChange={() => {}}
                    disabled
                />
            )
        }

        switch (heading.field) {
            case "actions":
                return canWrite ? (
                    <MTableActionsButtons 
                        rowData={row}
                        onEdit={() => {
                            setSelectedRow(row);
                            setIsEdit(true);
                            setIsEditModalOpen(true)
                        }}
                        onDelete={()=>{
                            setSelectedRow(row);
                            setIsDeleteModalOpen(true);
                        }}
                    />
                ) : <></>;
            default:
                return row[heading.field];
        }
    };

    const handleItemChange = (keyToUpdate: string, newValue: string | number | boolean | null) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const submitUpdateItem = async() => {
        if (!canWrite) return;
        let data = {
            ...selectedRow
        }
        await updateRolePermissions(selectedRow.id, data);
        window.location.reload();
    }

    const submitItem = async () => {
        if (!canWrite) return;
        let data = {
            ...selectedRow
        }
        await addRolePermissions(data);
        window.location.reload();
    }

    const submitDeleteItem = async() => {
        if (!canWrite) return;
        setBusy(true);
        await deleteRolePermissions(selectedRow?.id);
        window.location.reload();
    }

    const closeHandler = () => {
        setIsEdit(false);
        setIsEditModalOpen(false);
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        closeHandler();
        if(isEdit){
            await submitUpdateItem().finally(() => setTimeout(() => setBusy(false), 500));
        } else {
            await submitItem().finally(() => setTimeout(() => setBusy(false), 500));
        }
    }


    const deleteModal = (
        <MModal
            open={isDeleteModalOpen}
            handleClose={() => {setIsDeleteModalOpen(false)}}
            width={480}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                    Are you sure you want to delete current Role Permission?
                </Typography>
                <Box sx={{textAlign: "center", mt: 4}}>
                    <MButton disabled={busy} color="error" onClick={() => setIsDeleteModalOpen(false)}>Cancel</MButton>
                    <MButton busy={busy} sx={{ml: 2}} onClick={submitDeleteItem}>Save</MButton>
                </Box>
            </Box>
        </MModal>
    );

    const addEditModal = (
        <MModal
            open={isEditModalOpen}
            handleClose={() => {setIsEditModalOpen(false)}}
            width={480}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                    Role Permissions
                </Typography>
                <Box mb={2}>
                    <MTextField
                        label="Key"
                        value={selectedRow?.key}
                        onChange={(e) => {handleItemChange("key", e.target.value)}}
                        inputProps={{
                            maxLength: 20
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <MTextarea 
                        label="Description"
                        value={selectedRow?.description}
                        onChange={(v) => {handleItemChange("description", v)}}
                        maxLength={250}
                    />
                </Box>
                {Object.values(UserRolePermissionsEnum).map((option, key) => (
                    <Box key={key}>
                        <MCheckbox
                            label={option as string}
                            checked={selectedRow?.[option as string]}
                            onChange={(e, checked) => handleItemChange(option as string, checked)}
                        />
                    </Box>
                ))}
                <Box sx={{textAlign: "center", mt: 4}}>
                    <MButton disabled={busy} color="error" onClick={closeHandler}>Cancel</MButton>
                    <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler}>Save</MButton>
                </Box>
            </Box>
        </MModal>
    );

    return (
        <div>
            {deleteModal}
            {addEditModal}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >
                <Typography variant="h4">
                    Role Permissions
                </Typography>
                {canWrite ? (
                    <MButton variant="contained" color="primary" onClick={() => {
                        setIsEditModalOpen(true);
                        setSelectedRow(null)
                    }}>
                        Add Role Permission
                    </MButton>
                ) : <></>}
            </Box>
            <MTable 
                headings={headings}
                rows={rows}
                sx={sx}
                renderCell={renderCell}
            />  
        </div>
    );
}

export default MPermissionsTable;


