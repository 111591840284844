import {networkLayer} from "./networkLayer";
import {config} from "../config";

export const addIdOrganizationExternal = async (data: any) => {
    return await networkLayer('post', `${config.baseUrl}/id_organization_externals/`, data,null);
}

export const listIdOrganizationExternals = async () => {
    return await networkLayer('get', `${config.baseUrl}/id_organization_externals/`, null,null);
}
//
export const deleteIdOrganizationExternal = async (id:any) => {
    return await networkLayer('delete', `${config.baseUrl}/id_organization_externals/${id}/`, null,null);
}

export const updateIdOrganizationExternal = async (id:any,data: any) => {
    return await networkLayer('put', `${config.baseUrl}/id_organization_externals/${id}/`, data,null);
}