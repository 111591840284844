import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface RadioOption {
  label: string;
  value: string;
}

interface MRadioProps {
  label?: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: RadioOption[];
  disabled?: boolean;
}

export const MRadio = (props: MRadioProps) => {
  const { label, value = null, onChange, options, disabled } = props;

  if (!options) return <></>;

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <FormControl>
        <RadioGroup
          value={value}
          onChange={onChange}
        >
          {options.map((option: RadioOption, key) => (
            <FormControlLabel key={key} value={option.value} control={<Radio sx={{ paddingY: 0.5 }} disabled={disabled} />} label={option.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default MRadio;
