import { SxProps, Theme } from '@mui/system';
import {Link} from "react-router-dom";
import MTable from './MTable';

export type FieldType = 'name' | 'departments_number' | 'staff_number' | 'patients_number';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface UnitsListTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
    disableEmptyMessage?: boolean;
}

const formatMultipleValuesColumn = (i: number, value: string) => {
    if (i !== 0) {
        return <div>
            <br />
            {value}
        </div>;
    }

    return <div>{value}</div>;
};

export const UnitsListTable = (props: UnitsListTableProps) => {
    const { headings, rows, disableEmptyMessage } = props;


    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            // case 'id':
            //     return <b>{row[heading.field]}</b>;
            case 'id':
                return <Link to={row.url}>{row[heading.field]}</Link>;
            case 'allowed_studies':
                return <>{row[heading.field].map((study_type:any,i:number) =>
                        formatMultipleValuesColumn(i,study_type))}</>

            default:
                return row[heading.field];
        }
    };

    if (!rows.length && !!disableEmptyMessage) return <></>;

    return (
        <MTable 
            headings={headings}
            rows={rows}
            renderCell={renderCell}
        />
    );
}

export default UnitsListTableProps;
