import { SxProps, Theme } from '@mui/system';

import {DeviceInfo} from "../containers/DeviceInfo";
import {useState} from "react";
import {Chip, Drawer} from "@mui/material";
import MTable from './MTable';

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface AdminDevicesTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

export const AdminDevicesTable = (props: AdminDevicesTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const [selectedRow,setSelectedRow] = useState<any>(null);
    const [isModalOpen,setIsModalOpen] = useState<boolean>(false);

    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            // case 'id':
            //     return <b>{row[heading.field]}</b>;
            case 'connection':
                return (row[heading.field] !== null) ? <Chip label="Connected" color="primary" /> :
                    <Chip label="Available" />;

            default:
                return row[heading.field];
        }
    };

    return (
        <div>
            <Drawer
                anchor='right'
                open={isModalOpen}
                sx= {{ width: 480 }}
                PaperProps={{
                    sx: { width: "75%" },
                }}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedRow(null);
                }}
            >
                <DeviceInfo newItem={selectedRow}/>
            </Drawer>
            <MTable 
                headings={headings}
                rows={rows}
                renderCell={renderCell}
                getFullRowInfo
                onRowClick={(row) => {
                    setSelectedRow(row);
                    setIsModalOpen(true);
                }}
            />
        </div>
    );
}

export default AdminDevicesTable;
