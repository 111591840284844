import {networkLayer} from './networkLayer';
import {config} from "../config"

export type BillingMethod = 'medicare' |  'private_insurance' | 'self_pay';

export interface InsuranceInformationInterface {
  billing_method: BillingMethod;
  insurance_number?: string;
  id_unique: string;
}

export const createInsuranceInformation = async (values: InsuranceInformationInterface) => {
  return await networkLayer('post', `${config.usersUrls}/dashboard/insurance_information/`, {
    billing_method: values.billing_method,
    insurance_number: values.insurance_number,
    id_unique: values.id_unique,
  });
}

export const getInsuranceInformation = async (id_unique  : string) => {
  return await networkLayer('get', `${config.usersUrls}/dashboard/insurance_information`, null, {id_unique});
}