import { SxProps, Theme } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

export interface TableHeading {
    fieldName: string,
    field: string
}

export interface MTableActionsButtonsProps {
    rowData?: any;
    onEdit?: () => void;
    onDelete?: () => void;
}

export interface MTableProps {
    headings: TableHeading[];
    rows: any[];
    sx?: SxProps<Theme>;
    renderCell?: (heading: TableHeading, row: any) => JSX.Element;
    onRowClick?: (data: any) => void; 
    getFullRowInfo?: boolean;
}


export const MTableActionsButtons = (props: MTableActionsButtonsProps) => {
    const { rowData, onEdit, onDelete } = props;

    return onEdit || onDelete ? (
        <Box display="flex">
            {onEdit && (
                    <Box sx={{ cursor: 'pointer' }}>
                        <EditIcon color="primary" onClick={onEdit} />
                    </Box>
                )
            }
            {onDelete && (
                <Box sx={{ cursor: 'pointer', "margin-left":"10pt"}} onClick={() => {}}>
                    <DeleteRoundedIcon
                        color="error"
                        onClick={onDelete}
                    />
                </Box>
            )}
        </Box>
    ) : <></>;
}

export const MTable = (props: MTableProps) => {
    const { 
        headings = [], 
        rows = [], 
        renderCell, 
        sx, 
        onRowClick,
        getFullRowInfo
    } = props;

    const defaultTableStyle = {
        paddingX: 3.5, paddingY: 2.5
    };

    return (
        <>
           {rows.length ? 
                <TableContainer component={Paper} sx={sx ? defaultTableStyle : sx}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, key: number) => (
                                <TableRow key={key} sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }} onClick={() => onRowClick && onRowClick(getFullRowInfo ? row : row.id)}>
                                    {headings.map((heading: TableHeading, key: number) => (
                                        <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                            {renderCell && renderCell(heading, row) || '—'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> 
                </TableContainer>
            : 'No items to show'}
        </>
    );
}

export default MTable;


