import { useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import { useCallback, useEffect, useState } from "react";
import { getStudyAdmin, getStudyLogsAdmin } from "../api/study";
import { PatientInfo } from "../containers/PatientInfo";
import { MeasurementInfo } from "../containers/MeasurementInfo";
import { StudyInfoAdmin } from "../containers/StudyInfoAdmin";
import { StudyActionsAdmin } from "../containers/StudyActionsAdmin";
import MTabs, { TabProps } from "../components/MTabs";
import { AdminStudyLogItem, AdminStudyLogs, EventSeverity } from "../components/AdminStudyLogs";
import moment from "moment";
import { Box, IconButton, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { usePermissions, PermissionsE } from "../context/usePermissions";
import { NO_PERMISSIONS_MESSAGE } from "../utils/constants";

export const AdminStudy = () => {
  const { hasPermission } = usePermissions();
  const { idStudy } = useParams();
  const [study, setStudy] = useState<any>(null);
  const [studyLogs, setStudyLogs] = useState<AdminStudyLogItem[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [lastUpdatedLogs, setLastUpdatedLogs] = useState<string>('');
  const [idUnique, setIdUnique] = useState("");
  const navigate = useNavigate();

  const getStudyData = useCallback(async () => {
    if (!hasPermission!(PermissionsE['study.r_meta'])) return;
    if (idStudy) {
      const { data } = await getStudyAdmin(idStudy);
      setStudy(data);
      setIdUnique(data.id_unique);
    }
  }, [])

  const getStudyLogsData = useCallback(async () => {
    if (!hasPermission!(PermissionsE['study.log'])) return;
    setLastUpdatedLogs('')
    if (idStudy) {
      let { data: { events } } = await getStudyLogsAdmin(idStudy).finally(() => {
        setLastUpdatedLogs(moment().format('HH:mm:ss'))
      });
      events.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
      setStudyLogs(events);
    }
  }, [])

  useEffect(() => {
    getStudyData();
    getStudyLogsData();
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timer;
    timer = setTimeout(() => {
      getStudyLogsData();
    }, 10000);
    return () => clearTimeout(timer);
  }, [lastUpdatedLogs]);

  if (!hasPermission!(PermissionsE['study.r'])) return (
    <Paper sx={{ padding: 3 }}>
      <Typography>{NO_PERMISSIONS_MESSAGE}</Typography>
    </Paper>
  );

  if (!study) return <></>;

  const tabs = [
    {
      label: "Study Details",
      content: (
        <Box sx={{ '& > div': { marginBottom: 3 } }}>
          <StudyInfoAdmin {...study} />
          {study?.measurements &&
            study.measurements.map((item: any, key: number) => <MeasurementInfo newItem={item} key={key} />)}
          {
            hasPermission!(PermissionsE["patient.r"]) ? (
              <PatientInfo newIdPatient={idUnique} />
            ) : <></>
          }
          <StudyActionsAdmin {...study} />
        </Box>
      )
    },
    hasPermission!(PermissionsE['study.log']) ? {
      label: "Full log",
      content: (
        <AdminStudyLogs logs={studyLogs} />
      )
    } : null
  ].filter(Boolean) as TabProps[];

  return (
    <>
      <PageHeader title={"Study " + idStudy} actions={
        [{ onClick: () => { navigate(-1) }, name: "Close" }]
      } />
      <Paper sx={{ minHeight: '70vh' }}>
        <MTabs
          tabsStyles={{
            width: '300px'
          }}
          tabsContent={activeTab === 1 ?
            <Box
              display="flex"
              justifyContent="end"
              width="100%"
              alignItems="center"
              sx={{ fontSize: 12 }}
            >
              <Box mr={1}>{!lastUpdatedLogs ? 'Loading...' : `Last updated: ${lastUpdatedLogs}`}</Box>
              <Box mr={2}>
                <IconButton size="small" onClick={getStudyLogsData}><RefreshIcon /></IconButton>
              </Box>
            </Box> : <></>
          }
          value={activeTab}
          onChange={(e, v) => setActiveTab(v)}
          tabs={tabs}
        />
      </Paper>
    </>
  )
}