import React from 'react';
import {Page, Link, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import logo from '../../src/images/icon.png'

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 15,
        padding: 30,
        backgroundColor : "#F3F5E8"
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    contentContainer: {
        padding: 10,
        backgroundColor : "#FFF",
        width : "100%",
        height : "80%"
    },
    rowContainder : {
        marginBottom: 10,
        flexDirection: 'row',

    },
    fieldName : {
        marginLeft : 10,
        width : "20%",
        fontFamily: 'Helvetica-Bold',
        textAlign: 'right'
    },
    fieldValue : {
        marginLeft : 20
    },
    logo: {
        width: 50,
        height: 50,
        alignSelf: 'flex-end',
    },
    headerText: {
        fontSize: 32,
        fontWeight: 'bold',
        textAlign: 'center',
        flex: 1,
    },
    contentHeaderText: {
        fontSize: 21,
        fontWeight: 'bold',
        marginTop: 20
    },
    line: {
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        marginTop: 10,
        marginBottom: 20,
    },

});

interface CredentialPDFViewProps {
    clinic_name: string | undefined;
    account_name: string | undefined;
    email : string | undefined;
    password: string | undefined;
}

export const getDomain = () => {
    const currentDomain = window.location.hostname;

    // Check if the domain is localhost and return the modified domain
    if (currentDomain === 'localhost' || currentDomain === '127.0.0.1') {
        return 'https://portal-beta.mawiheart.com/';
    }

    // Return the current domain if it's not localhost
    return window.location.origin;
};

const CredentialPDFView = (props:CredentialPDFViewProps) => (
    <Document>
        <Page style={styles.page}>
            {/* Header Container */}
            <View style={styles.headerContainer}>
                {/* Header Text */}
                <Text style={styles.headerText}>Access details</Text>

                {/* Logo */}
                <Image style={styles.logo} src={logo} />
            </View>
            <View style={styles.contentContainer}>
                <Text style={styles.contentHeaderText}>Account information</Text>
                <View style={styles.line} />

                <View style={styles.rowContainder}>
                    <Text style={styles.fieldName}>Clinic</Text>
                    <Text style={styles.fieldValue}>{props.clinic_name}</Text>
                </View>
                <View style={styles.rowContainder}>
                    <Text style={styles.fieldName}>Account name</Text>
                    <Text style={styles.fieldValue}>{props.account_name}</Text>
                </View>

                <Text style={styles.contentHeaderText}>Access information</Text>
                <View style={styles.line} />

                <View style={styles.rowContainder}>
                    <Text style={styles.fieldName}>Email</Text>
                    <Text style={styles.fieldValue}>{props.email}</Text>
                </View>
                <View style={styles.rowContainder}>
                    <Text style={styles.fieldName}>Password</Text>
                    <Text style={styles.fieldValue}>{props.password}</Text>
                </View>

                <View style={styles.rowContainder}>
                    <Text style={styles.fieldName}>Link</Text>
                    <Link src={getDomain()} style={styles.fieldValue}>{getDomain()}</Link>
                </View>

            </View>





        </Page>
    </Document>
);

export default CredentialPDFView;
