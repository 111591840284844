export const config = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  usersUrls: process.env.REACT_APP_API_USER_URL,
  dataUrls: process.env.REACT_APP_API_DATA_URL,
  deviceUrl: process.env.REACT_APP_API_DEVICE_URL,
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN,
  new_relic_license_key: process.env.REACT_APP_NR_LICENSE_KEY,
  new_relic_app_id: process.env.REACT_APP_NR_APP_ID,
  new_relic_account_id: process.env.REACT_APP_NR_ACCOUNT_ID,
  new_relic_agent_id: process.env.REACT_APP_NR_AGENT_ID
}